import { CardMedia, MenuItem, Stack, TextField } from "@mui/material"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { resizePhotos } from "../../../functions/imageFunctions"


export default function AddEditCategoryForm({ formData, setFormData }) {
  const { categories , IsLoadingCategory} = useSelector((state) => state.category)
  const { selectedRow } = useSelector((state) => state.dataGrid)

  const handleChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    if (selectedRow) setFormData(selectedRow)
  }, [selectedRow])

  const onAddFile = async (e) => {
    let file = e.target.files[0]

    let compressedFile = await resizePhotos(file)
    setFormData({ ...formData, image: compressedFile })
  }

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      spacing={5}
      style={{ width: "50vw" }}
    >
      <Stack spacing={2} sx={{ width: "100%" }}>
        {input({ label: "Title", name: "title" })}
        {selectParent(formData, handleChange)}
        {input({ label: "Ordre", name: "order", type: "number" })}
      </Stack>
      <Stack alignItems="center" spacing={2}>
        
        <Button
          onClick={(e) =>
            document.getElementById("addCategoryImageInput").click()
          }
        >
          Télécharger une image
        </Button>
        <CardMedia
          className="selected-file"
          image={
            formData.image ||
            "https://homolog-paris.com/wp-content/uploads/2020/07/default.jpg"
          }
        />
      </Stack>
      <input
        hidden
        type="file"
        id="addCategoryImageInput"
        onChange={onAddFile}
        accept="image/*"
      />
    </Stack>
  )
  function selectParent(formData, handleChange) {
    return (
      <FormControl fullWidth>
        <InputLabel htmlFor="roles-select">Parent</InputLabel>
        <Select name="parent" onChange={handleChange}>
          {categories
            ?.filter((category) => category.parent === "0")
            .map((category) => (
              <MenuItem value={category._id} key={category._id}>
                {category.title}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
  }

  function input({ name, label, props }) {
    return (
      <TextField
        fullWidth
        label={label ? label : capitalizeFirstLetter(name)}
        variant="outlined"
        name={name}
        value={formData[name]}
        onChange={handleChange}
        {...props}
      />
    )
  }
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
