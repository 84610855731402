import React from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

function CheckAuthAction({ role, children }) {
  const { authData } = useSelector((state) => state.auth)
  const navigate = useNavigate()
  const location = useLocation()

  const handleCheck = async (e) => {
    if (!authData && role === "AUTHENTICATED")
      return navigate("/auth", { state: { from: location }, })

    if (children?.props?.onClick) children?.props?.onClick(e)
  }

  return <>{React.cloneElement(children, { onClick: handleCheck })}</>
}

export default CheckAuthAction
