import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import TextField from "@mui/material/TextField"
import * as React from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { submitAction } from "../../redux/actions/feedback"
import { createReservation } from "../../redux/actions/reservation"
import CheckAuthAction from "../../routes/CheckAuthAction"

export default function AddReservation({ children, post }) {
  const dispatch = useDispatch()
  const { authData } = useSelector((state) => state.auth)

  const formData = {
    prestataireReference: post?.prestataireId?._id, // The one making the reservation
    userReference: authData?._id, // The one making the reservation
    postReference: post?._id,
    name: authData?.name,
    email: authData?.email,
    phone: authData?.phone,
    text: "",
    numberOfPlace: 0,
  }
  /* #region  open-close */
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  /* #endregion */

  /* #region Form handle */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: formData })

  const onSubmit = async (data) => {
    await dispatch(
      submitAction({
        actionFn: createReservation(data),
        feedback: "Votre réservation a été enregistrée avec succès.",
        handleClose: handleClose(),
      })
    )
  }
  /* #endregion */
  return (
    <>
      <CheckAuthAction role="AUTHENTICATED">
        {React.cloneElement(children, { onClick: handleClickOpen })}
      </CheckAuthAction>
      <Dialog maxWidth="xl" open={open} onClose={handleClose}>
        <div className="addReservation">
          <div className="main-container">
            <h1>FAIRE UNE RÉSERVATION</h1>

            <form onSubmit={handleSubmit(onSubmit)}>
              {input("name", "Entrer votre nom", { disabled: true })}
              {input("email", "Entrer votre email", { disabled: true })}
              {input("phone", "Entrer votre téléphone", { disabled: true })}
              {input("text", "Entrer votre message")}
              {input("numberOfPlace", "Entrer le nombre de place", {
                required: true,
              })}
              <Button type="submit" className="submit-button">
                Soumettre
              </Button>
            </form>
          </div>
        </div>
      </Dialog>
    </>
  )

  function input(name, label, props) {
    return (
      <TextField
        type={name === "numberOfPlace" ? "number" : "text"}
        style={{ margin: "5px 0" }}
        variant="outlined"
        fullWidth
        label={label}
        InputProps={{ inputProps: { min: 1, max: 10 } }}
        {...register(name)}
        {...props}
      />
    )
  }
}
