
import FileResizer from "react-image-file-resizer"

export const resizePhotos = (file, compressRate = 25) =>
new Promise((resolve) => {
  FileResizer.imageFileResizer(
    file,
    1920,
    1080,
    "JPEG",
    90, // Lower means more compressed
    // compressRate, // Lower means more compressed
    0,
    (uri) => {
      resolve(uri)
    },
    "base64"
  )
})

