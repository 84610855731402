import { alpha } from "@mui/material/styles";
import {makeStyles} from '@mui/styles';
import { deepPurple } from "@mui/material/colors"

const useStyles = makeStyles((theme) => ({
  header: {
    width: "100vw",
    padding: "10px 0",
    // backgroundColor: "black",
    backgroundColor: "#f0f1ff",
    height: "80px",
    position: "fixed",
    top: "0",
    left: "0",
    zIndex: "888",
  },
  myContainer: {
    padding: 0,
    width: "95%",
    margin: "auto",

    // maxWidth: "1340px",
  },
  shadow: {
    // backgroundColor: "#000",
    // boxShadow:
    //   "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
  },
  flexLogo: {
    width: "33%",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    width: "60px",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;",
    borderRadius: "20px",
  },
  flexStart: {
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
  },
  flexCenter: {
    width: "33%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flexEnd: {
    width: "33%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  heading: {
    color: "#f76c6f",
    textDecoration: "none",
    fontSize: "2em",
    fontWeight: 400,
    whiteSpace: "nowrap",
    fontFamily: "LetsGo, Arial, Helvetica, sans-serif",
  },
  // desktopMenu: {
  //   [theme.breakpoints.down('lg')]: {
  //     display: "none",
  //   },
  // },
  // mobileToggle: {
  //   display: "none",
  //   [theme.breakpoints.down('lg')]: {
  //     display: "block",
  //     position: "absolute",
  //     top: "25px",
  //     right: "15px",
  //     fontSize: "2.2rem",
  //     color: "#f76c6f",
  //   },
  // },
  navigationMenu: {
    position: "fixed",
    bottom: 0,
    right: "-50px",
    width: "50px",
    height: "calc(100vh - 80px)",
    backgroundColor: "#000",
    opacity: 0,
    transition: "all 0.5s",
    zIndex: "889",
  },
  showMe: {
    right: 0,
    opacity: 1,
  },
  mobileIcons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px auto",
  },
  // profile: {
  //   display: "flex",
  //   justifyContent: "space-between",
  //   marginRight: "5px",
  //   alignItems: "center",
  //   [theme.breakpoints.down('lg')]: {
  //     width: "auto",
  //     marginTop: 20,
  //     justifyContent: "center",
  //   },
  // },
  logOut: {
    marginLeft: "0",
    padding: "4px 10px",
    background: "none",
    boxShadow: "none",
    border: "none",
    color: "#f76c6f",
    minWidth: "auto",
    "&:hover": {
      background: "none",
      boxShadow: "none",
    },
  },
  userName: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    marginLeft: "10px",
    marginRight: "15px",
  },
  brandContainer: {
    display: "flex",
    alignItems: "center",
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    width: "35px",
    height: "35px",
    marginLeft: "10px",
    cursor: "pointer",
  },
  signin: {
    background: "#18829b",
    "&:hover": {
      background: "#07677d",
    },
    borderRadius: "20px",
    padding: "5px 20px",
    color: "#f76c6f",
    marginLeft: "10px",
  },
  menuBtn: {
    display: "none",
  },
  svgAnimation: {
    fontSize: "2rem",
    transition: "all 0.5s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  // Search
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    color: "#f76c6f",
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "#f76c6f",
  },
  circleNotification: {
    position: "absolute",
    top: -7,
    left: 14,
    fontSize: 14,
    backgroundColor: "red",
    padding: "0px 7px",
    borderRadius: "50%",
    color: "white",
  },
  // inputInput: {
  //   fontSize: 20,
  //   padding: theme.spacing(1, 1, 1, 0),
  //   // vertical padding + font size from searchIcon
  //   paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  //   transition: theme.transitions.create("width"),
  //   width: "100%",
  //   [theme.breakpoints.up("sm")]: {
  //     width: "12ch",
  //     "&:focus": {
  //       width: "20ch",
  //     },
  //   },
  // },
}))

export default useStyles