import React, { useContext, useState } from "react"
import { Button, CardMedia, Stack } from "@mui/material"
import { FileUploader } from "react-drag-drop-files"
import CloseIcon from "@mui/icons-material/Close"
import { resizePhotos } from "../../functions/imageFunctions"

export const fileTypes = ["JPEG", "PNG", "GIF", "JPG"]
export function AddImages({ formData, setFormData, height, readOnly }) {

  const handleAddPostPhotos = async (file) => {
    if (file.length > 5 || formData?.photos?.length + file.length > 5) {
      return alert("Vous ne pouvez pas ajouter plus de 5 photos.")
    }

    let compressedPhotos = []
    for (let i = 0; i < file.length; i++) {
      compressedPhotos.push(await resizePhotos(file[i], 10))
    }

    if (!formData?.photos) formData.photos = []

    setFormData({
      ...formData,
      photos: [...formData?.photos, ...compressedPhotos],
    })
  }

  const handleRemovePhoto = async (e, photo) => {
    setFormData({
      ...formData,
      photos: formData.photos.filter((statePhoto) => statePhoto !== photo),
    })
    e.preventDefault()
  }

  return (
    <div>
      <div style={{ width : "fit-content", margin : "auto", display: readOnly ? "none" : "" }}>
        <FileUploader
          multiple={true}
          handleChange={handleAddPostPhotos}
          name="file"
          types={fileTypes}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{
              height: height ? height : "50vh",
            }}
          >
            <img src="/images/addImages.png" alt="Ajouter des images" />
            <h3
              style={{
                fontWeight: "lighter",
              }}
            >
              Faites glisser les photos et les vidéos ici
            </h3>
            <Button>Sélectionner sur l'ordinateur</Button>
          </Stack>
        </FileUploader>
      </div>

      <Stack
      alignItems="center"
      >
      <Stack
        sx={{
          p: 0.5,
          border: "1px solid black",
          height: "140px",
          width: formData?.photos?.length ? "fit-content" : "100%",
        }}
        direction="row"
        spacing={0.5}
      >
        {formData.photos?.map((photo, key) => (
          <div
            key={key}
            style={{
              position: "relative",
            }}
          >
            <CloseIcon
              className="delete-image"
              onClick={(e) => handleRemovePhoto(e, photo)}
              sx={{ display: readOnly ? "none" : "" }}
            />
            <CardMedia component="img" height="132" src={photo} />
          </div>
        ))}
      </Stack>  
      </Stack>
    </div>
  )
}
