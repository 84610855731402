import React, { useEffect } from "react"
import { Route, Navigate, Routes, useLocation } from "react-router-dom"
import PostDetails from "../components/PostDetails/PostDetailsPage"
import HomePage from "../components/Pages/Home/HomePage"
import Auth from "../components/Auth/AuthPage"
import Dashboard from "../components/Dashboard/DashboardIndex"
import CategoryDetails from "../components/CategoryDetails/CategoryDetails"
import PrestataireDetails from "../components/PrestataireDetails/PrestataireDetails"
import Profile from "../components/Profile/Profile"
import FavoritePage from "../components/Pages/ClientPages/FavoritePage/FavoritePage"
import Posts from "../components/Dashboard/PublicPostsPage/PublicPostsPage"
import Categories from "../components/Dashboard/CategoriesPage/CategoriesPage"
import Prestataires from "../components/Dashboard/PrestatairePage/PrestatairesPage"
import WaitingPosts from "../components/Dashboard/WaitingPostsPage/WaitingPostsPage"
import ArchivedPosts from "../components/Dashboard/ArchivedPostsPage/ArchivedPostsPage"
import MyPostsPage from "../components/Dashboard/PrestataireDashboard/MyPostsPage/MyPostsPage"
import ReservationsRequestPage from "../components/Dashboard/PrestataireDashboard/ReservationsPagePrestataire/ReservationsRequestPage"
import UsersPage from "../components/Dashboard/UsersPage/UsersPage"
import ProtectedRoutes from "./ProtectedRoutes"
import ProfilePage from "../components/Profile/ProfilePage"
import MyReservationsPage from "../components/Pages/ClientPages/MyReservationPage/MyReservationsPage"
import MembershipPrestatairesPage from "../components/Dashboard/MembershipPrestatairesPage/MembershipPrestatairesPage"
import ForgetPasswordPage from "../components/Pages/forgetPassword/ForgetPasswordPage"
import RejectedPostPage from "../components/Dashboard/rejectedPostPage/RejectedPostsPage"


function MainRoutes() {

  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Routes>
      {/* Public */}
      <Route path="/category_details" element={<CategoryDetails />} />
      <Route path="/post/:id" element={<PostDetails />} />
      {/* <Route path="/categories/:id" element={<CategoryDetails />} /> */}
      <Route path="/prestataires/:id" element={<PrestataireDetails />} />
      <Route path="/auth" element={<Auth />} />
      <Route path="/forget_password" element={<ForgetPasswordPage />} />
      <Route path="/home" element={<HomePage />} />
      {/* Logged In */}
      <Route element={<ProtectedRoutes role="user" />}>
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/profile2" element={<Profile />} />
        <Route path="/liked" element={<FavoritePage />} />
      </Route>

      {/* Admin */}
      <Route path="/admin" element={<Dashboard />}>
        <Route element={<ProtectedRoutes role="admin" />}>
          <Route path="/admin/public_posts" element={<Posts />} />
          <Route path="/admin/categories" element={<Categories />} />

          <Route path="/admin/waiting_posts">
            <Route index element={<WaitingPosts />} />
            <Route path=":id" element={<WaitingPosts />} />
          </Route>
          <Route path="/admin/archived_posts" element={<ArchivedPosts />} />
          <Route path="/admin/rejected_posts" element={<RejectedPostPage />} />
          <Route path="/admin/prestataires" element={<Prestataires />} />
          <Route
            path="/admin/membership_prestataires"
            element={<MembershipPrestatairesPage />}
          />
          <Route path="/admin/users" element={<UsersPage />} />
        </Route>

        {/* Prestataire */}
        <Route element={<ProtectedRoutes role="prestataire" />}>
          <Route path="/admin/my_posts" element={<MyPostsPage />} />
          <Route
            path="/admin/reservations"
            element={<ReservationsRequestPage />}
          />
        </Route>
      </Route>
      <Route element={<ProtectedRoutes role="client" />}>
        <Route path="/my_reservations" element={<MyReservationsPage />} />
      </Route>

      <Route path="*" element={<Navigate to="/home" replace />} />
    </Routes>
  )
}

export default MainRoutes
