import React, { useEffect, useState } from "react"
import { CardMedia, Grid, Grow, TextField } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { useDispatch, useSelector } from "react-redux"
import SaveIcon from "@mui/icons-material/Save"
import Button from "@mui/material/Button"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import MessageBox from "../MessageBox.js"
import {
  createCategory,
  updateCategory,
} from "../../../redux/actions/categories.js"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import Compress from "compress.js"
import { resizePhotos } from "../../../functions/imageFunctions.js"

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100%",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    inputUpload: {
      display: "none",
    },
    button: {
      margin: theme.spacing(1),
    },
  },
}))

export default function AddCategoryModal(props) {
  const compress = new Compress()
  const { category, categories } = props
  const { CategoriesIsLoading } = useSelector((state) => state.category)
  const classes = useStyles()
  const dispatch = useDispatch()
  const [newCategory, setNewCategory] = useState({
    title: "",
    image: "",
    parent: "0",
    order: 0,
  })
  const [myfeedback, setMyfeedback] = useState(null)
  const [errors, setErrors] = useState(null)

  useEffect(() => {
    if (category) {
      setNewCategory({
        title: category.title,
        image: category.image,
        parent: category.parent,
        order: category.order,
      })
    } else {
      clear()
    }
  }, [category])

  const clear = () => {
    setNewCategory({ title: "", image: "", parent: "0", order: 0 })
  }

  const onAddFile = async (e) => {
    let file = e.target.files[0]

    let compressedFile = await resizePhotos(file)
    setNewCategory({ ...newCategory, image: `compressedFile` })
  }

  const handleSelectFiles = (e) => {
    e.preventDefault()
    document.querySelector("#input-file-native input[type=file]").click()
  }

  const submitEdit = (e) => {
    e.preventDefault()
    if (category) {
      dispatch(updateCategory(category._id, newCategory))
        .then(() => {
          setMyfeedback("Category Updated successfully !")
          clear()
        })
        .catch((err) => {
          setErrors(err.message)
        })
    } else {
      dispatch(createCategory(newCategory))
        .then(() => {
          setMyfeedback("Category Added successfully !")
          clear()
        })
        .catch((err) => {
          setErrors(err.message)
        })
    }
  }

  if (props.showAddCategory) {
    return (
      <Grow in>
        <div className="modal-container show">
          <div className="modal-box">
            <div className="modal-content">
              <div className="modal-header">
                <h2>{category ? "Edit " : "Add "} Category</h2>
                <button id="close-modal" onClick={props.onClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className="form-center">
                    <form
                      className={classes.root}
                      noValidate
                      autoComplete="off"
                    >
                      <div className="flex-between">
                        <div className="col-2">
                          <TextField
                            type="text"
                            label="Title"
                            variant="outlined"
                            onChange={(e) =>
                              setNewCategory({
                                ...newCategory,
                                title: e.target.value,
                              })
                            }
                            value={newCategory.title}
                          />
                        </div>
                        <div className="col-2">
                          <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="roles-select">
                              Parent
                            </InputLabel>
                            <Select
                              native
                              value={newCategory.parent}
                              onChange={(e) =>
                                setNewCategory({
                                  ...newCategory,
                                  parent: e.target.value,
                                })
                              }
                              inputProps={{
                                name: "mainCategories",
                                id: "mainCategories-select",
                              }}
                            >
                              <option value={"0"}>Select a parent</option>
                              {categories
                                ?.filter((c) => c.parent === "0")
                                .map((Category) => (
                                  <option
                                    value={Category._id}
                                    key={Category._id}
                                  >
                                    {" "}
                                    {Category.title}{" "}
                                  </option>
                                ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                      <div className="flex-between">
                        <div className="col-2">
                          <TextField
                            type="number"
                            label="Order"
                            variant="outlined"
                            onChange={(e) =>
                              setNewCategory({
                                ...newCategory,
                                order: e.target.value,
                              })
                            }
                            value={newCategory.order}
                          />
                        </div>
                        <div className="col-2">
                          <div className="flex-imageBox">
                            <Button
                              variant="contained"
                              className={classes.button}
                              startIcon={<CloudUploadIcon />}
                              onClick={(e) => handleSelectFiles(e)}
                            >
                              Upload Photo
                            </Button>
                            <div className="cat-image">
                              <CardMedia
                                className="selected-file"
                                image={
                                  newCategory.image ||
                                  "https://homolog-paris.com/wp-content/uploads/2020/07/default.jpg"
                                }
                                title={newCategory.title}
                              />
                            </div>
                          </div>
                          <div id="input-file-native">
                            <input
                              type="file"
                              onChange={(e) => onAddFile(e)}
                              accept="image/*"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className="flex-start">
                    <Button
                      variant="contained"
                      color="primary"
                      className="mybtn"
                      startIcon={<SaveIcon />}
                      onClick={submitEdit}
                    >
                      {category ? "Edit  " : "Add  "}
                      {CategoriesIsLoading ? (
                        <img
                          src="/images/loading-buffering.gif"
                          alt="loading"
                          className="loading-img"
                        />
                      ) : null}
                    </Button>
                  </div>
                  {myfeedback && myfeedback !== "" ? (
                    <MessageBox>{myfeedback}</MessageBox>
                  ) : errors ? (
                    <MessageBox variant="danger">{errors}</MessageBox>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Grow>
    )
  } else {
    return null
  }
}
