import { IconButton } from "@mui/material"
import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import IosShareIcon from "@mui/icons-material/IosShare"

function CategoryItem({ category }) {
  return (
    <Link
      to={`/category_details/?parentId=${category._id}`}
    >
      <div className="categoryLink">
        <div className="category-image">
          <IconButton className="link-button" size="large">
            <IosShareIcon sx={{ color: "white", fontSize: "2.5rem" }} />
          </IconButton>
          <img src={category.image} alt={category.title} />
        </div>
        <h3>{category.title}</h3>
      </div>
    </Link>
  );
}

export default CategoryItem
