import { Avatar } from "@mui/material"
import { AcceptRejectPrestataire } from "../../../GlobalComponent/dataGridActions"
import DeletePrestataireDataGridAction from "../../../GlobalComponent/dataGridActions/DeletePrestataireDataGridAction"

export default function MembershipColumns() {

  let columns = [
    {
      field: "avatar",
      headerName: "Avatar",

      renderCell: (params) => (
        <Avatar
          sx={{
            cursor: "pointer",
          }}
          src={params.row?.userId?.avatar}
          alt={params.row?.userId?.name}
        />
      ),
    },
    {
      field: "name",
      headerName: "Nom",
      editable: true,
      renderCell: (params) => <p>{params.row?.userId?.name}</p>,
      valueGetter: (params) => params.row?.userId?.name,
      // valueFormatter: (params) => params.value?.name,
    },
    {
      editable: true,
      field: "address",
      headerName: "Emplacement",
    },
    {
      editable: true,
      field: "city",
      headerName: "Cité",
    },
    {
      field: "phone",
      headerName: "Téléphone",
      editable: true,
      renderCell: (params) => <p>{params.row?.userId?.phone}</p>,
      valueGetter: (params) => params.row?.userId?.phone,
    },
    {
      field: "action",

      renderCell: (params) => {
        return (
          <>
            <AcceptRejectPrestataire id= {params.row._id} />
            <DeletePrestataireDataGridAction id={params.row._id} />
          </>
        )
      },
    },
  ]

  // Center columns, filed and headerName
  columns = columns.map(
    (column, index) =>
      (column = {
        ...{
          headerName: capitalizeFirstLetter(column.field),
          flex: 1,
          headerAlign: "center",
          align: "center",
        },
        ...column,
      })
  )

  return columns
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
