import {
  AUTH,
  AUTH_ERROR,
  CREATE_PRESTATAIRE,
  END_LOADING_USER,
  START_LOADING_USER,
} from "../actionTypes"
import * as api from "../../api/index.js"
import { feedbackSuccess } from "./feedback"

export const signin = (formData) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_USER })
    const { data } = await api.signIn(formData)
    dispatch({ type: AUTH, payload: data })
    dispatch({ type: END_LOADING_USER })
  } catch (error) {
    dispatch({ type: END_LOADING_USER })
    return Promise.reject(error)
  }
}

export const signup = (formData) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_USER })
    const { data } = await api.signUp(formData)
    dispatch({ type: AUTH, payload: data })
    dispatch({ type: END_LOADING_USER })
  } catch (error) {
    dispatch({ type: END_LOADING_USER, error })
    return Promise.reject(error)
  }
}

export const signupPrestataire = (formData) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_USER })
    const { data } = await api.signUp(formData)
    dispatch(
      feedbackSuccess(
        "Votre demande est en cours de traitement, vous recevez un email lors de la confirmation."
      )
    )
    // dispatch({ type: CREATE_PRESTATAIRE, payload: data })

    dispatch({ type: END_LOADING_USER })
  } catch (error) {
    dispatch({ type: END_LOADING_USER, error })
    return Promise.reject(error)
  }
}
