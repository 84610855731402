import React from "react"
import ConfirmDialog from "../ConfirmDialog"
import DeleteIcon from "@mui/icons-material/Delete"
import { useDispatch } from "react-redux"
import { blockUser, deleteUser } from "../../redux/actions/users"
import { deleteCategory } from "../../redux/actions/categories"
import AcUnitIcon from "@mui/icons-material/AcUnit"
import {
  deletePrestataire,
  updatePrestataire,
} from "../../redux/actions/prestataires"
import AddEditUserDialog from "../../components/Dashboard/UsersPage/AddEditUserDialog"
import EditIcon from "@mui/icons-material/Edit"
import { IconButton, Tooltip } from "@mui/material"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import { deletePost, updatePost } from "../../redux/actions/posts"
import ArchiveIcon from '@mui/icons-material/Archive'


/* #region  Delete */
export function DeleteUserDataGridAction({ id }) {
  const dispatch = useDispatch()

  return (
    <ConfirmDialog
      actionFn={() => dispatch(deleteUser(id))}
      title="Supprimer un utilisateur"
      text="Êtes vous sur de vouloir supprimer cet utilisateur ?"
      feedback="L'utilisateur a été supprimé."
    >
      <DeleteIcon color="error" />
    </ConfirmDialog>
  )
}

export function DeletePostDataGridAction({ id }) {
  const dispatch = useDispatch()

  const handleDelete = async () => {
    dispatch(deletePost(id))
  }

  return (
    <ConfirmDialog
      actionFn={() => handleDelete()}
      title="Supprimer une publication"
      text="Êtes vous sur de vouloir supprimer cette publication ?"
      feedback="La publication a été supprimée définitivement."
    >
      <DeleteIcon color="error" />
    </ConfirmDialog>
  )
}

export function DeletePrestataireDataGridAction({ id }) {
  const dispatch = useDispatch()

  return (
    <ConfirmDialog
      actionFn={() => dispatch(deletePrestataire(id))}
      title="Supprimer un prestataire"
      text="Êtes vous sur de vouloir supprimer ce prestataire ?"
      feedback="Le prestataire a été supprimé."
    >
      <DeleteIcon color="error" />
    </ConfirmDialog>
  )
}

export function DeleteCategoryDataGridAction({ id }) {
  const dispatch = useDispatch()

  return (
    <ConfirmDialog
      actionFn={() => dispatch(deleteCategory(id))}
      title="Supprimer une catégorie"
      text="Êtes vous sur de vouloir supprimer cette catégorie ?"
      feedback="La catégorie a été supprimé."
    >
      <DeleteIcon color="error" />
    </ConfirmDialog>
  )
}
/* #endregion */

/* #region  Block */
export function FreezeUserDataGridAction({ user }) {
  const dispatch = useDispatch()
  const action = user.block ? "Débloquer" : "Bloquer"
  const action2 = user.block ? "débloquer" : "bloquer"

  return (
    <ConfirmDialog
      actionFn={() => dispatch(blockUser(user._id))}
      title={`${action} un utilisateur`}
      text={`Êtes vous sur de vouloir ${action2} cet utilisateur ?`}
      feedback={`L'utilisateur a été ${action2}.`}
    >
      <AcUnitIcon color="primary" />
    </ConfirmDialog>
  )
}

export function FreezePrestataireDataGridAction({ prestataire }) {
  const dispatch = useDispatch()
  const blockOrUnblock = prestataire.userId.block ? false : true
  const action = prestataire.userId.block ? "Débloquer" : "Bloquer"
  const action2 = prestataire.userId.block ? "débloquer" : "bloquer"

  return (
    <ConfirmDialog
      actionFn={() =>
        dispatch(
          updatePrestataire(prestataire._id, {
            block: blockOrUnblock,
          })
        )
      }
      title={`${action} un Prestataire`}
      text={`Êtes vous sur de vouloir ${action2} cet prestataire ?`}
      feedback={`Le prestataire a été ${action2}.`}
    >
      <AcUnitIcon color="primary" />
    </ConfirmDialog>
  )
}
/* #endregion */

/* #region  Add Edit */

export function AddEditUserPrestataireDataGridAction() {
  return (
    <AddEditUserDialog editMode={true}>
      <Tooltip title="Modifier">
        <IconButton size="large">
          <EditIcon color="success" />
        </IconButton>
      </Tooltip>
    </AddEditUserDialog>
  )
}

export function AcceptRejectPrestataire({ id }) {
  const dispatch = useDispatch()
  const handleAcceptRejectPrestataire = async () => {
    await dispatch(updatePrestataire(id, { accepted: true }))
  }

  return (
    <ConfirmDialog
      actionFn={() => handleAcceptRejectPrestataire()}
      title="Accepter un prestataire"
      text="Êtes vous sur de vouloir accepter ce prestataire ?"
      feedback="Prestataire accepté."
    >
      <CheckCircleOutlineIcon color="success" />
    </ConfirmDialog>
  )
}

export function ArchivePost({ id }) {
  const dispatch = useDispatch()
  const handleArchivePost = async () => {
    dispatch(updatePost(id, { status: "ARCHIVED" }))
  }
  return (
    <ConfirmDialog
      actionFn={() => handleArchivePost()}
      title="Archiver un prestataire"
      text="Êtes vous sur de vouloir archiver cette publication ?"
      feedback="Publication archivée."
    >
      <  ArchiveIcon color="secondary" />
    </ConfirmDialog>
  )
}

/* #endregion */
