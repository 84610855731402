import { Avatar, Chip, InputLabel } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"

function CategorySelection({ variant, setFormData, formData, style }) {
  const { categories } = useSelector((state) => state.category)

  const handleAddChip = (category) => {
    setFormData({
      ...formData,
      categoriesIds: [...formData.categoriesIds, category],
    })
  }

  const handleDeleteChip = (category) => {
    setFormData({
      ...formData,
      categoriesIds: formData.categoriesIds.filter((c) => c !== category),
    })
  }

  return (
    <div style={{ width: "100%", ...style }}>
      <InputLabel>
        {variant === "readOnly"
          ? "Catégories sélectionnées"
          : "Sélectionner les catégories"}
      </InputLabel>
      <div className="categories-container">
        {categories?.map((category) => (
          <Chip
            key={category._id}
            size="small"
            label={category.title}
            avatar={
              <Avatar
                alt={category.title}
                src={category.image || "/images/default.png"}
              />
            }
            color={
              formData.categoriesIds?.find((c) => c === category._id)
                ? "secondary"
                : "primary"
            }
            onClick={() => {
              if (variant === "readOnly") return

              formData.categoriesIds?.find((c) => c === category._id)
                ? handleDeleteChip(category._id)
                : handleAddChip(category._id)
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default CategorySelection
