import makeStyles from '@mui/styles/makeStyles';
import { deepPurple } from '@mui/material/colors';

export default makeStyles((theme) => ({

  mainContainer: {
    marginTop: '120px',
    padding: '20px 0 40px',
    width: '90%',
    margin: 'auto',
    maxWidth: '1340px',
  },
  title: {
    marginBottom: '30px',
  },
  button: {
    display: 'flex',
    cursor: 'pointer',
    margin: '40px auto',
  },
  avatar: {
    margin: '0 auto',
    height: '180px',
    width: '180px',
    borderRadius: '50%',
  } ,
  inputContainer:{
    marginTop: '20px',
    padding: '20px 0 40px',
    width: '90%',
    maxWidth: '1340px',
  } ,
  submitButton:{
    display: 'block',
    margin: '0',
    padding: '5px 30px' ,
    fontSize: '1rem', 
    color: '#333',
    backgroundColor: '#F76C6F',
    fontWeight: 'bold',
    borderRadius: '20px',

    '&:hover': {
      backgroundColor: '#af4d4f',
    }
    
  }
}));

