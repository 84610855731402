import { Avatar, Rating } from "@mui/material"
import { resizePhotos } from "../../../functions/imageFunctions"
import AddEditUserPrestataireDataGridAction from "../../../GlobalComponent/dataGridActions/AddEditUserPrestataireDataGridAction"
import DeletePrestataireDataGridAction from "../../../GlobalComponent/dataGridActions/DeletePrestataireDataGridAction"
import {FreezePrestataireDataGridAction} from "../../../GlobalComponent/dataGridActions"
import { updatePrestataire } from "../../../redux/actions/prestataires"

export default function PrestatairePageColumns(dispatch) {
  const handleAddAvatar = async (e, params) => {
    const avatar = e.target.files[0]
    const compressedAvatar = await resizePhotos(avatar, 10)
    dispatch(updatePrestataire(params.row._id, { avatar: compressedAvatar }))
  }

  let columns = [
    {
      field: "avatar",
      headerName: "Avatar",

      renderCell: (params) => (
        <>
          <input
            id={params.row._id}
            type="file"
            hidden
            onChange={(e) => handleAddAvatar(e, params)}
          />
          <label htmlFor={params.row._id}>
            <Avatar
              sx={{
                cursor: "pointer",
              }}
              src={params.row?.userId?.avatar}
              alt={params.row?.userId?.name}
            />
          </label>
        </>
      ),
    },
    {
      field: "name",
      headerName: "Nom",
      editable: true,
      renderCell: (params) => <p>{params.row?.userId?.name}</p>,
      valueGetter: (params) => params.row?.userId?.name,
      // valueFormatter: (params) => params.value?.name,
    },
    {
      editable: true,
      field: "address",
      headerName: "Emplacement",
    },
    {
      editable: true,
      field: "city",
      headerName: "Cité",
    },
    {
      field: "phone",
      headerName: "Téléphone",
      editable: true,
      renderCell: (params) => <p>{params.row?.userId?.phone}</p>,
      valueGetter: (params) => params.row?.userId?.phone,
    },
    {
      field: "rating",
      headerName: "Note",
      editable: true,

      renderCell: (params) => (
        <Rating readOnly value={params.row?.rating} precision={0.5} />
      ),
    },
    {
      field: "action",

      renderCell: (params) => {
        return (
          <>
            <AddEditUserPrestataireDataGridAction />
            <FreezePrestataireDataGridAction prestataire={params.row} />
            <DeletePrestataireDataGridAction id={params.row._id} />
          </>
        )
      },
    },
  ]

  // Center columns, filed and headerName
  columns = columns.map(
    (column, index) =>
      (column = {
        ...{
          headerName: capitalizeFirstLetter(column.field),
          flex: 1,
          headerAlign: "center",
          align: "center",
        },
        ...column,
      })
  )

  return columns
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
