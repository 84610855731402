import EditIcon from "@mui/icons-material/Edit"
import { IconButton } from "@mui/material"
import AddEditUserDialog from "../../components/Dashboard/UsersPage/AddEditUserDialog"

function AddEditUserPrestataireDataGridAction() {
  return (
    <AddEditUserDialog editMode={true}>
      <IconButton size="large">
        <EditIcon color="success" />
      </IconButton>
    </AddEditUserDialog>
  )
}

export default AddEditUserPrestataireDataGridAction
