import { Avatar } from "@mui/material"
import { useDispatch } from "react-redux"
import { resizePhotos } from "../../../functions/imageFunctions"
import {AddEditUserPrestataireDataGridAction} from "../../../GlobalComponent/dataGridActions"
import DeleteUserDataGridAction from "../../../GlobalComponent/dataGridActions/DeleteUserDataGridAction"
import {FreezeUserDataGridAction} from "../../../GlobalComponent/dataGridActions"
import { editUser } from "../../../redux/actions/users"

export default function UsersPageColumns() {
  const dispatch = useDispatch()

  const handleAddAvatar = async (e, params) => {
    const avatar = e.target.files[0]
    const compressedAvatar = await resizePhotos(avatar, 10)
    dispatch(editUser(params.row._id, { avatar: compressedAvatar }))
  }

  let columns = [
    {
      field: "avatar",
      renderCell: (params) => (
        <>
          <input
            id="avatarInput"
            type="file"
            hidden
            onChange={(e) => handleAddAvatar(e, params)}
          />
          <label htmlFor="avatarInput">
            <Avatar
              sx={{
                cursor: "pointer",
              }}
              src={params.row?.avatar}
              alt={params.row.name}
            />
          </label>
        </>
      ),
    },
    {
      field: "name",
      headerName: "Nom",
    },
    {
      field: "email",
    },
    {
      field: "phone",
      headerName: "Téléphone",
    },
    {
      field: "role",
      headerName: "Rôle",
      renderCell: (cellValues) => (
        <span className={`status ${cellValues.row.role}`}>
          {cellValues.row.role}
        </span>
      ),
      // cellClassName: (params) => `status ${params.row.role}`,
    },
    {
      field: "action",

      renderCell: (params) => {
        return (
          <>
            <AddEditUserPrestataireDataGridAction />
            <FreezeUserDataGridAction user={params.row} />
            <DeleteUserDataGridAction id={params.row._id} />
          </>
        )
      },
    },
  ]

  // Center columns, filed and headerName
  columns = columns.map(
    (column, index) =>
      (column = {
        ...{
          editable: true,
          headerName: capitalizeFirstLetter(column.field),
          flex: 1,
          headerAlign: "center",
          align: "center",
        },
        ...column,
      })
  )

  return columns
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
