import React from "react"
import ConfirmDialog from "../ConfirmDialog"
import DeleteIcon from "@mui/icons-material/Delete"
import { useDispatch } from "react-redux"
import { deletePrestataire } from "../../redux/actions/prestataires"

function DeletePrestataireDataGridAction({id}) {
  const dispatch = useDispatch()

  return (
    <ConfirmDialog
      actionFn={() => dispatch(deletePrestataire(id))}
      title="Supprimer un prestataire"
      text="Êtes vous sur de vouloir supprimer ce prestataire ?"
      feedback="Le prestataire a été supprimé."
    >
      <DeleteIcon color="error" />
    </ConfirmDialog>
  )
}

export default DeletePrestataireDataGridAction
