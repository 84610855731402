import { useCallback, useEffect, useState } from "react"
import "./style/App.css"

import useGeolocation from "react-hook-geolocation"
import CookieConsent from "./components/cookies/CookieConsent"
import CookieRulesDialog from "./components/cookies/CookieRulesDialog"
import fetchIpData from "./components/cookies/fetchIpData"
var lat, lng

function Cookies() {
  const [isCookieRulesDialogOpen, setIsCookieRulesDialogOpen] = useState(false)
  const geolocation = useGeolocation()

  const DEFAULT_LAT = 35.8283295
  const DEFAULT_LNG = 10.5830349

  if (geolocation && !geolocation.error) {
    lat = geolocation.latitude
    lng = geolocation.longitude
  } else {
    lat = DEFAULT_LAT
    lng = DEFAULT_LNG
  }

  const handleCookieRulesDialogOpen = useCallback(() => {
    setIsCookieRulesDialogOpen(true)
  }, [setIsCookieRulesDialogOpen])

  const handleCookieRulesDialogClose = useCallback(() => {
    setIsCookieRulesDialogOpen(false)
  }, [setIsCookieRulesDialogOpen])


  useEffect(() => {
    localStorage.removeItem("ipinfos")
    fetchIpData
      .then((data) => {
        data && localStorage.setItem("ipinfos", data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return (
    <>
      {!isCookieRulesDialogOpen && (
        <CookieConsent
          handleCookieRulesDialogOpen={handleCookieRulesDialogOpen}
        />
      )}

      <CookieRulesDialog
        open={isCookieRulesDialogOpen}
        onClose={handleCookieRulesDialogClose}
      />
    </>
  )
}

export default Cookies
