import * as actionType from "../actionTypes"
import * as API from 'api';

export const findReservation = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionType.LOADING_RESERVATION })
    const { data } = await API.findReservation(id)
    dispatch({ type: actionType.SET_RESERVATION, payload: data })
    dispatch({ type: actionType.END_LOADING_RESERVATION })
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const findReservations = () => async (dispatch) => {
  try {
    dispatch({ type: actionType.LOADING_RESERVATION })
    const { data } = await API.findReservations()
    dispatch({ type: actionType.SET_RESERVATIONS, payload: data })
    dispatch({ type: actionType.END_LOADING_RESERVATION })
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}
export const findMyReservations = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionType.LOADING_RESERVATION })
    const { data } = await API.findMyReservations(id)
    dispatch({ type: actionType.SET_RESERVATIONS, payload: data })
    dispatch({ type: actionType.END_LOADING_RESERVATION })
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}
export const findClientReservations = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionType.LOADING_RESERVATION })
    const { data } = await API.findClientReservations(id)
    dispatch({ type: actionType.SET_RESERVATIONS, payload: data })
    dispatch({ type: actionType.END_LOADING_RESERVATION })
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const createReservation = (newReservation) => async (dispatch) => {
  try {
    dispatch({ type: actionType.LOADING_RESERVATION })
    const { data } = await API.createReservation(newReservation)
    dispatch({ type: actionType.ADD_RESERVATION, payload: data })
    dispatch({ type: actionType.END_LOADING_RESERVATION })
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateReservation = (id, updatedReservation) => async (dispatch) => {
  try {
    dispatch({ type: actionType.LOADING_RESERVATION })
    const { data } = await API.updateReservation(id, updatedReservation)
    dispatch({ type: actionType.UPDATE_RESERVATION, payload: data })
    dispatch({ type: actionType.END_LOADING_RESERVATION })
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteReservation = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionType.LOADING_RESERVATION })
    const { data } = await API.deleteReservation(id)
    dispatch({ type: actionType.DELETE_RESERVATION, payload: data })
    dispatch({ type: actionType.END_LOADING_RESERVATION })
  } catch (error) {
    return Promise.reject(error)
  }
}
