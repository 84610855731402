import axios from "axios"

export var baseURL
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  baseURL = "http://localhost:5002"
} else {
  baseURL = "https://tunisian-event.herokuapp.com"
  //  baseURL = 'http://tunisia-events.com'
}

export const API = axios.create({ baseURL: baseURL })

API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile")).token
    }`
  }
  return req
})

/* //! -------------- Auth ------------------ */

export const signIn = (formData) => API.post("/auth/signin", formData)
export const signUp = (formData) => API.post("/auth/signup", formData)

export const forgotPassword = (formData) =>
  API.post(`/auth/forgotPassword`, formData)

export const resetPassword = (formData) =>
  API.post(`/auth/resetPassword`, formData)

//! ----------- Category -------------- */
export const findCategory = (id) => API.get(`/category/findOne/${id}`)

export const findCategories = () => API.get("/category/findAll")

export const createCategory = (newCategory) =>
  API.post("/category/create", newCategory)

export const updateCategory = (id, updatedCategory) =>
  API.patch(`/category/update/${id}`, updatedCategory)

export const deleteCategory = (id) => API.delete(`/category/delete/${id}`)

//! --------- Prestataires ------------ */

export const fetchPrestataire = (id) => API.get(`/prestataires/${id}`)
export const fetchPrestataires = () => API.get("/prestataires")
export const likePrestataire = (id) =>
  API.patch(`/prestataires/${id}/likePrestataire`)
export const commentPrestataire = (value, id) =>
  API.post(`/prestataires/${id}/commentPrestataire`, { value })
export const createPrestataire = (newPrestataire) =>
  API.post("/prestataires", newPrestataire)
export const updatePrestataire = (id, updatedPrestataire) =>
  API.patch(`/prestataires/${id}`, updatedPrestataire)

export const acceptRejectPrestataire = (id) =>
  API.put(`/prestataires/acceptRejectPrestataire/${id}`)

export const deletePrestataire = (id) => API.delete(`/prestataires/${id}`)

//! -------------- Posts ----------------- */}

export const listPosts = () => API.get("/posts/0/")
export const fetchPost = (id) => API.get(`/posts/${id}`)
export const fetchPosts = () => API.get("/posts")





export const getPublicPosts = () => API.get("/posts/getPublicPosts")
export const getWaitingPosts = () => API.get("/posts/getWaitingPosts")
export const getRejectedPosts = () => API.get("/posts/getRejectedPosts")
export const getArchivedPosts = () => API.get("/posts/getArchivedPosts")




export const createPost = (newPost) => API.post("/posts", newPost)
export const getPostsFromParentCategory = (id, formData) =>
  API.post("/posts/getPostsFromParentCategory/" + id, formData)

// -1 is the stating index of uploaded images (we add +1 each upload)
export const uploadImages = async (post, photos) => {
  var bodyFormData = new FormData()

  for (const photo of photos) {
    bodyFormData.append("images", photo)
  }

  // delete newPost.photos

  return await API.post(`/posts/uploadImages/${post._id}/-1`, bodyFormData)
}

export const likePost = (id, userId) =>
  API.put(`/posts/${id}/likePost`, { id, userId: userId })
export const comment = (value, id) =>
  API.post(`/posts/${id}/commentPost`, { value })
export const publicWaiting = (formData) =>
  API.put(`/posts/${formData.id}/publicWaiting`, formData)
export const topSwitch = (formData) =>
  API.put(`/posts/${formData.id}/topSwitch`, formData)
export const updatePost = (id, updatedPost) =>
  API.patch(`/posts/${id}`, updatedPost)
// export const updatePost = (id, updatedPost) => {
//   var bodyFormData = new FormData()
//   if (updatedPost.photos.length > 0) {
//     for (const photo of updatedPost.photos) {
//       bodyFormData.append("image", photo)
//     }
//   }
//   delete updatedPost.photos

//   console.log(bodyFormData.get("image"))
//   return API.patch(`/posts/${id}`, bodyFormData, {
//     headers: {
//       newpost: JSON.stringify(updatedPost),
//     },
//   })
// }
export const deletePost = (id) => API.delete(`/posts/${id}`)

{
  /* //! ------------  Users ---------------- */
}

export const ListUsers = () => API.get("/user/listUsers")
export const AddUser = (newUser) => API.post("/user/addUser", newUser)
export const EditUser = (id, newUser) => API.patch(`/user/${id}`, newUser)
export const DeleteUser = (id) => API.delete(`/user/${id}`)
export const blockUser = (id) => API.put(`/user/blockUser/${id}`)
export const getUser = (id, fields) =>
  API.get(`/user/getUser/${id}`, { params: { fields: fields } })
export const EditUserPassword = (id, oldPassword, newPassword) =>
  API.post(`/user/passwordUpdate/${id}`, {
    oldPassword: oldPassword,
    newPassword: newPassword,
  })

export const findUserNotifications = (id) =>
  API.get(`/user/findUserNotifications/${id}`)
export const clearUnreadNotifications = (id, notifications) =>
  API.put(`/user/clearUnreadNotifications/${id}`, { notifications })
export const deleteAllNotifications = (id) =>
  API.delete(`/user/deleteAllNotifications/${id}`)

// Chat

export const fetchChats = () => API.get("/chats")
export const fetchChatByPost = (id) => API.get(`/chats/${id}`)
export const createChatMessage = (newMessage) => API.post("/chats", newMessage)

{
  /* //! ----------- Reservation -------------- */
}

export const findReservation = (id) => API.get(`/reservations/findOne/${id}`)

export const findReservations = () =>
  API.get("/reservations/findAll")

export const findMyReservations = (id) =>
  API.get(`/reservations/findMyReservations/${id}`)

export const findClientReservations = (id) =>
  API.get(`/reservations/findClientReservations/${id}`)

export const createReservation = (newReservation) =>
  API.post("/reservations/create", newReservation)

export const updateReservation = (id, updatedReservation) =>
  API.patch(`/reservations/update/${id}`, updatedReservation)

export const deleteReservation = (id) =>
  API.delete(`/reservations/delete/${id}`)

{
  /* //! ---------- Notification -------------- */
}

export const findNotifications = (filters, fields) =>
  API.post("/notifications/findAll", {
    filters: filters,
    fields: fields,
  })

export const createNotification = (newNotification) =>
  API.post("/notifications/create", newNotification)

export const updateNotification = (id, updatedNotification) =>
  API.patch(`/notifications/update/${id}`, updatedNotification)

export const deleteNotification = (id) =>
  API.delete(`/notifications/delete/${id}`)
