import {
  COMMENT_PRESTATAIRE,
  CREATE_PRESTATAIRE,
  DELETE_PRESTATAIRE,
  END_LOADING_PRESTATAIRE,
  FETCH_ALL_PRESTATAIRE,
  FETCH_PRESTATAIRE,
  LIKE_PRESTATAIRE,
  START_LOADING_PRESTATAIRE,
  UPDATE_PRESTATAIRE,
} from "../actionTypes"

export default (
  state = { PrestatairesIsLoading: true, prestataires: [], prestataire: {} },
  action
) => {
  switch (action.type) {
    case START_LOADING_PRESTATAIRE:
      return { ...state, PrestatairesIsLoading: true }
    case END_LOADING_PRESTATAIRE:
      return { ...state, PrestatairesIsLoading: false }
    case FETCH_ALL_PRESTATAIRE:
      return { ...state, prestataires: action.payload.data }
    case FETCH_PRESTATAIRE:
      return { ...state, prestataire: action.payload.prestataire }
    case LIKE_PRESTATAIRE:
      return {
        ...state,
        prestataires: state.prestataires.map((p) =>
          p._id === action.payload._id ? action.payload : p
        ),
      }
    case COMMENT_PRESTATAIRE:
      return {
        ...state,
        prestataires: state.prestataires.map((p) =>
          p._id === action.payload._id ? action.payload : p
        ),
      }
    case CREATE_PRESTATAIRE:
      return {
        ...state,
        prestataires: [...state.prestataires, action.payload],
      }
    case UPDATE_PRESTATAIRE:
      return {
        ...state,
        prestataires: state.prestataires.map((p) =>
          p._id === action.payload._id ? action.payload : p
        ),
      }
    case DELETE_PRESTATAIRE:
      return {
        ...state,
        prestataires: state.prestataires.filter(
          (p) => p._id !== action.payload
        ),
      }
    default:
      return state
  }
}
