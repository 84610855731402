import React, { useEffect, useRef } from "react"
import clsx from "clsx"
import makeStyles from "@mui/styles/makeStyles"
import CssBaseline from "@mui/material/CssBaseline"
import Drawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import List from "@mui/material/List"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Badge from "@mui/material/Badge"
import MenuIcon from "@mui/icons-material/Menu"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import NotificationsIcon from "@mui/icons-material/Notifications"
import { mainListItems } from "./DashboardLinks"
import { Link, Outlet, Route, Routes } from "react-router-dom"
import { Collapse } from "@mui/material"
import ProtectedRoutes from "../../routes/ProtectedRoutes"
import NotificationPopper from "../Header/NotificationPopper"

const drawerWidth = 260

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
    position: "fixed",
    width: "100vw",
    height: "100vh",
    top: "0",
    left: "0",
    zIndex: "999",
    background: "#fff",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "#04495e",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    fontSize: "1.5rem",
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    background: "#04495e",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    minHeight: "100vh",
    overflow: "auto",
  },
  container: {
    position: "relative",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    background: "#fafafa",
  },
  sectionContainer: {
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
    borderRadius: "20px",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  notification: {
    boxShadow: "none",
    marginRight: "15px",
  },
}))

export default function Dashboard() {
  const currentUser = JSON.parse(localStorage.getItem("profile"))
  const classes = useStyles()
  const [open, setOpen] = React.useState(true)

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)

  // if (!user || user.result.role !== "admin") return "No Permission"

  return (
    <Collapse in>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
              size="large"
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Dashboard
              {currentUser.role === "admin" ? " Administrateur" : " Prestataire"}
            </Typography>

            {/* //! ---------- Notification -------------- */}
            <div style={{ marginRight: "1rem" }}>
              <NotificationPopper />
            </div>
            <Link to="/">
              <img src="/images/logo.png" alt="Logo" className="admin-logo" />
            </Link>
          </Toolbar>
        </AppBar>
        {/* Drawer */}
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton
              className="drawer-btn"
              onClick={handleDrawerClose}
              size="large"
            >
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List className="color-white">{mainListItems()}</List>
          {/* <Divider />
          <List>{secondaryListItems}</List> */}
        </Drawer>
        <main className={classes.content}>
          <div
            style={{
              marginTop: "87px",
              padding: "3rem",
              minHeight: "calc(100vh - 148px)",
            }}
          >
            <Outlet />
          </div>
          <Box sx={{ my: 4 }}>
            <Copyright />
          </Box>
        </main>
      </div>
    </Collapse>
  )
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Created by Creo ©{new Date().getFullYear()}.
    </Typography>
  )
}
