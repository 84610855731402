import { InputAdornment, Stack, TextField } from "@mui/material"
import React, { useContext } from "react"
import { AddPostContext } from "./AddPostDialog"

export function FormInputs(input) {
  const { handleChange, formData } = useContext(AddPostContext)

  return (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      spacing={4}
      sx={{ p: 4 }}
    >
      {input({ value: "title", label: "Titre" })}
      {input({ value: "date", props: { type: "date" } })}
      {input({
        value: "price",
        label: "Prix",
        props: {
          type: "number",
          InputProps: {
            endAdornment: <InputAdornment position="end">Dt</InputAdornment>,
            inputProps: { min: 0, max: 1000 },
          },
        },
      })}
      {input({ value: "description" })}
    </Stack>
  )

  function input({ value, label, props }) {
    return (
      <TextField
        
        fullWidth
        name={value}
        value={formData[value]}
        label={label ? label : capitalizeFirstLetter(value)}
        variant="outlined"
        onChange={handleChange}
        {...props}
        InputLabelProps={{ shrink: true }}
        placeholder={label ? label : capitalizeFirstLetter(value)}
      />
    )
  }
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
