import { CircularProgress, Stack } from "@mui/material"
import React from "react"

function LoadingPage() {
  return (
    <Stack
    direction="row"
    justifyContent="center"
    alignItems="center"
      spacing={2}
      sx={{width : "100%", height : "100%",}}
    >
      <CircularProgress size={100} />  
    </Stack>
  )
}

export default LoadingPage
