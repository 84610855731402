import * as actionType from "../actionTypes"
export default  (
  state = {
    reservation: {},
    reservations: [],
    loadingReservation : false
  },
  action
) => {
  const payload = action.payload
  switch (action.type) {
    case actionType.LOADING_RESERVATION:
      return { ...state, reservation: true }

    case actionType.END_LOADING_RESERVATION:
      return { ...state, reservation: false }

    case actionType.SET_RESERVATION:
      return { ...state, reservation: payload }
    
    case actionType.SET_RESERVATIONS:
      return { ...state, reservations: payload }

    case actionType.ADD_RESERVATION:
      return {
        ...state,
        reservations: [...state.reservations, payload],
      }

    case actionType.UPDATE_RESERVATION:
      return {
        ...state,
        reservations: state.reservations.map((reservation) =>
          reservation._id === payload._id ? payload : reservation
        ),
      }

    case actionType.DELETE_RESERVATION:
      return {
        ...state,
        reservations: state.reservations.filter(
          (reservation) => reservation._id !== payload
        ),
      }

    default:
      return state
  }
}


