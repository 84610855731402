import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const FormGenre = (props) => {
    const { genre, setGenre } = props;

    return (
        <>
            <FormControl component="fieldset">
                <FormLabel component="legend">Genre</FormLabel>
                <RadioGroup aria-label="gender" name="gender1" 
                            value={genre} 
                            onChange={(e) => setGenre(e.target.value)}
                            style={{flexDirection: 'row'}}
                            >
                    <FormControlLabel value="Femme" control={<Radio />} label="Female" />
                    <FormControlLabel value="Homme" control={<Radio />} label="Male" />
                </RadioGroup>
            </FormControl>
            
        </>
    );
};

export default FormGenre;