import EventNoteIcon from "@mui/icons-material/EventNote"
import LocalOfferIcon from "@mui/icons-material/LocalOffer"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import { Skeleton } from "@mui/material"
import moment from "moment"

export function TextOverBanner(isLoadingImage, post, setIsLoadingImage) {
  return (
    <div style={{ position: "relative" }}>
      {isLoadingImage && (
        <Skeleton style={{ transform: "scale(1)", height: "70vh" }} />
      )}
      <img
        style={{
          width: "100%",
          height: "70vh",
          display: isLoadingImage ? "none" : "block",
        }}
        src={
          post?.photos?.length
            ? post?.photos[0]
            : "https://picsum.photos/1000/500"
        }
        alt="Event image"
        onLoad={() => setIsLoadingImage(false)}
      />
      {/* Text over image */}
      {!isLoadingImage && (
        <div className="text-over-image">
          <h1> {post.title}</h1>
          <p>
            <EventNoteIcon /> {moment(post?.date).format("dddd MM, YYYY")}
          </p>

          <p>
            <LocationOnIcon style={{ marginRight: 10 }} />
            {post.prestataireId.userId?.name && post.prestataireId.userId?.name}
            {post.prestataireId?.city && ", " + post.prestataireId?.city}
            {post.prestataireId?.address && ", " + post.prestataireId?.address}
          </p>

          <p>
            <LocalOfferIcon /> {post?.price} DT
          </p>
        </div>
      )}
    </div>
  )
}
