// material-ui
import { useTheme, styled } from "@mui/material/styles"
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material"
import moment from "moment"
import { Fragment, useEffect } from "react"
import { Link } from "react-router-dom"

// assets
// import { IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto } from '@tabler/icons';
// import User1 from 'assets/images/users/user-round.svg';

// styles
const ListItemWrapper = styled("div")(({ theme }) => ({
  //   cursor: "pointer",
  padding: 16,
  "&:hover": {
    background: theme.palette.primary.light,
  },
  "& .MuiListItem-root": {
    padding: 0,
  },
}))

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({ dbNotification }) => {
  const theme = useTheme()

  const chipSX = {
    height: 24,
    padding: "0 6px",
  }
  const chipErrorSX = {
    ...chipSX,
    color: theme.palette.orange.dark,
    backgroundColor: theme.palette.orange.light,
    marginRight: "5px",
    cursor: "pointer" 
  }

  const chipWarningSX = {
    ...chipSX,
    color: theme.palette.warning.dark,
    backgroundColor: theme.palette.warning.light,
  }

  const chipSuccessSX = {
    ...chipSX,
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
    height: 28,
  }

  const processTime = (createdAt) => {
    const days = moment().diff(createdAt, "days")
    const hours = moment().diff(createdAt, "hours")
    const minutes = moment().diff(createdAt, "minutes")
    const seconds = moment().diff(createdAt, "seconds")

    if (days) return `Il y a ${days} jour${days > 1 ? "s" : ""}`
    if (hours) return `Il y a ${hours} heure${hours > 1 ? "s" : ""}`
    if (minutes) return `Il y a ${minutes} minute${minutes > 1 ? "s" : ""}`
    if (seconds) return `Il y a ${seconds} seconde${seconds > 1 ? "s" : ""}`
  }

  {
    /* //! ------------- Return ----------------- */
  }

  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 500,
        py: 0,
        borderRadius: "10px",
        [theme.breakpoints.down("lg")]: {
          maxWidth: 300,
        },
        "& .MuiListItemSecondaryAction-root": {
          top: 22,
        },
        "& .MuiDivider-root": {
          my: 0,
        },
        "& .list-container": {
          pl: 7,
        },
      }}
    >
      {dbNotification.map((notification, key) => (
        <Fragment key={key}>
          <ListItemWrapper>
            <ListItem alignItems="center">
              <ListItemAvatar>
                <Avatar
                  src={
                    notification.notificationId?.avatar
                      ? notification.notificationId?.avatar
                      : ""
                  }
                />
              </ListItemAvatar>
              <ListItemText primary={notification.notificationId?.title} />
              <ListItemSecondaryAction>
                <Grid container justifyContent="flex-end">
                  <Grid item xs={12}>
                    <Typography variant="caption" display="block" gutterBottom>
                      {processTime(notification.notificationId?.createdAt)}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItemSecondaryAction>
            </ListItem>
            <Grid container direction="column" className="list-container">
              <Grid item xs={12} sx={{ pb: 2 }}>
                <Typography variant="subtitle2">
                  {notification.notificationId?.content}
                </Typography>
              </Grid>
              {notification.notificationId?.linkHref && (
                <Grid  item xs={12}>
                  <Grid container>
                    <Link to={notification.notificationId?.linkHref}>
                      <Grid item>
                        <Chip
                          label={notification.notificationId?.linkLabel}
                          sx={chipErrorSX}
                        />
                      </Grid>
                    </Link>
                    {/* <Grid item>
                    <Chip label="New" sx={chipWarningSX} />
                  </Grid> */}
                  </Grid>
                </Grid>
              )}
            </Grid>
            {notification.notificationId?.image && (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        backgroundColor: theme.palette.secondary.light,
                      }}
                    >
                      <CardContent>
                        <Grid container direction="column">
                          <Grid item xs={12}>
                            <Stack direction="row" spacing={2}>
                              {/* <IconPhoto stroke={1.5} size="1.3rem" /> */}
                              <Typography variant="subtitle1">
                                demo.jpg
                              </Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </ListItemWrapper>
          <Divider />
        </Fragment>
      ))}
    </List>
  )
}

export default NotificationList
