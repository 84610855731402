import { Avatar } from "@mui/material"
import ViewFullscreen from "GlobalComponent/ViewFullscreen"
import moment from "moment"

import DeleteIcon from "@mui/icons-material/Delete"
import FlakyIcon from "@mui/icons-material/Flaky"
import ConfirmDialog from "../../../GlobalComponent/ConfirmDialog.js"
import { deletePost } from "../../../redux/actions/posts.js"
import EditFormPostDashboardAdmin from "../../../GlobalComponent/dashboard/EditFormPostDashboardAdmin.js"

function RejectedPostColumns(dispatch) {
  const handleDelete = async ({ row }) => {
    dispatch(deletePost(row._id))
  }

  let columns = [
    {
      field: "photos",
      headerName: "Photo",
      renderCell: (params) => (
        <Avatar
          src={
            params.row?.photos
              ? params.row?.photos[0]
              : "https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
          }
        />
      ),
    },
    {
      field: "title",
      headerName: "Titre",
      editable: true,
    },
    {
      field: "prestataireId",
      headerName: "Nom prestataire",
      // editable: true,
      valueFormatter: (params) => params.value?.userId?.name,
      // renderCell: (params) => <p>{params.row?.userId?.phone}</p>,
      // valueGetter:  (params) => params.value?.location
    },
    {
      field: "date",
      headerName: "Date évenement",
      valueFormatter: (params) =>
        moment(params.value).format("DD/MM/YYYY HH:mm"),
      // valueGetter: (params) => moment( params.value).format("DD/MM/YYYY HH:mm"),
      // valueGetter:  (params) => params.value?.location
      editable: false,
    },
    {
      field: "status",
      headerName: "Statut",
      valueFormatter: (params) => "En attente",

      // editable: true,
      // type: "singleSelect",
      // valueOptions: [
      //   { label: "En attente", value: "Waiting" },
      //   { label: "Publique", value: "Public" },
      //   { label: "Archivée", value: "Archived" },
      // ],
    },
    {
      field: "action",
      renderCell: (cellValues) => {
        return (
          <>
            <ViewFullscreen title="" Form={<EditFormPostDashboardAdmin readOnly={true} />}>
              <FlakyIcon sx={{ color: "#168700" }} />
            </ViewFullscreen>
            <ConfirmDialog
              actionFn={() => handleDelete(cellValues)}
              title="Supprimer une publication"
              text="Êtes vous sur de vouloir supprimer cette publication ?"
            >
              <DeleteIcon color="error" />
            </ConfirmDialog>
          </>
        )
      },
    },
  ]

  // Center columns, filed and headerName
  columns = columns.map(
    (column, index) =>
      (column = {
        ...{
          headerName: capitalizeFirstLetter(column.field),
          flex: 1,
          headerAlign: "center",
          align: "center",
        },
        ...column,
      })
  )

  return columns
}

export default RejectedPostColumns

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
