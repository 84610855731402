import React from "react"
import { Avatar, Button, Divider, Grid, Stack, TextField } from "@mui/material"
import MuiPhoneNumber from "material-ui-phone-number"

export function AddEditUserForm({ setFormData, formData }) {
  return (
    <>
      <Grid sx={{ my: 2 }} item xs={12}>
        <Divider>Informations Utilisateur</Divider>
      </Grid>
      <Grid item xs={6}>
        <Stack spacing={2}>
          {/* Name */}
          {input("name", "Nom")}
          {/* Email */}
          {input("email", "Adresse email", {
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
          {/* Phone */}
          <MuiPhoneNumber
            variant="outlined"
            label="Téléphone"
            onChange={(value) => setFormData({ ...formData, phone: value })}
            defaultCountry={"tn"}
          />
          {/* Password */}
          {input("password", "Mot de passe", { type: "password" })}
          {/* Role */}
          {/* {selectRole()} */}
        </Stack>
      </Grid>

      {/* Avatar */}
      <Grid item xs={6}>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <label htmlFor="addNewUserAvatarInput">
            <Avatar
              sx={{ width: 150, height: 150, cursor: "pointer" }}
              src={formData.avatar}
            />
          </label>
          <Button
            sx={{ mt: 2 }}
            onClick={() =>
              document.getElementById("addNewUserAvatarInput").click()
            }
          >
            Changer la photo de profil
          </Button>
        </Stack>
      </Grid>
    </>
  )

  function input(name, label, props) {
    return (
      <TextField
        name={name}
        value={formData[name]}
        onChange={(e) => setFormData({ ...formData, [name]: e.target.value })}
        label={label}
        variant="outlined"
        inputProps={{
          autoComplete: "disabled",
        }}
        {...props}
      />
    )
  }
}
