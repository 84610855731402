import React from "react"
import { useSelector } from "react-redux"
import { Navigate, Outlet } from "react-router-dom"

function ProtectedRoutes({ role = "user" }) {
  const authData = JSON.parse(localStorage.getItem("profile"))
  // const { authData } = useSelector((state) => state.auth)

  
  switch (role) {
    case "user":
      return authData ? <Outlet /> : <Navigate to="/auth" />
    case "client":
      return authData.role === role ? <Outlet /> : <Navigate to="/auth" />
    case "prestataire":
      return authData?.role === role ? <Outlet /> : <Navigate to="home" />
    case "admin":
      return authData?.role === role ? <Outlet /> : <Navigate to="home" />

    default:
      break
  }
}

export default ProtectedRoutes
