import {
  GET_USER,
  CREATE_USER,
  DELETE_USER,
  END_LOADING_USER,
  BLOCK_USER,
  LIST_USERS,
  START_LOADING_USER,
  UPDATE_USER,
} from "../actionTypes"

export default (
  state = { UsersIsLoading: true, users: [], user: {} },
  action
) => {
  switch (action.type) {
    case START_LOADING_USER:
      return { ...state, UsersIsLoading: true }
    case END_LOADING_USER:
      return { ...state, UsersIsLoading: false }
    case LIST_USERS:
      return { ...state, users: action.payload.data }
    case CREATE_USER:
      return { ...state, users: [...state.users, action.payload.user] }
    case UPDATE_USER:
      return {
        ...state,
        users: state.users.map((user) =>
          user._id === action.payload._id ? action.payload : user
        ),
      }
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user._id !== action.payload),
      }
    case BLOCK_USER:
      return {
        ...state,
        users: state.users.map((user) =>
          user._id === action.payload._id ? action.payload : user
        ),
      }
    case GET_USER:
      return { ...state, user: action.payload }
    default:
      return state
  }
}
