import AddBoxIcon from "@mui/icons-material/AddBox"
import FavoriteIcon from "@mui/icons-material/Favorite"
import HouseIcon from "@mui/icons-material/House"
import LoginIcon from "@mui/icons-material/Login"
import SettingsIcon from "@mui/icons-material/Settings"
import { Box, IconButton, Stack, Tooltip, useTheme } from "@mui/material"
import { Fragment, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { SHOW_NEW_POST_WINDOW } from "../../redux/actionTypes"
import { getWindowDimensions } from "./Header"
import NotificationPopper from "./NotificationPopper"
import ProfileMenu from "./ProfileMenuComponent"
import useStyles from "./styles"

export default function HeaderIcons({ logout }) {
  const dispatch = useDispatch()
  const { authData } = useSelector((state) => state.auth)
  const theme = useTheme()
  const { width } = useSelector((state) => state.misc)

  var buttonIcons
  switch (authData?.role) {
    case "admin":
      buttonIcons = [
        <NotificationPopper />,
        link("/admin/waiting_posts", SettingsIcon, "Dashboard"),
      ]
      break

    case "prestataire":
      buttonIcons = [
        link("", AddBoxIcon, "Ajouter une publication", () =>
          dispatch({ type: SHOW_NEW_POST_WINDOW, payload: true })
        ),
        <NotificationPopper />,
      ]
      break

    case "client":
      buttonIcons = [
        <NotificationPopper />,
        link("/liked", FavoriteIcon, "Favoris"),
      ]
      break

    default:
      buttonIcons = [link("/auth", LoginIcon, "Se connecter")]

      break
  }

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        {link("/home", HouseIcon, "Accueil")}
        {buttonIcons.map((link, key) => (
          <Fragment key={key}>{link}</Fragment>
        ))}
      </Box>

      {/* //! ---------- Profile Menu ------------- */}
      {authData && <ProfileMenu logout={logout} />}
    </Stack>
  )

  function link(path, Icon, title, onClick) {
    return (
      <Tooltip title={title}>
        <Link to={path}>
          <IconButton sx={theme.headerIcon} onClick={onClick} size="large">
            <Icon style={{ fontSize: "2rem" }} />
          </IconButton>
        </Link>
      </Tooltip>
    )
  }
}
