import { Button, Divider, Stack, Typography } from "@mui/material"
import { feedbackError, feedbackSuccess } from "redux/actions/feedback"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { signup, signupPrestataire } from "../../../redux/actions/auth"
import { RegisterInputs } from "./RegisterInputs"

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  password: "",
  confirmPassword: "",
  googleRequest: false,
}

function RegisterForm() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [userType, setUserType] = useState("prestataire")
  const [formData, setFormData] = useState(initialState)
  const [showPassword, setShowPassword] = useState(false)

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value })

  const handleShowPassword = () => setShowPassword(!showPassword)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (formData.password !== formData.confirmPassword)
      return dispatch(feedbackError("Mot de passe non identique."))

    try {
      if (formData.role === "client") await dispatch(signup(formData))
      else await dispatch(signupPrestataire(formData))
      navigate("/")
    } catch (error) {
      dispatch(feedbackError(error?.response?.data))
    }
  }

  useEffect(() => {
    setFormData({ ...formData, role: userType })
  }, [userType])

  return (
    <form onSubmit={handleSubmit} style={{ width: "40vw" }}>
      <Stack spacing={2}>
        <Typography sx={{ textAlign: "center" }} component="h1" variant="h3">
          {userType === "prestataire"
            ? "Inscription Prestataire"
            : "Inscription Client"}
        </Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Button onClick={() => setUserType("prestataire")}>
            Prestataire
          </Button>
          <Button onClick={() => setUserType("client")}>Client</Button>
        </Stack>
        {RegisterInputs(
          userType,
          showPassword,
          handleShowPassword,
          handleChange
        )}
        <Button
          type="submit"
          style={{
            width: "50%",
            margin: "auto",
            marginTop: "1rem",
            display: "block",
          }}
        >
          S'inscrire
        </Button>
        <Divider />
      </Stack>
    </form>
  )
}

export default RegisterForm
