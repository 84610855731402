import StarIcon from "@mui/icons-material/Star"
import StarBorderIcon from "@mui/icons-material/StarBorder"
import { Avatar } from "@mui/material"
import moment from "moment"
import {
  ArchivePost,
  DeletePostDataGridAction
} from "../../../GlobalComponent/dataGridActions/index.js"
import {
  topSwitch
} from "../../../redux/actions/posts.js"

function PublicPostColumns(dispatch) {
  const toggleTopPost = (row) => {
    dispatch(topSwitch({ id: row?._id, val: row.top ? false : true }))
  }

  let columns = [
    {
      field: "top",
      renderCell: (params) => {
        const Star = params?.row?.top ? StarIcon : StarBorderIcon

        return (
          <Star className="top-btn" onClick={() => toggleTopPost(params.row)} />
        )
      },

    },
    {
      field: "avatar",
      renderCell: (params) => (
        <Avatar
          src={
            params.row?.photos
              ? params.row?.photos[0]
              : "https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
          }
        />
      ),
    },
    { field: "title", editable: true },
    {
      field: "prestataireId",
      headerName: "Nom prestataire",
      valueFormatter: (params) => params.value?.userId?.name,
    },
    {
      field: "date",
      valueFormatter: (params) =>
        moment(params.value).format("DD/MM/YYYY HH:mm"),
      editable: false,
    },
    {
      field: "status",
      headerName: "Statut",
      valueFormatter: (params) => "Publique",

      // editable: editable.includes("status") ? false : true,
      // type: "singleSelect",
      // valueOptions: ["Waiting", "Public", "Archived"],
    },
    {
      field: "action",
      headerName: "Action",
      renderCell: (cellValues) => {
        return (
          <>
            <DeletePostDataGridAction id={cellValues.row._id} />
            <ArchivePost id={cellValues.row._id} />
          </>
        )
      },
    },
  ]

  // Center columns, filed and headerName
  columns = columns.map(
    (column, index) =>
      (column = {
        ...{
          editable: true,
          headerName: capitalizeFirstLetter(column.field),
          flex: 1,
          headerAlign: "center",
          align: "center",
        },
        ...column,
      })
  )

  return columns
}

export default PublicPostColumns

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
