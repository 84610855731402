import { Button, Grid, Stack, Typography } from "@mui/material"
import React, { useState, useEffect } from "react"
import Input from "../../Auth/Input"
import { feedbackError, feedbackSuccess } from "../../../redux/actions/feedback"
import { forgotPassword, resetPassword } from "../../../api"
import { useDispatch } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"

const initialState = {
  email: "",
  password: "",
  confirmPassword: "",
}

function ForgetPasswordForm() {
  let [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false)
  const handleShowPassword = () => setShowPassword(!showPassword)
  const [formData, setFormData] = useState(initialState)
  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value })

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      if (searchParams.get("token")) {
        await resetPassword({
          ...formData,
          token: searchParams.get("token"),
        })
        dispatch(
          feedbackSuccess(
            "Votre mot de passe a été modifié avec succès. Vous pouvez vous connecter."
          )
        )
        setTimeout(() => navigate("/"), 1500)
      } else {
        await forgotPassword(formData)
        dispatch(
          feedbackSuccess(
            "Un email vous a été envoyé pour réinitialiser votre mot de passe."
          )
        )
      }
    } catch (error) {
      dispatch(feedbackError(error?.response?.data))
    }
  }

  return (
    <form style={{ width: "100%" }}>
      <Stack alignItems="center" spacing={2}>
        {searchParams.get("token") ? (
          <>
            {input({
              label: "Mot de passe",
              name: "password",
              type: "password",
              handleShowPassword: handleShowPassword,
            })}
            {input({
              label: "Confirmer mot de passe",
              name: "confirmPassword",
              type: "password",
              handleShowPassword: handleShowPassword,
            })}
          </>
        ) : (
          input({ label: "Email", name: "email", type: "email" })
        )}

        <Button onClick={handleSubmit} fullWidth>
          Confirmer
        </Button>
      </Stack>
    </form>
  )
  function input(props) {
    return (
      <Input
        fullWidth
        value={formData[props.name]}
        placeholder="Entrez votre email"
        handleChange={handleChange}
        {...props}
      />
    )
  }
}

export default ForgetPasswordForm
