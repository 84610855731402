import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  media: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',

  },
  card: {
    display: 'block',
    width: '100%',
  },
  section: {
    borderRadius: '20px',
    margin: 'auto',
    padding: '40px 0',
    flex: 1,
  },
  imageSection: {
    width: "100%",
    height: "350px",
    position: 'relative'
  },
  recommendedPosts: {
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
  },
  loadingPaper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    minHeight: '100vh',
    background: 'none',
    boxShadow: 'none',
  },
  commentsOuterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  commentsInnerContainer: {
    height: '300px',
    overflowY: 'auto',
    marginRight: '30px',
    width: '66.66%',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    background: 'linear-gradient(to right bottom,rgba(176, 150, 106, 0.8), rgba(226, 217, 200, 0.8) )',
    borderRadius: '20px',
    height: 350,
  },
}));
