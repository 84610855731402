import { DialogTitle } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import * as React from "react"
import AddEditUserPrestataireForm from "./AddEditUserPrestataireForm"

export default function AddEditUserDialog({ editMode, children }) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      {React.cloneElement(children, { onClick: handleClickOpen })}

      <Dialog open={open} onClose={handleClose} maxWidth="xl">
        <DialogTitle>
          Modifier un utilisateur
        </DialogTitle>
        <AddEditUserPrestataireForm handleClose={handleClose} />
      </Dialog>
    </div>
  )
}
