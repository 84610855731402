import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import Footer from "./components/Footer/Footer"
import Header from "./components/Header/Header"
import { findCategories } from "./redux/actions/categories.js"
import { getPosts, getPublicPosts } from "./redux/actions/posts.js"
import { getPrestataires } from "./redux/actions/prestataires.js"
import { listUsers } from "./redux/actions/users.js"
import MainRoutes from "./routes/MainRoutes.js"
import "./style/App.css"

import socketIOClient from "socket.io-client"
import { baseURL } from "./api/index.js"
import AddPostDialog from "./components/AddPost/AddPostDialog"
import Cookies from "./Cookies"
import SnackbarFeedback from "./GlobalComponent/SnackbarFeedback"
export var socket

const App = () => {
  const dispatch = useDispatch()
  const { authData } = useSelector((state) => state.auth)


  // Socket connection
  useEffect(() => {
    if (!authData) return
    socket = socketIOClient(baseURL)
    socket.on("connect", () => {
      console.log(`Socket connection Id : ${socket.id}`)
      socket.emit("registerUserToTheList", { ...authData, photos: [] })
    })

    socket?.on("redux-update", (dataToDispatch) => {
      // return
      console.log(dataToDispatch)
      if (dataToDispatch?.type)
        dispatch({
          type: dataToDispatch?.type,
          payload: dataToDispatch?.payload,
        })
    })

    return () => socket.disconnect()
  }, [authData])

  useEffect(() => {
    dispatch(listUsers())
    dispatch(getPosts())
    dispatch(getPublicPosts())
    dispatch(findCategories())
    dispatch(getPrestataires())

    const currentUser = JSON.parse(localStorage.getItem("profile"))

    if (currentUser) {
      dispatch({ type: "AUTH", payload: currentUser })
    }

    function handleResize() {
      dispatch({ type: "SET_WIDTH", payload: window.outerWidth })
    }

    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <BrowserRouter>
      <Cookies />
      <SnackbarFeedback />
      <AddPostDialog />

      <Header />

      <div style={{ minHeight: "100vh", paddingTop: "80px" }}>
        <MainRoutes />
      </div>
      <Footer />
    </BrowserRouter>
  )
}

export default App
