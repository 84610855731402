import { Avatar, IconButton } from "@mui/material"
import React from "react"
import moment from "moment"
import DeleteIcon from "@mui/icons-material/Delete"
import ConfirmDialog from "../../../GlobalComponent/ConfirmDialog.js"
import { deletePost } from "../../../redux/actions/posts.js"

function ArchivedPostColumns(dispatch) {
  const handleDelete = async ({ row }) => {
    dispatch(deletePost(row._id, { status: "Archived" }))
  }

  let columns = [
    {
      field: "photos",
      headerName: "Photo",
      renderCell: (params) => (
        <Avatar
          src={
            params.row?.photos
              ? params.row?.photos[0]
              : "https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
          }
        />
      ),
    },
    {
      field: "title",
      headerName: "Titre",
      editable: true,
    },
    {
      field: "prestataireId",
      headerName: "Nom prestataire",
      valueFormatter: (params) => params.value?.userId?.name,
    },
    {
      field: "date",
      headerName: "Date évenement",
      valueFormatter: (params) =>
        moment(params.value).format("DD/MM/YYYY HH:mm"),
      editable: false,
    },
    {
      field: "status",
      headerName: "Statut",
      valueFormatter: (params) => "Archivée",
    },
    {
      field: "action",
      renderCell: (cellValues) => {
        return (
          <>
            <ConfirmDialog
              actionFn={() => handleDelete(cellValues)}
              title="Supprimer une publication"
              text="Êtes vous sur de vouloir supprimer cette publication ?"
            >
              <DeleteIcon color="error" />
            </ConfirmDialog>
          </>
        )
      },
    },
  ]

  // Center columns, filed and headerName
  columns = columns.map(
    (column, index) =>
      (column = {
        ...{
          headerName: capitalizeFirstLetter(column.field),
          flex: 1,
          headerAlign: "center",
          align: "center",
        },
        ...column,
      })
  )

  return columns
}

export default ArchivedPostColumns

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
