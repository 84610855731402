import React, { useEffect, useState } from "react"
import useStyles from "./styles"
import { useDispatch, useSelector } from "react-redux"
import { Avatar, Button, CardMedia, Chip, Grid } from "@mui/material"
import { findCategories } from "../../redux/actions/categories"
import Compress, { convertBase64ToFile } from "compress.js"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { getUser, editUser, EditUserPassword } from "../../redux/actions/users"
import TextField from "@mui/material/TextField"
import makeStyles from "@mui/styles/makeStyles"
import FormGenre from "./FormGenre"
import InputLabel from "@mui/material/InputLabel"
import NativeSelect from "@mui/material/NativeSelect"
import moment from "moment"
import { useNavigate } from "react-router-dom"
// import 'react-phone-number-input/style.css' ;
// import PhoneInput from 'react-phone-number-input';

export default function Profile() {
  const dispatch = useDispatch()
  const compress = new Compress()
  const navigate = useNavigate()
  const classes = useStyles()
  const [feedback, setFeedback] = useState(null)
  const [passwordFeedback, setPasswordFeedback] = useState(null)
  const [oldPasswordFeedback, setOldPasswordFeedback] = useState(null)
  const { user } = useSelector((state) => state.users)
  const { categories, CategoriesIsLoading } = useSelector(
    (state) => state.category
  )
  const profile = localStorage.getItem("profile")
    ? JSON.parse(localStorage.getItem("profile"))
    : null
  const [profileOptions, setProfileOptions] = useState({
    categoriesIds: [],
    profile: {},
  })
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    avatar: null,
    genre: "",
    email: "",
    phone: "",
    birthday: moment("2000-05-30T12:00").format("YYYY-MM-DD"),
  })
  const [oldPassword, setOldPassword] = useState("")
  const [password, setPassword] = useState("")

  useEffect(() => {
    dispatch(findCategories())
  }, [dispatch])

  useEffect(() => {
    if (profile) {
      dispatch(getUser(profile.result._id))
    } else {
      navigate("/auth")
    }
  }, [dispatch])

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name?.split(" ")[0],
        surname: user.name?.split(" ")[1],
        avatar: user.avatar,
        genre: user.genre || "",
        email: user.email,
        phone: user.phone,
        birthday: moment(user.birthday).format("YYYY-MM-DD"),
      })
    }
  }, [user])

  const handleSelectFiles = (e, type) => {
    e.preventDefault()
    switch (type) {
      case "avatar":
        document.querySelector("#input-avatar-native input[type=file]").click()
        break
      default:
        console.log("Error")
        break
    }
  }

  const onAddAvatarFile = (e) => {
    let file = e.target.files[0]
    if (file) {
      resizeAvatar(file).then((res) => {
        setFormData({
          ...formData,
          avatar: `data:image/${res.ext};base64,${res.base64}`,
        })
      })
    }
  }

  const resizeAvatar = async (file) => {
    const resizedImage = await compress.compress([file], {
      size: 2, // the max size in MB, defaults to 2MB
      quality: 1, // the quality of the image, max is 1,
      maxWidth: 200, // the max width of the output image, defaults to 1920px
      maxHeight: 200, // the max height of the output image, defaults to 1920px
      resize: true, // defaults to true, set false if you do not want to resize the image width and height
    })
    const img = resizedImage[0]
    const res = {
      base64: img.data,
      ext: img.ext,
    }
    return res
  }

  const handleAddChip = (category) => {
    setProfileOptions({
      ...profileOptions,
      categoriesIds: [...profileOptions.categoriesIds, category],
    })
  }

  const handleDeleteChip = (category) => {
    setProfileOptions({
      ...profileOptions,
      categoriesIds: profileOptions.categoriesIds.filter((c) => c !== category),
    })
  }

  const handleSelectBirthday = (date) => {
    setFormData({ ...formData, birthday: date.target.value })
  }

  // Validation Function
  const validation = () => {
    let isValid = true
    const emailReg =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    document.getElementById("feedback").style.color = "#F00"
    if (formData.genre === "") {
      isValid = false
      setFeedback("veuillez selectionner votre genre")
    }
    if (formData.name === "") {
      isValid = false
      document.getElementById("name").style.border = "1px solid #F00"
    }
    if (formData.surname === "") {
      isValid = false
      document.getElementById("surname").style.border = "1px solid #F00"
    }
    if (formData.email === "" || !emailReg.test(formData.email)) {
      isValid = false
      document.getElementById("email").style.border = "1px solid #F00"
    }
    if (formData.phone === "") {
      isValid = false
      document.getElementById("phone").style.border = "1px solid #F00"
    }

    return isValid
  }

  // Save Function
  const handleSave = (e) => {
    e.preventDefault()
    if (validation()) {
      setFeedback("")
      dispatch(
        editUser(user._id, {
          ...formData,
          name: `${formData.name} ${formData.surname}`,
        })
      )
      document.getElementById("feedback").style.color = "#080"
      setFeedback("Profile enregistré avec success")
    }
  }

  const handleSavePassword = (e) => {
    e.preventDefault()
    if (validation()) {
      setPasswordFeedback("")
      dispatch(
        EditUserPassword(user._id, {
          oldPassword: oldPassword,
          newPassword: password,
        })
      )
      document.getElementById("passwordFeedback").style.color = "#080"
      setPasswordFeedback("Mot de passe modifié avec success")
    }
    let passwordIsValid = true
    // const passwordReg = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-+!*$@%_])([-+!*$@%_\w]{10,15})$/i;
    const passwordReg = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).*$/

    if (oldPassword === "") {
      document.getElementById("old-password").style.border = "1px solid #F00"
      setOldPasswordFeedback(
        "Veuillez saisir votre ancien mot de passe correctement"
      )
      passwordIsValid = false
    }

    if (password === "" || !passwordReg.test(password)) {
      document.getElementById("new-password").style.border = "1px solid #F00"
      setPasswordFeedback(
        "Le mot de passe doit contenir au moins 10 caractères,1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial"
      )
      passwordIsValid = false
    }

    dispatch(EditUserPassword(profile.result._id, oldPassword, password))
  }

  return (
    <Grid container maxWidth="xl" spacing={8} className={classes.mainContainer}>
      <Grid item xs={12} md={12}>
        <h3 className={classes.title}>Les Catégories favoris:</h3>
        <div className="categories-container">
          {CategoriesIsLoading ? (
            <span className="loading">
              <img src="/images/admin-loading.gif" alt="Loading" />
            </span>
          ) : null}
          {categories?.map((Category) => (
            <Chip
              key={Category._id}
              size="small"
              label={Category.title}
              avatar={
                <Avatar
                  alt={Category.title}
                  src={Category.image || "/images/default.png"}
                />
              }
              color={
                profileOptions?.categoriesIds?.find((c) => c === Category._id)
                  ? "secondary"
                  : "primary"
              }
              onClick={() => {
                profileOptions?.categoriesIds?.find((c) => c === Category._id)
                  ? handleDeleteChip(Category._id)
                  : handleAddChip(Category._id)
              }}
            />
          ))}
        </div>
      </Grid>

      <Grid item xs={12} md={12} className={classes.inputContainer}>
        <Grid container maxWidth="xl" spacing={8}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={8}>
              <Grid item xs={12} md={6}>
                <FormGenre
                  genre={formData.genre}
                  setGenre={(data) => setFormData({ ...formData, genre: data })}
                />
                <TextField
                  fullWidth
                  id="name"
                  label="Prénom "
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  style={{ marginTop: "15px" }}
                />
                <TextField
                  fullWidth
                  id="email"
                  label="Email"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  style={{ marginTop: "15px" }}
                />
                <Button
                  className={classes.submitButton}
                  type="button"
                  style={{ marginTop: "25px" }}
                  onClick={(e) => handleSave(e)}
                >
                  Enregistrer
                </Button>
                <p id="feedback">{feedback}</p>
              </Grid>
              <Grid item xs={12} md={6}>
                <div>
                  <InputLabel htmlFor="select"></InputLabel>
                  <TextField
                    fullWidth
                    id="birthday"
                    label="Anniversaire"
                    type="date"
                    value={formData.birthday}
                    onChange={handleSelectBirthday}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <TextField
                  fullWidth
                  id="surname"
                  label="Nom"
                  value={formData.surname}
                  onChange={(e) =>
                    setFormData({ ...formData, surname: e.target.value })
                  }
                  style={{ marginTop: "25px" }}
                />
                <TextField
                  fullWidth
                  id="phone"
                  label="Téléphone"
                  value={formData.phone}
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                  style={{ marginTop: "15px" }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <div>
              <div className={classes.avatar}>
                <CardMedia
                  className="selected-file"
                  image={formData?.avatar}
                  title={formData?.name}
                />
              </div>
              <Button
                variant="contained"
                className={classes.button}
                startIcon={<CloudUploadIcon />}
                onClick={(e) => handleSelectFiles(e, "avatar")}
              >
                Télécharger votre photo
              </Button>
            </div>
          </Grid>
        </Grid>
        <div id="input-avatar-native">
          <input
            type="file"
            onChange={(e) => onAddAvatarFile(e)}
            accept="image/*"
          />
        </div>
      </Grid>

      <Grid container maxWidth="xl" spacing={8}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={8} style={{ paddingLeft: "40px" }}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="old-password"
                type="password"
                label="Ancien mot de passe"
                style={{ marginTop: "15px", marginBottom: "25px" }}
                onChange={(e) => setOldPassword(e.target.value)}
              />
              <Button
                className={classes.submitButton}
                type="button"
                onClick={(e) => handleSavePassword(e)}
              >
                Modifier le mot de passe
              </Button>
              <p id="oldPasswordFeedback">{oldPasswordFeedback}</p>
              <p id="passwordFeedback">{passwordFeedback}</p>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="new-password"
                type="password"
                label="Nouveau mot de passe"
                style={{ marginTop: "15px", marginBottom: "25px" }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}></Grid>
      </Grid>
    </Grid>
  )
}
