import React, { useContext } from "react"
import CategorySelectionGrid from "../../GlobalComponent/CategorySelectionGrid"
import { AddPostContext } from "./AddPostDialog"

function SelectCategories() {
  const { setFormData, formData } = useContext(AddPostContext)

  return (
    <div style={{ width: "100%", height: "50vh", overflowX: "scroll" }}>
      <h3>
      Sélectionner vos catégories
      </h3>
      <CategorySelectionGrid setFormData={setFormData} formData={formData} />
    </div>
  )
}

export default SelectCategories
