import ThumbUpIcon from "@mui/icons-material/ThumbUp"
import { LoadingButton } from "@mui/lab"
import {
  Button,
  Grid,
  InputLabel,
  Rating,
  Stack,
  TextField
} from "@mui/material"
import moment from "moment"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AddImages } from "../../components/AddPost/AddImages.js"
import {
  feedbackError,
  feedbackSuccess,
  submitAction
} from "../../redux/actions/feedback.js"
import { updatePost } from "../../redux/actions/posts.js"
import CategorySelection from "../CategorySelection.js"
import ConfirmDialog from "../ConfirmDialog.js"

const initForm = {
  categoriesIds: [],
  prestataireId: "",
  title: "",
  date: "",
  description: "",
  price: "",
  photos: [],
  rating: 0,
  status: "WAITING",
  motif: "",
}

export default function EditFormPostDashboardAdmin({ handleClose, readOnly }) {
  const dispatch = useDispatch()
  const { selectedRow } = useSelector((state) => state.dataGrid)
  const { isLoading } = useSelector((state) => state.misc)


  const [formData, setFormData] = useState(initForm)
  const handleChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    if (selectedRow) {
      let date = moment(selectedRow.date).format("YYYY-MM-DD")
      setFormData({
        ...selectedRow,
        date: date,
      })
    }
  }, [selectedRow])

  const onSubmit = async () => {
    try {
      await dispatch(updatePost(formData._id, formData))
      dispatch(feedbackSuccess("Catégorie Modifiée."))
      setTimeout(() => {
        handleClose()
      }, 1500)
    } catch (error) {
      dispatch(feedbackError(error?.response?.data))
    }
  }

  const acceptRejectPost = async (status) => {
    const feedback =
      status === "PUBLIC" ? "Publication Acceptée." : "Publication Rejetée."

    if (status == "PUBLIC" && formData.rating == 0)
      return dispatch(feedbackError("Veuillez noter la publication."))

    formData.status = status
    dispatch(
      submitAction({
        actionFn: updatePost(formData._id, formData),
        feedback: feedback,
        handleClose: handleClose,
      })
    )
  }

  return (
    <Grid sx={{ p: 4 }} container spacing={5}>
      <Grid item xs={12}>
        <h3>Informations sur la publication</h3>
      </Grid>
      <Grid item xs={6}>
        <Stack spacing={3}>
          {input({ label: "Titre", name: "title" })}
          {input({ name: "date", type: "date" })}
          {input({
            label: "Prix",
            name: "price",
            props: {
              inputProps: {
                inputMode: "numeric",
                pattern: "[0-9]*",
                min: 0,
                max: 1000000,
              },
            },
            type: "number",
          })}
        </Stack>
      </Grid>

      <Grid item xs={6}>
        <Stack spacing={3}>
          {input({
            name: "description",
            props: { multiline: true, rows: 4 },
          })}
          <AddImages
            height="25vh"
            formData={formData}
            setFormData={setFormData}
            readOnly={readOnly}
          />{" "}
          {!readOnly && <Button onClick={onSubmit}>Modifier</Button>}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <hr />

        <h3 style={{ marginTop: "2rem" }}>Donnez votre avis</h3>
      </Grid>

      <Grid item xs={12}>
        <CategorySelection formData={formData} setFormData={setFormData} />

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="give-rating"
        >
          <div>
            <InputLabel sx={{ fontSize: "0.6em" }}>
              Choisissez une note
            </InputLabel>
            <Rating
              value={formData.rating}
              onChange={(event, newValue) => {
                setFormData({ ...formData, rating: newValue })
              }}
              precision={1}
              size="large"
              // sx ={{fontSize : 24}}
            />
          </div>

          <Stack direction="row" spacing={2}>
            {/* <Button disabled={isLoading} onClick={() => acceptRejectPost("PUBLIC")}>Accepter</Button> */}

            <LoadingButton
              loading={isLoading}
              loadingPosition="start"
              startIcon={<ThumbUpIcon />}
              onClick={() => acceptRejectPost("PUBLIC")}
            >
              Accepter
            </LoadingButton>

            <ConfirmDialog
              actionFn={() => acceptRejectPost("REJECTED")}
              title="Rejeter une publication"
              text="Êtes vous sur de vouloir rejeter cette publication ?"
              feedback="Publication rejetée."
              Form={<MotifTextField />}
            >
              <Button style={{'fontSize' : "19px"}} >Rejeter</Button>
            </ConfirmDialog>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  )

  function MotifTextField() {
    return (
      <TextField
        autoFocus
        margin="dense"
        required
        fullWidth
        variant="standard"
        name="motif"
        label="Motif"
        value={formData.motif}
        onChange={handleChange}
      />
    )
  }

  function input({ name, label, props }) {
    return (
      <TextField
        // disabled={readOnly}
        fullWidth
        label={label ? label : capitalizeFirstLetter(name)}
        variant="outlined"
        name={name}
        value={formData[name]}
        onChange={readOnly ? false : handleChange}
        {...props}
      />
    )
  }
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
