import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import App from "./App"
import store from "./redux/store"
import useGeolocation from "react-hook-geolocation"

import { StyledEngineProvider, ThemeProvider } from "@mui/material"
import themes from "./style/themes"

// To overwrite default MUI components style
export const themeOptions = {
  headerIcon: {
    fontSize: "1.5rem",
    color: "#F76C6F",
    cursor: "pointer",
    //  margin: "4px 5px"
  },
}

// let theme = responsiveFontSizes(createTheme(themeOptions))

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <StyledEngineProvider injectFirst>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes()}>
          <App />
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </StyledEngineProvider>
)
