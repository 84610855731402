import React, { useEffect, useMemo, useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import Paper from "@mui/material/Paper"
import { useDispatch, useSelector } from "react-redux"
import DataGridComponent from "../DataGridComponent/DataGridComponent.js"
import PrestatairePageColumns from "./PrestatairePageColumns.js"
import { updatePrestataire } from "redux/actions/prestataires.js"
import { SET_SELECTED_ROW } from "../../../redux/actionTypes"

export default function PrestatairesPage() {
  const dispatch = useDispatch()
  var { prestataires } = useSelector((state) => state.prestataires)
  const [prestataireColumns, setPrestataireColumns] = useState()

  useEffect(() => {
    setPrestataireColumns(PrestatairePageColumns(dispatch))

    return () => dispatch({ type: SET_SELECTED_ROW, payload: null })
  }, [])

  return (
    <div className="prestatairePage">
      <Paper sx={{ p: 3, mb: 3 }} elevation={4}>
        <div className="paper-content">
          <h3 className="mytitle">Liste des prestataires</h3>

          <div>
            <DataGridComponent
              rows={prestataires}
              columns={prestataireColumns}
              getRowClassName={(params) =>
                `client-row ${
                  params.row.userId?.block === true ? " freezed" : ""
                }`
              }
              onRowEdit={(newRow) =>
                dispatch(updatePrestataire(newRow._id, newRow))
              }
            />
          </div>
        </div>
      </Paper>
    </div>
  )
}
