import React, { useEffect, useState } from "react"
import Paper from "@mui/material/Paper"
import { useDispatch, useSelector } from "react-redux"
import {
  updatePost,
} from "../../../redux/actions/posts.js"

import DataGridComponent from "../DataGridComponent/DataGridComponent.js"
import ArchivedPostColumns from "./ArchivedPostColumns.js"
import { SET_SELECTED_ROW } from "../../../redux/actionTypes"

export default function WaitingPosts() {
  const dispatch = useDispatch()
  const { posts, PostsIsLoading } = useSelector((state) => state.posts)
  const [columns, setColumns] = useState([])

  useEffect(() => {
    setColumns(ArchivedPostColumns(dispatch))

    return () => dispatch({ type: SET_SELECTED_ROW, payload: null })
  }, [])

  return (
    <div className="waitingPostPage">
      <Paper sx={{ p: 3 }} elevation={4}>
        <h3 style={{ marginBottom: "1rem" }}>Publications Archivées </h3>

        <DataGridComponent
          rows={posts.filter((post) => post.status === "ARCHIVED")}
          columns={columns}
          onRowEdit={(newRow) => dispatch(updatePost(newRow._id, newRow))}
          loading={PostsIsLoading}

        />
      </Paper>

    </div>
  )
}


// import React, { useEffect, useState } from "react"
// import makeStyles from "@mui/styles/makeStyles"
// import Table from "@mui/material/Table"
// import TableBody from "@mui/material/TableBody"
// import TableCell from "@mui/material/TableCell"
// import TableContainer from "@mui/material/TableContainer"
// import TableHead from "@mui/material/TableHead"
// import TableRow from "@mui/material/TableRow"
// import Paper from "@mui/material/Paper"
// import { useDispatch, useSelector } from "react-redux"
// import ConfirmModal from "../ConfirmModal.js"
// import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
// import Grid from "@mui/material/Grid"
// import AddCircleIcon from "@mui/icons-material/AddCircle"
// import EditIcon from "@mui/icons-material/Edit"
// import { CircularProgress } from "@mui/material"
// import { findCategories } from "../../../redux/actions/categories.js"
// import { getPrestataires } from "../../../redux/actions/prestataires.js"
// import Avatar from "@mui/material/Avatar"
// import { listUsers } from "../../../redux/actions/users.js"
// import {
//   deletePost,
//   getPosts,
//   publicWaiting,
//   topSwitch,
// } from "../../../redux/actions/posts.js"
// import AddPostModal from "../Modals/AddPostModal.js"
// import moment from "moment"
// import PublicIcon from "@mui/icons-material/Public"
// import BlockIcon from "@mui/icons-material/Block"
// import StarBorderIcon from "@mui/icons-material/StarBorder"
// import StarIcon from "@mui/icons-material/Star"
// import ShowDocumentsDialog from "../WaitingPostsPage/ShowConfirmationDialog.js"
// // import FavoriteIcon from '@mui/icons-material/Favorite';

// const useStyles = makeStyles({
//   table: {
//     minWidth: "100%",
//   },
//   paper: {
//     boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
//     borderRadius: "20px",
//     minHeight: "75vh",
//   },
// })

// const tableHeader = (
//   <TableHead className="custom-thead">
//     <TableRow>
//       <TableCell>Top</TableCell>
//       <TableCell>Avatar</TableCell>
//       <TableCell>Title</TableCell>
//       <TableCell>Location</TableCell>
//       <TableCell>Date</TableCell>
//       {/* <TableCell>Likes</TableCell> */}
//       <TableCell>Status</TableCell>
//       <TableCell>Actions</TableCell>
//     </TableRow>
//   </TableHead>
// )
// export default function ArchivedPosts() {
//   const classes = useStyles()
//   const dispatch = useDispatch()
//   const { posts, PostsIsLoading } = useSelector((state) => state.posts)
//   const { users, UsersIsLoading } = useSelector((state) => state.users)
//   const { prestataires, PrestatairesIsLoading } = useSelector(
//     (state) => state.prestataires
//   )
//   const { categories, CategoriesIsLoading } = useSelector(
//     (state) => state.category
//   )
//   const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] =
//     useState(false)
//   const [selectedPost, setSelectedPost] = useState(null)
//   const [showAddPost, setShowAddPost] = useState(false)
//   const [unPublicConfirmation, setUnPublicConfirmation] = useState(false)

//   const selectEditPost = (post) => {
//     setSelectedPost(post)
//     setShowAddPost(true)
//   }

//   const handleCloseAddPost = () => {
//     reloadData()
//     setShowAddPost(false)
//   }

//   const handleOpenAddPost = () => {
//     setSelectedPost(null)
//     setShowAddPost(true)
//   }

//   const handleDeletePost = () => {
//     dispatch(deletePost(selectedPost._id)).then(() => {
//       setShowDeleteConfirmationDialog(false)
//     })
//   }

//   // Public / Unpublic

//   const showUnPublicConfirmation = (post) => {
//     setSelectedPost(post)
//     setUnPublicConfirmation(true)
//   }

//   const unPublic = () => {
//     dispatch(publicWaiting({ id: selectedPost._id, status: "Archived" }))
//     setUnPublicConfirmation(false)
//   }

//   const publicPost = (id) => {
//     dispatch(publicWaiting({ id: id, status: "Public" }))
//   }

//   const topPost = (id) => {
//     dispatch(topSwitch({ id, val: true }))
//   }

//   const noTopPost = (id) => {
//     dispatch(topSwitch({ id, val: false }))
//   }

//   const reloadData = () => {
//     dispatch(getPosts())
//     dispatch(listUsers())
//     dispatch(getPrestataires())
//   }

//   useEffect(() => {
//     dispatch(getPosts())
//     dispatch(listUsers())
//     dispatch(getPrestataires())
//     dispatch(findCategories())
//   }, [dispatch])

//   return (
//     <Grid container>
//       <Grid item xs={12}>
//         <Paper className={classes.paper}>
//           <div className="paper-content">
//             <TableContainer component={Paper}>
//               <div className="paper-header">
//                 <h3 className="mytitle">
//                   Publications Archivées
//                   {PostsIsLoading ||
//                   UsersIsLoading ||
//                   PrestatairesIsLoading ||
//                   CategoriesIsLoading ? (
//                     <span className="loading">
//                       <img src="/images/admin-loading.gif" alt="Loading" />
//                     </span>
//                   ) : null}
//                 </h3>
//                 {/* <div className="button-container">
//                   <button onClick={() => handleOpenAddPost()}>
//                     <AddCircleIcon />
//                   </button>
//                 </div> */}
//               </div>
//               <Table className={classes.table} aria-label="simple table">
//                 {tableHeader}
//                 {tableBody()}
//               </Table>
//             </TableContainer>
//           </div>
//           <AddPostModal
//             onClose={(e) => handleCloseAddPost(e)}
//             showAddPost={showAddPost}
//             post={selectedPost}
//             prestataires={prestataires}
//             users={users}
//             categories={categories}
//           />
//         </Paper>
//       </Grid>
//     </Grid>
//   )

//   function tableBody() {
//     return (
//       <TableBody>
//         {posts
//           ?.filter((post) => post.status === "Archived")
//           .map((post, index) => (
//             <TableRow
//               key={index}
//               className={
//                 post.status !== "Public" ? "client-row freezed" : "client-row"
//               }
//             >
//               <TableCell>
//                 {post.top ? (
//                   <StarIcon
//                     className="top-btn"
//                     onClick={() => noTopPost(post._id)}
//                   />
//                 ) : (
//                   <StarBorderIcon
//                     className="top-btn"
//                     onClick={() => topPost(post._id)}
//                   />
//                 )}
//               </TableCell>
//               <TableCell>
//                 <Avatar
//                   src={
//                     post.photos
//                       ? post.photos[0]
//                       : "https://gelbasla.com/uploads/app/default/picture.jpg"
//                   }
//                   alt="avatar"
//                 />
//               </TableCell>
//               <TableCell>{post?.title}</TableCell>
//               <TableCell>
//                 {
//                   prestataires?.find((p) => p._id === post?.prestataireId)
//                     ?.location
//                 }
//               </TableCell>
//               <TableCell>
//                 {moment(post?.date).format("dddd DD/MM/yyyy")}
//               </TableCell>
//               {/* <TableCell><span className='likes-cell'><FavoriteIcon />{post?.likes.lenght}</span></TableCell> */}
//               <TableCell>
//                 <span
//                   className={
//                     post?.status === "Waiting"
//                       ? "status waiting"
//                       : post?.status === "Public"
//                       ? "status public"
//                       : "status archived"
//                   }
//                 >
//                   {post?.status}
//                 </span>
//               </TableCell>
//               <TableCell className="table-actions">
//                 {post?.status === "Waiting" ? (
//                   <PublicIcon
//                     title="Publish"
//                     className="public-icon"
//                     onClick={() => publicPost(post?._id)}
//                   />
//                 ) : post?.status === "Public" ? (
//                   <BlockIcon
//                     title="Unpublish"
//                     className="unpublic-icon"
//                     onClick={() => showUnPublicConfirmation(post)}
//                   />
//                 ) : null}
//                 {/* <EditIcon
//                 className="edit-icon"
//                 onClick={() => selectEditPost(post)}
//               /> */}
//                 <DeleteForeverIcon
//                   className="delete-icon"
//                   onClick={() => {
//                     setSelectedPost(post)
//                     setShowDeleteConfirmationDialog(true)
//                   }}
//                 />
//               </TableCell>
//             </TableRow>
//           ))}
//         <ShowDocumentsDialog
//           setOpen={setShowDeleteConfirmationDialog}
//           open={showDeleteConfirmationDialog}
//           onConfirm={(e) =>
//             dispatch(deletePost(selectedPost._id)).then(() => {
//               setShowDeleteConfirmationDialog(false)
//             })
//           }
//         />
//       </TableBody>
//     )
//   }
// }
