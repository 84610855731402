import { Stack } from "@mui/material"
import Paper from "@mui/material/Paper"
import { useDispatch, useSelector } from "react-redux"
import { editUser } from "../../../redux/actions/users.js"
import DataGridComponent from "../DataGridComponent/DataGridComponent.js"
import UsersPageColumns from "./UsersPageColumns.js"

export default function UsersPage() {
  const dispatch = useDispatch()
  var { users } = useSelector((state) => state.users)

  const usersColumns = UsersPageColumns()

  return (
    <div className="userPage">
      <Paper sx={{ p: 3 }} elevation={4}>
        <div className="paper-content">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <h3 className="mytitle">Liste des Utilisateurs</h3>
            {/* <AddEditUserDialog /> */}
          </Stack>

          <DataGridComponent
            rows={users.filter((user) => user?.role !== "prestataire")}
            columns={usersColumns}
            getRowClassName={(params) =>
              `${params.row.role}-row client-row ${
                params.row?.block === true ? " freezed" : ""
              }`
            }
            onRowEdit={(newRow) => dispatch(editUser(newRow._id, newRow))}
            // enableClickAway={true}
          />
        </div>
      </Paper>
    </div>
  )
}
