import {
  START_LOADING,
  END_LOADING,
  FETCH_ALL,
  FETCH_POST,
  ADD_POST,
  UPDATE_POST,
  DELETE_POST,
  LIKE,
  COMMENT,
  PUBLIC_WAITING,
  LIST_POSTS,
  TOP_POST,
  POSTS_ERROR,
} from "../actionTypes"
import * as api from "../../api/index.js"

export const listPosts = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING })
    const {
      data: { data },
    } = await api.listPosts()
    dispatch({ type: LIST_POSTS, payload: { data } })
    dispatch({ type: END_LOADING })
  } catch (error) {
    console.log(error)
  }
}

export const getPosts = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING })
    const { data } = await api.fetchPosts()
    dispatch({ type: FETCH_ALL, payload: { data } })
    dispatch({ type: END_LOADING })
  } catch (error) {
    console.log(error)
  }
}
export const getPublicPosts = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING })
    const { data } = await api.getPublicPosts()
    dispatch({ type: FETCH_ALL, payload: { data } })
    dispatch({ type: END_LOADING })
  } catch (error) {
    console.log(error)
  }
}
export const getWaitingPosts = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING })
    const { data } = await api.getWaitingPosts()
    dispatch({ type: FETCH_ALL, payload: { data } })
    dispatch({ type: END_LOADING })
  } catch (error) {
    console.log(error)
  }
}
export const getRejectedPosts = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING })
    const { data } = await api.getRejectedPosts()
    dispatch({ type: FETCH_ALL, payload: { data } })
    dispatch({ type: END_LOADING })
  } catch (error) {
    console.log(error)
  }
}
export const getArchivedPosts = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING })
    const { data } = await api.getArchivedPosts()
    dispatch({ type: FETCH_ALL, payload: { data } })
    dispatch({ type: END_LOADING })
  } catch (error) {
    console.log(error)
  }
}

export const getPost = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING })
    const { data } = await api.fetchPost(id)
    dispatch({ type: FETCH_POST, payload: { post: data } })
    dispatch({ type: END_LOADING })
  } catch (error) {
    console.log(error)
  }
}

export const setPost = (selectedPost) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING })
    dispatch({ type: FETCH_POST, payload: { post: selectedPost } })
    dispatch({ type: END_LOADING })
  } catch (error) {
    console.log(error)
  }
}

export const createPost = (post) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING })

    const { data } = await api.createPost(post)
    // if (post?.photos.length) api.uploadImages(data, post?.photos)
    dispatch({ type: ADD_POST, payload: data })
    dispatch({ type: END_LOADING })
  } catch (error) {
    dispatch({ type: END_LOADING })

    return Promise.reject(error.response.data.message)
  }
}

export const updatePost = (id, post) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING })
    const { data } = await api.updatePost(id, post)
    console.log(data)
    dispatch({ type: UPDATE_POST, payload: data })
    dispatch({ type: END_LOADING })
  } catch (error) {
    dispatch({ type: END_LOADING })

    return Promise.reject(error.response.data.message)
  }
}

export const likePost = (id, userId) => async (dispatch) => {
  try {
    const { data } = await api.likePost(id, userId)
    dispatch({ type: LIKE, payload: data })
  } catch (error) {
    console.log(error)
  }
}

export const commentPost = (value, id) => async (dispatch) => {
  try {
    const { data } = await api.comment(value, id)
    dispatch({ type: COMMENT, payload: data })
    return data.comments
  } catch (error) {
    console.log(error)
  }
}

export const deletePost = (id) => async (dispatch) => {
  try {
    await await api.deletePost(id)
    dispatch({ type: DELETE_POST, payload: id })
  } catch (error) {
    console.log(error)
  }
}

export const publicWaiting = (formData) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING })
    const { data } = await api.publicWaiting(formData)
    dispatch({ type: PUBLIC_WAITING, payload: data })
    dispatch({ type: END_LOADING })
  } catch (error) {
    console.log(error)
  }
}

export const topSwitch = (formData) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING })
    const { data } = await api.topSwitch(formData)
    dispatch({ type: TOP_POST, payload: data })
    dispatch({ type: END_LOADING })
  } catch (error) {
    console.log(error)
  }
}

export const getPostsFromParentCategory = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING })
    const {
      data,
    } = await api.getPostsFromParentCategory(id, formData)

    dispatch({ type: END_LOADING })
    return data
  } catch (error) {
    console.log(error)
  }
}
