import { findMyReservations, findReservations } from "redux/actions/reservation"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DataGridComponent from "../../DataGridComponent/DataGridComponent"
import { SET_SELECTED_ROW } from "../../../../redux/actionTypes"
import ReservationsColumnsPrestataire from "./ReservationsRequestColumns"
import { Paper } from "@mui/material"

function ReservationsRequestPage() {
  const dispatch = useDispatch()
  const { reservations, loadingReservation } = useSelector(
    (state) => state.reservation
  )
  const { authData } = useSelector((state) => state.auth)
  const [columns, setColumns] = useState([])

  useEffect(() => {
if (!authData) return

    dispatch(findMyReservations(authData.prestataireBody._id))
    setColumns(ReservationsColumnsPrestataire())

    return () => dispatch({ type: SET_SELECTED_ROW, payload: null })
  }, [authData])

  return (
    <Paper sx={{ p: 5, mb: 3 }} elevation={5}>
      <h3 style={{ marginBottom: "1rem" }}>
        Liste des demandes de Réservations
      </h3>
      <DataGridComponent
        columns={columns}
        rows={reservations.filter(reservation => reservation.status === "PENDING")}
        allowSelectedRow={false}
      />
    </Paper>
  )
}

export default ReservationsRequestPage
