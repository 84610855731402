import Fade from "@mui/material/Fade"
import Skeleton from "@mui/material/Skeleton"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { BannerCarousel } from "../../../GlobalComponent/BannerCarousel"
import FillerSkeleton from "../../../GlobalComponent/FillerSkeleton"
import FlowerTitle from "../../../GlobalComponent/FlowerTitle"
import Categories from "../../Categories/Categories"
import PostsComponent from "../../Posts/PostsComponent"
export const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const HomePage = () => {
  const { categories, CategoriesIsLoading } = useSelector(
    (state) => state.category
  )
  const { posts, PostsIsLoading } = useSelector((state) => state.posts)

  const publicPosts = useMemo(() => posts.filter(post => post.status === "PUBLIC", [publicPosts]))

  return (
    <Fade in>
      <div>
        {/* Banner */}
        <BannerCarousel
          title=<span>
            Les meilleurs plans pour <br /> sortir tout de suite !
          </span>
          imagesPath={[
            "/images/homePageBanner1.jpg",
            "/images/homePageBanner4.jpg",
            "/images/homePageBanner5.jpg",
            "/images/homePageBanner6.jpg",
          ]}
        />

        <div className="homePage">
          <h1 style={{ fontSize: "3rem" }}>Planifions une sortie parfaite !</h1>

          <hr />

          {/* Categories Carousel */}
          {categoriesRender()}
          <p className="HomePageDescription">
            Tunisian Event est votre guide des sorties en Tunisie vous informe de toute l'actualité et les bons plans pour vos sorties et loisirs.

          </p>

          <hr />
          {renderTopPosts()}

          {/* Posts under their respectives Categories */}
          {processAndRenderCategories()}
        </div>
      </div>
    </Fade>
  )

  function processAndRenderCategories() {
    return (
      categories.length > 0 &&
      categories.map((parentCategory, key) => {
        if (parentCategory.parent === "0") {
          var subCategoriesIds = []

          categories
            .filter((category) => category.parent !== "0")
            .map((subCategory, key) => {
              if (subCategory.parent === parentCategory._id) {
                subCategoriesIds.push(subCategory._id)
              }
            })

          let postsToRender = publicPosts?.filter((post) => {
            return parentCategory?._id
              ? post.categoriesIds?.find(
                (category) =>
                  category === parentCategory?._id ||
                  subCategoriesIds.includes(category._id)
              )
              : true
          })

          if (postsToRender?.length)
            return (
              <div key={key}>
                <FlowerTitle
                  id={parentCategory._id}
                  title={parentCategory.title}
                />

                {postsRender(postsToRender, parentCategory)}
              </div>
            )
        }
      })
    )
  }

  function categoriesRender() {
    /* Get parent categories then get their subCategories */

    return (
      <>
        {CategoriesIsLoading ? (
          renderCategoriesFiller()
        ) : (
          <Categories categories={categories} />
        )}
      </>
    )
  }

  function postsRender(postsToRender, parentCategory) {
    return (
      <>
        {PostsIsLoading ? < FillerSkeleton /> : (
          <PostsComponent
            renderMode={"Carousel"}
            posts={postsToRender}
            parentCategory={parentCategory}
          />
        )}
      </>
    )
  }

  function renderTopPosts() {
    return (
      <>
        <FlowerTitle title="Les tops du jour" />
        {PostsIsLoading ? (
          <FillerSkeleton />
        ) : (
          <PostsComponent
            renderMode={"Carousel"}
            posts={publicPosts?.filter((post) => post.top === true)}
          />
        )}
      </>
    )
  }

  function renderCategoriesFiller() {
    return (
      <div className="flex-between">
        {[...Array(3)].map((e, key) => (
          <div key={key} className="categories-sketlon">
            <Skeleton
              animation="wave"
              variant="circular"
              width="220px"
              height="220px"
            />
          </div>
        ))}
      </div>
    )
  }

}

export default HomePage
