import { Button, DialogActions, Divider, Grid } from "@mui/material"
import Paper from "@mui/material/Paper"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { resizePhotos } from "../../../functions/imageFunctions.js"
import { submitAction } from "../../../redux/actions/feedback.js"
import { updatePrestataire } from "../../../redux/actions/prestataires.js"
import { editUser } from "../../../redux/actions/users.js"
import { AddEditPrestataireForm } from "./AddEditPrestataireForm.js"
import { AddEditUserForm } from "./AddEditUserForm"
import { LoadingButton } from "@mui/lab"
import SaveIcon from "@mui/icons-material/Save"



const initForm = {
  name: "",
  email: "",
  address: "",
  phone: "",
  password: "",
  city: "",
  rating: 0,
}

function AddEditUserPrestataireForm({ handleClose }) {
  const { selectedRow } = useSelector((state) => state.dataGrid)
  const { isLoading} = useSelector((state) => state.misc)

  const dispatch = useDispatch()
  const [formData, setFormData] = useState(initForm)

  const handleAddAvatar = async (e) => {
    const avatar = e.target.files[0]
    const compressedAvatar = await resizePhotos(avatar, 10)
    setFormData({ ...formData, avatar: compressedAvatar })
  }

  useEffect(() => {
    if (selectedRow)
      setFormData({
        ...selectedRow?.userId,
        ...selectedRow,
        userId: selectedRow?.userId?._id, accepted : undefined,
      })
  }, [selectedRow])

  const onSubmit = async () => {
    if (formData.email === selectedRow.email) formData.email = undefined
    if (formData.password === "") formData.password = undefined

    await dispatch(
      submitAction({
        actionFn: formData?.userId
          ? updatePrestataire(formData._id, formData)
          : editUser(formData._id, formData),
        feedback: formData?.userId
          ? "Prestataire mis à jour"
          : "Utilisateur mis à jour",
      })
    )

    await handleClose()
  }

  return (
    <form>
      <Paper sx={{ p: 3 }} elevation={4}>
        {/* <h3 style={{ marginBottom: "1rem" }}>Ajouter un utilisateur</h3> */}

        <Grid container>
          {AddEditUserForm({ setFormData, formData })}
          {selectedRow?.userId &&
            AddEditPrestataireForm({ setFormData, formData })}

          <DialogActions sx={{ marginLeft: "auto" }}>
          <LoadingButton
            loading={isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            onClick={onSubmit}
          >
           Modifier
          </LoadingButton>
          </DialogActions>
        </Grid>
      </Paper>
      <input
        id="addNewUserAvatarInput"
        type="file"
        hidden
        onChange={handleAddAvatar}
      />
    </form>
  )

  // function selectRole() {
  //   return (
  //     <FormControl variant="standard">
  //       <InputLabel>Rôle</InputLabel>
  //       <Select

  //         {...register("role")} >
  //         {["admin", "client"].map((role, key) => (
  //           <MenuItem key={key} value={role}>
  //             {capitalizeFirstLetter(role)}
  //           </MenuItem>
  //         ))}
  //       </Select>
  //     </FormControl>
  //   )
  // }
}

export default AddEditUserPrestataireForm
