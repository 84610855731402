import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import Fade from "@mui/material/Fade"
import PostsComponent from "../../../Posts/PostsComponent"
import FlowerTitle from "../../../../GlobalComponent/FlowerTitle"
import FillerSkeleton  from "../../../../GlobalComponent/FillerSkeleton"

const LikedPage = () => {
  const { posts, PostsIsLoading } = useSelector((state) => state.posts)

  {
    /* //! ------------- Return ----------------- */
  }
  return (
    <Fade in timeout={3000}>
      <div className="favoritePage">

        <FlowerTitle title="Mes Favoris" />

        {PostsIsLoading ? (
          <FillerSkeleton />
        ) : (
          <PostsComponent
            renderMode="Grid"
            posts={posts}
            showOnly="userLiked"
          />
        )}

        <div className="spacer"></div>

        {/* You can also like */}
        <h2 className="mytitle">Vous pourriez aussi aimer</h2>

        {PostsIsLoading ? (
          <FillerSkeleton />
        ) : (
          <PostsComponent
            posts={posts}
            renderMode="Carousel"
            showOnly="userUnliked"
          />
        )}
      </div>
    </Fade>
  )
}

export default LikedPage
