import { END_LOADING, LOADING, SET_FEEDBACK_OBJECT } from "../actionTypes"

export const feedbackSuccess = (feedback) => async (dispatch) =>
  dispatch({
    type: SET_FEEDBACK_OBJECT,
    payload: {
      feedback: feedback,
      severity: "success",
    },
  })

export const feedbackError = (feedback) => async (dispatch) =>
  dispatch({
    type: SET_FEEDBACK_OBJECT,
    payload: {
      feedback: feedback,
      severity: "error",
    },
  })

export const submitAction =
  ({ actionFn, feedback, handleClose }) =>
  async (dispatch) => {
    dispatch({
      type: LOADING,
    })

    try {
      await dispatch(actionFn)
      if (feedback) await  dispatch(feedbackSuccess(feedback))
      else await dispatch(feedbackSuccess("Opération effectuée avec succès."))

      if (handleClose)
        setTimeout(() => {
          handleClose()
        }, 1500)

      dispatch({
        type: END_LOADING,
      })
    } catch (error) {
      dispatch({
        type: END_LOADING,
      })
      dispatch(feedbackError(error?.response?.data))
      return error?.response?.data
    }
  }
