import { Avatar, IconButton } from "@mui/material"
import { useDispatch } from "react-redux"
import React from "react"
import moment from "moment"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"

import { deletePost } from "../../../../redux/actions/posts"
import ViewFullscreen from "../../../../GlobalComponent/ViewFullscreen.js"
import ConfirmDialog from "../../../../GlobalComponent/ConfirmDialog.js"
import { SHOW_NEW_POST_WINDOW } from "../../../../redux/actionTypes.js"
import EditFormPostDashboardPrestataire from "./EditFormPostDashboardPrestataire"
import { DeletePostDataGridAction } from "../../../../GlobalComponent/dataGridActions"

function MyPostsPageColumns() {
  const dispatch = useDispatch()
  const handleDelete = async ({ row }) => {
    dispatch(deletePost(row._id))
  }

  let columns = [
    {
      field: "avatar",
      renderCell: (cellValues) => (
        <Avatar
          src={
            cellValues.row?.photos
              ? cellValues.row?.photos[0]
              : "https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
          }
        />
      ),
    },
    {
      field: "title",
      headerName: "Titre",
      editable: true,
    },
    {
      field: "date",

      valueFormatter: (params) =>
      params.value?  moment(params.value).format("DD/MM/YYYY HH:mm") : "",
    },
    {
      field: "status",
      headerName: "Statut",
      valueFormatter: (params) => {
        switch (params.value.toUpperCase()) {
          case "WAITING":
            return "En attente"
          case "REJECTED":
            return "Rejetée"
          case "ARCHIVED":
            return "Archivée"
          case "PUBLIC":
            return "Publique"
        }
      },
    },
    {
      field: "action",
      renderCell: (cellValues) => {
        return (
         <>
           <ViewFullscreen title="Modifier votre publication" Form={<EditFormPostDashboardPrestataire />  } >
             <EditIcon sx={{ color: "#168700" }} />
            </ViewFullscreen>
            <DeletePostDataGridAction id={cellValues.row._id} />
         </>

        )
      },
    },
  ]

  // Center columns, filed and headerName
  columns = columns.map(
    (column, index) =>
      (column = {
        ...{
          headerName: capitalizeFirstLetter(column?.field),
          flex: 1,
          headerAlign: "center",
          align: "center",
        },
        ...column,
      })
  )

  return columns
}

export default MyPostsPageColumns

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
