import { IconButton, Paper, Stack } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DataGridComponent from "../../DataGridComponent/DataGridComponent"
import MyPostsPageColumns from "./MyPostsPageColumns"
import {
  SET_SELECTED_ROW,
  SHOW_NEW_POST_WINDOW,
} from "../../../../redux/actionTypes"
import AddIcon from "@mui/icons-material/Add"

function MyPostsPage() {
  const dispatch = useDispatch()
const {authData} = useSelector((state) => state.auth)
  var { posts, PostsIsLoading } = useSelector((state) => state.posts)

  useEffect(() => {
    // posts = posts.filter(
    //   (post) => post?.prestataireId?._id === currentUser?.prestataireId
    // )
    // // if (posts.length === 0) {
    // //   setNoRows("Vous avez aucune publication.")
    // // }

    return () => dispatch({ type: SET_SELECTED_ROW, payload: null })
  }, [])

  var columns = MyPostsPageColumns()
  return (
    <Paper sx={{ p: 5, mb: 3 }} elevation={5}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <h3 style={{ marginBottom: "1rem" }}>Mes publications</h3>
        <IconButton size="large">
          <AddIcon
            onClick={() =>
              dispatch({ type: SHOW_NEW_POST_WINDOW, payload: true })
            }
          />
        </IconButton>
      </Stack>
      <DataGridComponent
        rows={posts.filter(
          (post) => post?.prestataireId?._id === authData?.prestataireBody?._id
        )}
        columns={columns}
        loading={PostsIsLoading}

        // noRows={!PostsIsLoading && posts.length && "Vous avez aucune publication."}
      />
    </Paper>
  );
}

export default MyPostsPage
