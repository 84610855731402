import { useEffect, useRef, useState } from "react"

// material-ui
import {
  Badge,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"

// third-party
import PerfectScrollbar from "react-perfect-scrollbar"

// project imports
import MainCard from "./MainCard"
import NotificationList from "./NotificationList"
import Transitions from "./Transitions"

// assets
import { makeStyles } from "@mui/styles"

import NotificationsIcon from "@mui/icons-material/Notifications"
import withStyles from "@mui/styles/withStyles"
import { useSelector } from "react-redux"
import { clearUnreadNotifications, findUserNotifications } from "./../../api"
import { socket } from "./../../App"

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid",
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    width: "400px",
    zIndex: "1000",
    "& > a, & > h2": {
      padding: "0.5rem",
    },
  },
}))

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 15,
    top: 15,
    padding: "0 4px",
  },
}))(Badge)
const StyledBadgeForNotificationElement = withStyles((theme) => ({
  badge: {
    right: 20,
    top: 5,
    padding: "0 4px",
    backgroundColor: "#004DB0",
  },
}))(Badge)

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
  const { authData } = useSelector((state) => state.auth)
  /* #region  component related stuff */
  const classes = useStyles()
  const theme = useTheme()
  const matchesXs = useMediaQuery(theme.breakpoints.down("lg"))

  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const [newNotification, setNewNotification] = useState(false)
  const [badge, setBadge] = useState()
  const [dbNotification, setDbNotification] = useState([])

  const reset = async () => {
    // if (newNotification === true) {
      await clearUnreadNotifications(authData._id )
      setNewNotification(false)
      setBadge(0)
    // }
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
    if (open) reset()
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    reset()
    setOpen(false)
  }

  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }
    prevOpen.current = open
  }, [open])

  /* #endregion */

  // Fetch notification
  useEffect(() => {
    if (!authData) return
    ;(async () => {
      // await deleteAllNotifications(currentUser._id)
      let { data } = await findUserNotifications(authData._id)

      let unread = 0

      data.map((notification) => {
        notification.unread && unread++
      })
      setBadge(unread)

      setDbNotification(data)

      socket?.on("new-notification", (newSocketNotification) => {
        console.log(newSocketNotification)
        setDbNotification((prev) => [newSocketNotification, ...prev])
        setBadge((prev) => prev + 1)
        setNewNotification(true)
      })
    })()
  }, [authData])

  return (
    <>
      <StyledBadge badgeContent={badge} color="error" onClick={handleToggle}>
        <Tooltip ref={anchorRef} title="Notifications">
          <IconButton size="large">
            <NotificationsIcon sx={{ fontSize: "2rem", color: "#f76c6f" }} />
          </IconButton>
        </Tooltip>
      </StyledBadge>
      <Popper
        placement={matchesXs ? "bottom" : "bottom-end"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? 5 : 0, 20],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            position={matchesXs ? "top" : "top-right"}
            in={open}
            {...TransitionProps}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ pt: 2, px: 2 }}
                      >
                        <Grid item>
                          <Stack direction="row" spacing={2}>
                            <Typography variant="subtitle1">
                              Toutes les notifications
                            </Typography>
                            <Chip
                              size="small"
                              label={badge}
                              sx={{
                                color: theme.palette.background.default,
                                bgcolor: theme.palette.warning.dark,
                              }}
                            />
                          </Stack>
                        </Grid>
                        {/* <Grid item>
                        <Typography
                          component={Link}
                          to="#"
                          variant="subtitle2"
                          color="primary"
                          onClick={markAllAsRead}
                        >
                          Marquer tous comme lus
                        </Typography>
                      </Grid> */}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <PerfectScrollbar
                        style={{
                          height: "100%",
                          maxHeight: "calc(100vh - 205px)",
                          overflowX: "hidden",
                        }}
                      >
                        <Grid container direction="column" spacing={2}>
                          {/* <Grid item xs={12}>
                          <Box sx={{ px: 2, pt: 0.25 }}>
                            <TextField
                              id="outlined-select-currency-native"
                              select
                              fullWidth
                              value={value}
                              onChange={handleChange}
                              SelectProps={{
                                native: true,
                              }}
                            >
                              {status.map((option) => (
                                <option
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </TextField>
                          </Box>
                        </Grid> */}
                          <Grid item xs={12} p={0}>
                            <Divider sx={{ my: 0 }} />
                          </Grid>
                        </Grid>
                        <NotificationList dbNotification={dbNotification} />
                      </PerfectScrollbar>
                    </Grid>
                  </Grid>
                  <Divider />
                  {/* <CardActions sx={{ p: 1.25, justifyContent: "center" }}>
                  <Button size="small" disableElevation>
                    View All
                  </Button>
                </CardActions> */}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  )
}

export default NotificationSection
