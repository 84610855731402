import DeleteIcon from "@mui/icons-material/Delete"
import FlakyIcon from "@mui/icons-material/Flaky"
import { Avatar } from "@mui/material"
import moment from "moment"
import ConfirmDialog from "../../../GlobalComponent/ConfirmDialog.js"
import ViewFullscreen from "../../../GlobalComponent/ViewFullscreen.js"
import { deletePost } from "../../../redux/actions/posts.js"
import EditFormPostDashboardAdmin from "../../../GlobalComponent/dashboard/EditFormPostDashboardAdmin.js"
import { DeletePostDataGridAction } from "../../../GlobalComponent/dataGridActions/index.js"

function WaitingPostColumns() {
  

  let columns = [
    {
      field: "photos",
      headerName: "Photo",
      renderCell: (params) => (
        <Avatar
          src={
            params.row?.photos
              ? params.row?.photos[0]
              : "https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
          }
        />
      ),
    },
    {
      field: "title",
      headerName: "Titre",
      editable: true,
    },
    {
      field: "prestataireId",
      headerName: "Nom prestataire",
      valueFormatter: (params) => params.value?.userId?.name,
    },
    {
      field: "date",
      headerName: "Date évenement",
      valueFormatter: (params) =>
        moment(params.value).format("DD/MM/YYYY HH:mm"),
      editable: false,
    },
    {
      field: "status",
      headerName: "Statut",
      valueFormatter: (params) => "En attente",

    },
    {
      field: "action",
      renderCell: (cellValues) => {
        return (
          <>
            <ViewFullscreen title="" Form={<EditFormPostDashboardAdmin readOnly={true} />}>
              <FlakyIcon sx={{ color: "#168700" }} />
            </ViewFullscreen>
            <DeletePostDataGridAction id={cellValues.row._id} />
          </>
        )
      },
    },
  ]

  // Center columns, filed and headerName
  columns = columns.map(
    (column, index) =>
    (column = {
      ...{
        headerName: capitalizeFirstLetter(column.field),
        flex: 1,
        headerAlign: "center",
        align: "center",
      },
      ...column,
    })
  )

  return columns
}

export default WaitingPostColumns

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
