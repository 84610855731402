import SaveIcon from "@mui/icons-material/Save"
import { LoadingButton } from "@mui/lab"
import { Grid, Stack, TextField } from "@mui/material"
import moment from "moment"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  feedbackError,
  feedbackSuccess,
} from "../../../../redux/actions/feedback.js"
import { updatePost } from "../../../../redux/actions/posts.js"
import { AddImages } from "../../../AddPost/AddImages.js"

const initForm = {
  categoriesIds: [],
  prestataireId: "",
  title: "",
  date: "",
  description: "",
  price: "",
  photos: [],
  rating: 0,
}

export default function EditFormPostDashboardPrestataire({ handleClose }) {
  const dispatch = useDispatch()

  const { selectedRow } = useSelector((state) => state.dataGrid)
  const { PostsIsLoading } = useSelector((state) => state.posts)

  const [formData, setFormData] = useState(initForm)
  const handleChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    if (selectedRow) {
      let date = moment(selectedRow.date).format("YYYY-MM-DD")
      setFormData({
        ...selectedRow,
        date: date,
      })
    }
  }, [selectedRow])

  const onSubmit = async () => {
    try {
      await dispatch(
        updatePost(formData._id, { ...formData, status: undefined })
      )
      dispatch(feedbackSuccess("Publication Modifiée."))
      setTimeout(() => {
        handleClose()
      }, 1500)
    } catch (error) {
      dispatch(feedbackError(error?.response?.data))
    }
  }

  return (
    <Grid sx={{ p: 4 }} container spacing={5}>
      <Grid item xs={12}>
        <h3>Informations sur la publication</h3>
      </Grid>
      <Grid item xs={6}>
        <Stack spacing={3}>
          {input({ label: "Titre", name: "title" })}
          {input({ name: "date", type: "datetime-local" })}
          {input({
            label: "Prix",
            name: "price",
            props: {
              inputProps: {
                inputMode: "numeric",
                pattern: "[0-9]*",
                min: 0,
                max: 1000000,
              },
            },
            type: "number",
          })}
          {input({
            name: "description",
            props: { multiline: true, rows: 4 },
          })}
        </Stack>
      </Grid>

      <Grid item xs={6}>
        <AddImages
          height="100%"
          formData={formData}
          setFormData={setFormData}
        />
      </Grid>
      <Grid item xs={12}>
        <LoadingButton
          loading={PostsIsLoading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          onClick={onSubmit}
        >
          Modifier
        </LoadingButton>
      </Grid>
    </Grid>
  )

  function input({ name, label, type = "text", props }) {
    return (
      <TextField
        type={type}
        fullWidth
        label={label ? label : capitalizeFirstLetter(name)}
        variant="outlined"
        name={name}
        value={formData[name]}
        onChange={handleChange}
        {...props}
      />
    )
  }
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
