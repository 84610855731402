import { Button, Paper } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { AUTH } from "../../redux/actionTypes"
import { fastLogin } from "./FastLogin"
import LoginForm from "./LoginForm"
import RegisterForm from "./RegisterForm/RegisterForm"
import useStyles from "./styles"

const AuthPage = () => {
  const {authData} = useSelector((state) => state.auth)
  
  const [imageLoaded, setImageLoaded] = useState(false)
  const dispatch = useDispatch()
  const [isSignup, setIsSignup] = useState(false)
  const classes = useStyles()

  const navigate = useNavigate()

  const switchMode = () => {
    setIsSignup((prevIsSignup) => !prevIsSignup)
  }


  useEffect(() => {
    if (authData) return navigate("/home")
  }, [authData])

  return (
    <div
      className="authPage"
      // style={{ backgroundImage: `url("images/bgAuthPage.jpg")` }}
    >
      <img
        src="images/bgAuthPage.jpg"
        className="bgImage"
        alt="Image de fond"
        onLoad={() => setImageLoaded(true)}
      />
      {imageLoaded && (
        <Paper
          className={`${classes.paper} auth-page-main-container`}
          elevation={6}
        >
          <img className={classes.avatar} src="/images/logo.png" alt="Logo" />
          <h1 style={{ marginBottom: "1rem" }}>
            {isSignup ? "S'enregistrer" : "Se connecter"}
          </h1>

          {isSignup ? <RegisterForm /> : <LoginForm />}

          <Button
            variant="outlined"
            sx={{ mt: 2, width: "100%" }}
            onClick={switchMode}
          >
            {isSignup
              ? "Vous avez déjà un compte ? connectez-vous !"
              : "Vous n'avez pas de compte ? inscrivez-vous !"}
          </Button>
        </Paper>
      )}
      {/* // TODO TESTING */}
      {/* {fastLogin(dispatch, navigate)} */}
    </div>
  )
}

export default AuthPage
