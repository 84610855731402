import * as api from "../../api/index.js"
import {
  CREATE_USER,
  DELETE_USER,
  END_LOADING_USER,
  BLOCK_USER,
  LIST_USERS,
  START_LOADING_USER,
  UPDATE_USER,
  GET_USER,
  UPDATE_USER_PASSWORD,
} from "../actionTypes.js"

export const listUsers = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_USER })
    const {
      data: { data },
    } = await api.ListUsers()
    dispatch({ type: LIST_USERS, payload: { data } })
    dispatch({ type: END_LOADING_USER })
  } catch (error) {
    console.log(error)
  }
}

export const getUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_USER })
    const { data } = await api.getUser(id)
    dispatch({ type: GET_USER, payload: data })
    dispatch({ type: END_LOADING_USER })
  } catch (error) {
    console.log(error)
  }
}

export const AddUser = (newUser) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_USER })
    const { data } = await api.AddUser(newUser)
    dispatch({ type: CREATE_USER, payload: data })
    dispatch({ type: END_LOADING_USER })
  } catch (error) {
    console.log(error)
  }
}

export const editUser = (id, newUser) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_USER })
    const { data } = await api.EditUser(id, newUser)
    dispatch({ type: UPDATE_USER, payload: data })
    dispatch({ type: END_LOADING_USER })
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteUser = (id) => async (dispatch) => {
  try {
    await api.DeleteUser(id)
    dispatch({ type: DELETE_USER, payload: id })
  } catch (error) {
    console.log(error)
  }
}

export const blockUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_USER })
    const { data } = await api.blockUser(id)
    dispatch({ type: UPDATE_USER, payload: data })
    dispatch({ type: END_LOADING_USER })
  } catch (error) {
    console.log(error)
  }
}

export const EditUserPassword =
  (id, oldPassword, newPassword) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING_USER })
      const { data } = await api.EditUserPassword(id, oldPassword, newPassword)
      dispatch({ type: UPDATE_USER_PASSWORD, payload: data })
      dispatch({ type: END_LOADING_USER })
    } catch (error) {
      console.log(error)
    }
  }
