import { IconButton, Tooltip } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  createCategory,
  updateCategory,
} from "../../../redux/actions/categories.js"
import {
  feedbackError,
  feedbackSuccess,
} from "../../../redux/actions/feedback.js"
import AddEditCategoryForm from "./AddEditCategoryForm"
import SaveIcon from "@mui/icons-material/Save"
import { LoadingButton } from "@mui/lab"

const initForm = {
  title: "",
  image: "",
  parent: "0",
  order: 0,
}

export default function AddEditCategoryDialog({ children }) {
  const { IsLoadingCategory } = useSelector((state) => state.category)

  const dispatch = useDispatch()
  const { selectedRow } = useSelector((state) => state.dataGrid)

  const [formData, setFormData] = React.useState(initForm)

  React.useEffect(() => {
    if (selectedRow) setFormData(selectedRow)
  }, [selectedRow])

  /* #region  Open Close */
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  /* #endregion */

  const onSubmit = async () => {
    try {
      if (selectedRow) {
        await dispatch(updateCategory(formData._id, formData))
        dispatch(feedbackSuccess("Catégorie Modifiée."))
      } else {
        await dispatch(createCategory(formData))
        dispatch(feedbackSuccess("Catégorie Ajoutée."))
      }
      setTimeout(() => {
        handleClose()
        setFormData(initForm)
      }, 1500)
    } catch (error) {
      dispatch(feedbackError(error?.response?.data))
    }
  }

  return (
    <div>
      <Tooltip title="Ajouter une catégorie">
        <IconButton variant="contained" onClick={handleClickOpen} size="large">
          {children}
        </IconButton>
      </Tooltip>

      <Dialog open={open} onClose={handleClose} maxWidth="xl">
        <DialogTitle>
          {selectedRow ? "Modifier" : "Ajouter"} une catégorie
        </DialogTitle>
        <DialogContent>
          <AddEditCategoryForm formData={formData} setFormData={setFormData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
          <LoadingButton
            loading={IsLoadingCategory}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            onClick={onSubmit}
          >
            {selectedRow ? "Modifier" : "Ajouter"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}
