import * as API from "../../api/index.js"
import * as actionType from "../actionTypes.js"

export const findCategory = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionType.LOADING_CATEGORY })
    const { data } = await API.findCategory(id)
    dispatch({ type: actionType.SET_CATEGORY, payload: data })
    dispatch({ type: actionType.END_LOADING_CATEGORY })
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const findCategories = () => async (dispatch) => {
  try {
    dispatch({ type: actionType.LOADING_CATEGORY })
    const { data } = await API.findCategories()
    dispatch({ type: actionType.SET_CATEGORIES, payload: data })
    dispatch({ type: actionType.END_LOADING_CATEGORY })
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const createCategory = (newCategory) => async (dispatch) => {
  try {
    dispatch({ type: actionType.LOADING_CATEGORY })
    const { data } = await API.createCategory(newCategory)
    dispatch({ type: actionType.ADD_CATEGORY, payload: data })
    dispatch({ type: actionType.END_LOADING_CATEGORY })
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateCategory = (id, updatedCategory) => async (dispatch) => {
  try {
    dispatch({ type: actionType.LOADING_CATEGORY })
    const { data } = await API.updateCategory(id, updatedCategory)
    dispatch({ type: actionType.UPDATE_CATEGORY, payload: data })
    dispatch({ type: actionType.END_LOADING_CATEGORY })
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteCategory = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionType.LOADING_CATEGORY })
    await API.deleteCategory(id)
    dispatch({ type: actionType.DELETE_CATEGORY, payload: id })
    dispatch({ type: actionType.END_LOADING_CATEGORY })
  } catch (error) {
    return Promise.reject(error)
  }
}
