import * as React from "react"
import Dialog from "@mui/material/Dialog"

import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import Slide from "@mui/material/Slide"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function ViewFullscreen({ title, children, Form }) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }



  return (
    <div>
      <IconButton onClick={handleClickOpen} size="large">{children}</IconButton>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", bgcolor: "#F76C6F" }}>
          {/* Toolbar */}
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} size="large">
              <CloseIcon />
            </IconButton>
            <h3 style={{ color: "white" }}>{title}</h3>
          </Toolbar>
        </AppBar>
        {React.cloneElement(Form, {handleClose : handleClose})}
      </Dialog>
    </div>
  )
}
