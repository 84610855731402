import * as actionType from "../actionTypes"

const authReducer = (state = { authData: null, authError: null }, action) => {
  switch (action.type) {
    case actionType.AUTH:
      localStorage.setItem("profile", JSON.stringify({ ...action?.payload }))

      return { ...state, authData: action.payload, loading: false, errors: null }
    case actionType.LOGOUT:
      localStorage.clear()
      return { ...state, authData: null, loading: false, errors: null }
    case actionType.AUTH_ERROR:
      return {
        ...state,
        authError: action.error?.response?.data?.message,
      }
    default:
      return state
  }
}

export default authReducer