import { Avatar, Button, Chip, InputLabel, Stack } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import LoadingPage from "./LoadingPage"

function CategorySelectionGrid({ variant, setFormData, formData }) {
  const { categories, IsLoadingCategory } = useSelector(
    (state) => state.category
  )

  const handleAddChip = (category) => {
    setFormData({
      ...formData,
      categoriesIds: [...formData.categoriesIds, category],
    })
  }

  const handleDeleteChip = (category) => {
    setFormData({
      ...formData,
      categoriesIds: formData.categoriesIds.filter((c) => c !== category),
    })
  }

  if (IsLoadingCategory === true) return <LoadingPage />

  return (
    <div className="categorySelectGrid">
      {categories?.map((category, key) => (
        <Stack
          key={key}
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Avatar
            sx={{
              width: "150px",
              height: "150px",
              outline: formData.categoriesIds?.find((c) => c === category._id)
                ? "3px red solid"
                : "",
            }}
            alt={category.title}
            src={category.image || "/images/default.png"}
            variant="rounded"
          />
          <Button
            onClick={() => {
              if (variant === "readOnly") return

              formData.categoriesIds?.find((c) => c === category._id)
                ? handleDeleteChip(category._id)
                : handleAddChip(category._id)
            }}
            color={
              formData.categoriesIds?.find((c) => c === category._id)
                ? "secondary"
                : "primary"
            }
          >
            {category.title}
          </Button>
        </Stack>
      ))}
    </div>
  )
}

export default CategorySelectionGrid
