import * as api from "../../api/index.js"
import {
  ACCEPT_REJECT_PRESTATAIRE,
  COMMENT_PRESTATAIRE,
  CREATE_PRESTATAIRE,
  CREATE_USER,
  DELETE_PRESTATAIRE,
  END_LOADING_PRESTATAIRE,
  FETCH_ALL_PRESTATAIRE,
  FETCH_PRESTATAIRE,
  LIKE_PRESTATAIRE,
  START_LOADING_PRESTATAIRE,
  UPDATE_PRESTATAIRE,
} from "../actionTypes.js"

export const getPrestataires = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_PRESTATAIRE })
    const {
      data: { data },
    } = await api.fetchPrestataires()
    dispatch({ type: FETCH_ALL_PRESTATAIRE, payload: { data } })
    dispatch({ type: END_LOADING_PRESTATAIRE })
  } catch (error) {
    console.log(error)
  }
}

export const getPrestataire = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_PRESTATAIRE })
    const { data } = await api.fetchPrestataire(id)
    dispatch({ type: FETCH_PRESTATAIRE, payload: { prestataire: data } })
    dispatch({ type: END_LOADING_PRESTATAIRE })
  } catch (error) {
    console.log(error)
  }
}

export const createPrestataire = (newPrestataire) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING_PRESTATAIRE })
    const { data } = await api.createPrestataire(newPrestataire)
    dispatch({ type: CREATE_USER, payload: data[0] })
    dispatch({ type: CREATE_PRESTATAIRE, payload: data[1] })
    dispatch({ type: END_LOADING_PRESTATAIRE })
    return data
  } catch (error) {
    console.log(error)
    return Promise.reject(error)
  }
}

export const updatePrestataire =
  (id, updatedPrestataire) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING_PRESTATAIRE })
      const { data } = await api.updatePrestataire(id, updatedPrestataire)
      dispatch({ type: UPDATE_PRESTATAIRE, payload: data })
      dispatch({ type: END_LOADING_PRESTATAIRE })
      return data

    } catch (error) {
      console.log(error)
      return Promise.reject(error)

    }
  }

export const likePost = (id) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem("profile"))
  try {
    const { data } = await api.likePrestataire(id, user?.token)
    dispatch({ type: LIKE_PRESTATAIRE, payload: data })
  } catch (error) {
    console.log(error)
  }
}

export const commentPrestataire = (value, id) => async (dispatch) => {
  try {
    const { data } = await api.commentPrestataire(value, id)
    dispatch({ type: COMMENT_PRESTATAIRE, payload: data })
    return data.comments
  } catch (error) {
    console.log(error)
  }
}

export const deletePrestataire = (id) => async (dispatch) => {
  try {
    await await api.deletePrestataire(id)
    dispatch({ type: DELETE_PRESTATAIRE, payload: id })
  } catch (error) {
    console.log(error)
  }
}

