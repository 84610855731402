import { Stack } from "@mui/material"
import React from "react"
import Input from "../Input"

export function RegisterInputs(
  userType,
  showPassword,
  handleShowPassword,
  handleChange
) {
  if (userType === "prestataire")
    return (
      <>
        <Stack direction="row" spacing={2}>
          {input({ label: "Nom", name: "name" })}
        </Stack>
        {input({ label: "Adresse Email", name: "email" })}
        <Stack direction="row" spacing={2}>
          {input({ label: "Téléphone", name: "phone" })}
        </Stack>
        <Stack direction="row" spacing={2}>
          {input({ label: "Adresse", name: "address" })}
          {input({ label: "Ville", name: "city" })}
        </Stack>
        <Stack direction="row" spacing={2}>
          {input({
            label: "Mot de passe",
            name: "password",
            type: showPassword ? "text" : "password",
            handleShowPassword: handleShowPassword,
          })}
          {input({
            label: "Confirmer mot de passe",
            name: "confirmPassword",
            type: "password",
          })}
        </Stack>
      </>
    )

  // Client
  return (
    <>
      <Stack direction="row" spacing={2}>
        {input({ label: "Nom", name: "name" })}
      </Stack>
      {input({ label: "Adresse Email", name: "email" })}
      {input({ label: "Téléphone", name: "phone" })}
      <Stack direction="row" spacing={2}>
        {input({
          label: "Mot de passe",
          name: "password",
          type: showPassword ? "text" : "password",
          handleShowPassword: handleShowPassword,
        })}
        {input({
          label: "Confirmer mot de passe",
          name: "confirmPassword",
          type: "password",
        })}
      </Stack>
    </>
  )
  function input(props) {
    return <Input handleChange={handleChange} {...props} />
  }
}
