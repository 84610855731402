import AddIcon from "@mui/icons-material/Add"
import { Stack } from "@mui/material"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { findClientReservations } from "redux/actions/reservation"
import PostComponent from "./../../../Posts/Post/PostComponent"
import ReservationRequest from "./ReservationRequest"

function MyReservationsPage() {
  const authData = JSON.parse(localStorage.getItem("profile"))
  const dispatch = useDispatch()
  const { reservations } = useSelector((state) => state.reservation)

  useEffect(() => {
    dispatch(findClientReservations(authData._id))
  }, [])

  return (
    <div className="MyReservationPage">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ mb: 20 }}
      >
        <h3 className="title gradient">Mes Réservation</h3>
        <Link to="/home">
          <AddIcon
            sx={{
              fontSize: "3rem",
              color: "white",
              borderRadius: "50%",
              cursor: "pointer",
            }}
            className="gradient"
          />
        </Link>
      </Stack>

      {reservations
        .sort((a, b) => (a.status < b.status ? 1 : b.status < a.status ? -1 : 0))
        .map((reservation, key) => (
          <Stack
            key={key}
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mb: 10 }}
          >
            <div style={{ textAlign: 'center', width: "30%", height: "600px" }}>
              <PostComponent
                postImageHeight="300px"
                post={reservation.postReference}
              />
            </div>
            <div style={{ width: "30%", height: "600px" }}>
              <ReservationRequest reservation={reservation} />
            </div>
          </Stack>
        ))}
      {reservations.map((reservation, key) => (
        <div style={{ marginBottom: "1rem" }}></div>
      ))}
    </div>
  )
}

export default MyReservationsPage
