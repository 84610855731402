import React from "react"
import ConfirmDialog from "../ConfirmDialog"
import DeleteIcon from "@mui/icons-material/Delete"
import { useDispatch } from "react-redux"
import { deleteUser } from "../../redux/actions/users"

function DeleteUserDataGridAction({id}) {
  const dispatch = useDispatch()

  return (
    <ConfirmDialog
      actionFn={() => dispatch(deleteUser(id))}
      title="Supprimer un utilisateur"
      text="Êtes vous sur de vouloir supprimer cet utilisateur ?"
      feedback="L'utilisateur a été supprimé."
    >
      <DeleteIcon color="error" />
    </ConfirmDialog>
  )
}

export default DeleteUserDataGridAction
