import React, { useCallback, useEffect, useMemo, useState } from "react"
import Paper from "@mui/material/Paper"
import { useDispatch, useSelector } from "react-redux"
import {
  updatePost,
} from "../../../redux/actions/posts.js"

import DataGridComponent from "../DataGridComponent/DataGridComponent.js"
import RejectedPostColumns from "./RejectedPostColumns.js"
import { SET_SELECTED_ROW } from "../../../redux/actionTypes"
// import JudgePostDetail from "./JudgePostDetail.js"

export default function RejectedPostPage() {
  const dispatch = useDispatch()
  const { posts } = useSelector((state) => state.posts)
  const [columns, setColumns] = useState([])

  useEffect(() => {
    setColumns(RejectedPostColumns(dispatch))

    return () => dispatch({ type: SET_SELECTED_ROW, payload: null })
  }, [])

  return (
    <div className="waitingPostPage">
      <Paper sx={{ p: 3 }} elevation={4}>
        <h3 style={{ marginBottom: "1rem" }}>Publications Rejetées</h3>

        <DataGridComponent
          rows={posts.filter((post) => post.status === "REJECTED")}
          columns={columns || []}
          onRowEdit={(newRow) => dispatch(updatePost(newRow._id, newRow))}
        />
      </Paper>

    </div>
  )
}
