import * as actionType from "../actionTypes"
export default (
  state = {
    category: {},
    categories: [],
    IsLoadingCategory: false,
  },
  action
) => {
  const payload = action.payload
  switch (action.type) {
    case actionType.LOADING_CATEGORY:
      return { ...state, IsLoadingCategory: true }

    case actionType.END_LOADING_CATEGORY:
      return { ...state, IsLoadingCategory: false }

    case actionType.SET_CATEGORY:
      return { ...state, category: payload }

    case actionType.SET_CATEGORIES:
      return { ...state, categories: payload }

    case actionType.ADD_CATEGORY:
      return {
        ...state,
        categories: [payload, ...state.categories],
      }

    case actionType.UPDATE_CATEGORY:
      return {
        ...state,
        categories: state.categories.map((category) =>
          category._id === payload._id ? payload : category
        ),
      }

    case actionType.DELETE_CATEGORY:
      return {
        ...state,
        categories: state.categories.filter(
          (category) => category._id !== payload
        ),
      }

    default:
      return state
  }
}

