import React from "react"
import PostComponent from "./Post/PostComponent"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { useSelector } from "react-redux"

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 7,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const PostsComponent = ({
  posts,
  setCurrentId,
  renderMode,
  showOnly,
}) => {

  const { authData} = useSelector((state) => state.auth)
  

  if (renderMode === "Grid")
    return <div className="likedPageGrid">{postsRender()}</div>

  if (renderMode === "Carousel")
    return (
      <div style={{ padding: "2rem" }}>
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={2500}
          // keyBoardControl={true}
          transitionDuration={1500}
          arrows={false}
        >
          {postsRender()}
        </Carousel>
        {/* {parentCategory && (
          <h3 className="viewAll">
            <Link
              to={`/category_details/?parentId=${parentCategory?._id}&title=${parentCategory?.title}`}
              style={{ color: "red", fontSize: 16, marginLeft: "8px" }}
            >
              Voir tous
            </Link>
          </h3>
        )} */}
      </div>
    )

  function postsRender() {
    switch (showOnly) {
      case "userUnliked":
        posts = posts.filter(
          (post) => !post?.likes?.includes(authData?._id)
        )
        break
        case "userLiked":
          posts = posts.filter((post) => post?.likes?.includes(authData?._id))
          break
        }

    return posts.map((post, index) => (
      <PostComponent key={index} post={post} setCurrentId={setCurrentId} />
    ))
  }
}

export default PostsComponent
