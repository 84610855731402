import React from "react"
import { Link } from "react-router-dom"

function FlowerTitle({ title, id= ""}) {
  return (
    <div className="category-title">
      <h1>{title} </h1>

      {id && (
        <h3>
          <Link to={`/category_details/?parentId=${id}`}>
            Voir tous
          </Link>
        </h3>
      )}
      <div className="flexAIC" style={{ width: "100%" }}>
        <img style={{ margin: "auto" }} src="/images/divider_title.webp" />
      </div>
    </div>
  )
}

export default FlowerTitle
