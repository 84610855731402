import * as actionType from "../actionTypes"

export default  (
  state = {
    width: null,
    isLoading: false,
  },
  action
) => {
  const payload = action.payload
  switch (action.type) {
    case actionType.SET_WIDTH:
      return { ...state, width: payload }
    case actionType.LOADING:
      return { ...state, isLoading: true }
    case actionType.END_LOADING:
      return { ...state, isLoading: false }

    default:
      return state
  }
}
// dispatch({type :UNREAD_MESSAGES, payload : { scheme: "fetchedUsersData", ...userFormData } })
