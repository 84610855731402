import React, { useEffect } from "react"
import Button from "@mui/material/Button"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Grow from "@mui/material/Grow"
import Paper from "@mui/material/Paper"
import Popper from "@mui/material/Popper"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import makeStyles from "@mui/styles/makeStyles"
import { Avatar } from "@mui/material"
import useStylesCustom from "./styles"

import PersonIcon from "@mui/icons-material/Person"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import EventSeatIcon from "@mui/icons-material/EventSeat"
import { Link, useNavigate } from "react-router-dom"
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned"
import ListAltIcon from "@mui/icons-material/ListAlt"
import { useDispatch, useSelector } from "react-redux"
import { getUser } from "../../api"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  itemStyle: {
    marginRight: theme.spacing(2),
    color: "red",
  },
}))

export default function MenuListComposition(props) {
  const { authData } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const classes = useStyles()
  const custom = useStylesCustom()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const navigate = useNavigate()

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const logout = async () => {
    await dispatch({ type: "LOGOUT" })
    navigate("/auth")
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <div className={classes.root}>
      <Avatar
        ref={anchorRef}
        onClick={handleToggle}
        style={{ cursor: "pointer" }}
        alt={authData?.name}
        src={authData?.avatar}
      >
        {authData?.name?.charAt(0)}
      </Avatar>

      {/* Popper */}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                {/* Menu */}
                {navigationsLinks()}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )

  function navigationsLinks() {
    var links = []

    switch (authData?.role) {
      case "admin":
        links = [link("/profile", PersonIcon, "Mon compte")]
        break
      case "prestataire":
        links = [
          link("/profile", PersonIcon, "Mon compte"),
          link("/admin/my_posts", ListAltIcon, "Mes publications"),
          link(
            "/admin/reservations",
            AssignmentReturnedIcon,
            "Demande de réservations"
          ),
        ]
        break
      case "client":
        links = [
          link("/profile", PersonIcon, "Mon compte"),
          link("/my_reservations", EventSeatIcon, "Mes réservations"),
        ]
        break

      default:
        break
    }
    return (
      <MenuList
        autoFocusItem={open}
        id="menu-list-grow"
        onKeyDown={handleListKeyDown}
        onClick={handleClose}
      >
        {links.map((link, key) => (
          <div key={key}>{link}</div>
        ))}

        {/* Logout */}
        <Link to="/auth" onClick={() => logout()}>
          <MenuItem>
            <ExitToAppIcon
              className={`${custom.svgAnimation} ${classes.itemStyle}`}
            />
            Déconnexion
          </MenuItem>
        </Link>
      </MenuList>
    )
  }

  function link(path, Icon, text) {
    return (
      <Link to={path}>
        <MenuItem>
          <Icon className={`${custom.svgAnimation} ${classes.itemStyle}`} />
          {text}
        </MenuItem>
      </Link>
    )
  }
}
