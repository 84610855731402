import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
// import moment from 'moment';
import WhatsAppIcon from "@mui/icons-material/WhatsApp"
import { Button, CircularProgress, Paper, Skeleton, Stack } from "@mui/material"
import { useParams } from "react-router-dom"
import { API } from "../../api"
import { getPost } from "../../redux/actions/posts"
import PostsComponent from "../Posts/PostsComponent"
import AddReservation from "./AddReservation"
import { TextOverBanner } from "./TextOverBanner"

const PostDetailsPage = () => {
  const { id: postId } = useParams()
  const { post, PostsIsLoading } = useSelector((state) => state.posts)
  const dispatch = useDispatch()

  const [isLoadingImage, setIsLoadingImage] = useState(true)
  const [fetchedPostsOfTheSameCategory, setFetchedPostsOfTheSameCategory] =
    useState([])

  useEffect(() => {
    ;(async () => {
      await dispatch(getPost(postId))

      const { data: postsOfTheSameCategory } = await API.post(
        `/posts/getPostsOfTheSameCategory`,
        { categoriesIds: post?.categoriesIds }
      )
      setFetchedPostsOfTheSameCategory(postsOfTheSameCategory)
    })()
  }, [postId])

  if (post === null) {
    return (
      <Stack
        sx={{ height: "100vh" }}
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress size="7em" />
      </Stack>
    )
  }

  const WhatsUpTag = post.prestataireId?.userId?.phone ? "a" : "p"

  return (
    <>
      <div className="postDetailPage">
        {TextOverBanner(isLoadingImage, post, setIsLoadingImage)}
        <div className="flex">
          {/* ABOUT EVENT */}
          <Paper className="paper" elevation={3} square>
            <h2 style={{ fontFamily: '"Roboto", sans-serif' }}>
              À propos de l'événement
            </h2>
            <p className="event-p">{post?.description}</p>
          </Paper>

          {/* Date and Address and number */}
          <Paper className="paper" elevation={3} square>
            <Stack
              spacing={2}
              // alignItems="center"

              sx={{ width: "50%", margin: "auto" }}
            >
              <AddReservation post={post}>
                <Button color="error" variant="contained" fullWidth>
                  RESERVER
                </Button>
              </AddReservation>
              <WhatsUpTag
                target="_blank"
                href={`https://api.whatsapp.com/send?phone=216${post.prestataireId?.userId?.phone}`}
                className="flexAIC"
              >
                <WhatsAppIcon
                  color={
                    post.prestataireId?.userId?.phone ? "success" : "error"
                  }
                  fontSize="large"
                />
                {post.prestataireId?.userId?.phone
                  ? " Contacter le prestataire"
                  : "Actuellement indisponible."}
              </WhatsUpTag>
            </Stack>
          </Paper>
        </div>
        <h2 style={{ margin: " 0 2rem" }}>Posts similaires</h2>
        {postsRender(fetchedPostsOfTheSameCategory)}
        {/* <div className="carousel-container">
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={2000}
            keyBoardControl={true}
            transitionDuration={1000}
            // arrows={false}
          >
            {fetchedPostsOfTheSameCategory.map((post, key) => (
              <div key={key} className="carousel-image-container">
                <div className="time">Dans 9h25</div>
                <img
                  className="carousel-image"
                  src="https://picsum.photos/200"
                  style={{
                    aspectRatio: "1/1",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                  alt="More post"
                />
                <h3>{post?.title}</h3>
              </div>
            ))}
          </Carousel>
        </div>  */}
      </div>
    </>
  )

  function postsRender(postsToRender) {
    return (
      <>
        {PostsIsLoading ? (
          <div className="flex-between">
            {[...Array(3)].map((e, key) => (
              <div key={key} style={{ width: "30%" }}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width="100%"
                  height={118}
                />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" width="60%" />
              </div>
            ))}
          </div>
        ) : (
          <PostsComponent renderMode={"Carousel"} posts={postsToRender} />
        )}
      </>
    )
  }
}

export default PostDetailsPage
