import { Divider, Grid, Rating, Stack, TextField } from "@mui/material"

export function AddEditPrestataireForm({ setFormData, formData }) {
  return (
    <>
      <Grid sx={{ my: 2 }} item xs={12}>
        <Divider>Informations Prestataire</Divider>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={2}>
          {input("address", "Adresse")}
          {input("city", "Cité")}
          <Rating
            value={formData.rating}
            onChange={(e,value) => setFormData({ ...formData, rating: value })}
            precision={0.5}
          />
        </Stack>
      </Grid>
    </>
  )

  function input(name, label, props) {
    return (
      <TextField
        name={name}
        value={formData[name]}
        onChange={(e) => setFormData({ ...formData, [name]: e.target.value })}
        label={label}
        variant="outlined"
        inputProps={{
          autoComplete: "disabled",
        }}
        {...props}
      />
    )
  }
}
