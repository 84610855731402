import React from "react"
import { Button, Stack } from "@mui/material"
import { GoogleLogin } from "react-google-login"
import FacebookIcon from "@mui/icons-material/Facebook"
import GoogleIcon from "@mui/icons-material/Google"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { AUTH } from "redux/actionTypes"

export function GoogleFacebookLogin( ) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const googleSuccess = async (res) => {
    const result = res?.profileObj
    const token = res?.tokenId

    try {
      dispatch({ type: AUTH, data: { result, token } })
      navigate("/")
    } catch (error) {
      console.log(error)
    }
  }

  const googleError = () =>
    console.log("Google Sign In was unsuccessful. Try again later")

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ mt: 4, width: "100%" }}
      spacing={2}
    >
      <GoogleLogin
        clientId="564033717568-bu2nr1l9h31bhk9bff4pqbenvvoju3oq.apps.googleusercontent.com"
        render={(renderProps) => (
          <Button
            // className={classes.googleButton}
            color="primary"
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            startIcon={<GoogleIcon />}
            // variant="contained"
            fullWidth
          >
            Google
          </Button>
        )}
        onSuccess={googleSuccess}
        onFailure={googleError}
        cookiePolicy="single_host_origin"
      />

      <GoogleLogin
        clientId="564033717568-bu2nr1l9h31bhk9bff4pqbenvvoju3oq.apps.googleusercontent.com"
        render={(renderProps) => (
          <Button
            // className={classes.googleButton}
            color="primary"
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            startIcon={<FacebookIcon />}
            // variant="contained"
            fullWidth
          >
            Facebook
          </Button>
        )}
        onSuccess={googleSuccess}
        onFailure={googleError}
        cookiePolicy="single_host_origin"
      />
    </Stack>
  )
}
