import styled from "@emotion/styled"
import { Stack, Tooltip } from "@mui/material"
import moment from "moment"
import React, { useEffect } from "react"

const ToBeStyledTooltip = ({ className, ...props }) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
)
const StyledTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
  backgroundColor: "rgb(250, 154, 161)",
  color: "white",
  border: "1px solid #dadde9",
  fontSize: "20px",
}))

function ReservationRequest({ reservation }) {
  return (
    <div className="reservationRequest">
      <h3>Demande de réservation</h3>
      <Stack spacing={4} sx={{ p: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <p className="date">
            {moment(reservation.createdAt).format("DD/MM/YYYY")}
          </p>

          <div className={"status " + reservation.status}>
            {(() => {
              switch (reservation.status) {
                case "pending":
                  return "En attente"
                case "accepted":
                  return "Acceptée"
                case "rejected":
                  return "Rejetée"
              }
            })()}
          </div>
        </Stack>
        <div className="place">{reservation.numberOfPlace} Places</div>
        <StyledTooltip
          title={reservation.userReference.name}
          sx={{ bgcolor: "common.black" }}
        >
          <div className="infos ellipsis">{reservation.userReference.name}</div>
        </StyledTooltip>
        <StyledTooltip title={reservation.userReference.email}>
          <div className="infos ellipsis">
            {reservation.userReference.email}
          </div>
        </StyledTooltip>
        <StyledTooltip title={reservation.userReference.phone}>
          <div className="infos ellipsis">
            {reservation.userReference.phone}
          </div>
        </StyledTooltip>
        <div className="infos">{reservation.text}</div>
      </Stack>
    </div>
  )
}

export default ReservationRequest
