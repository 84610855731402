import React, { useEffect, useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { useDispatch, useSelector } from "react-redux"
import ConfirmModal from "../ConfirmModal.js"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import Grid from "@mui/material/Grid"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import EditIcon from "@mui/icons-material/Edit"
import AddCategoryModal from "../Modals/AddCategoryModal"
import {
  findCategories,
  deleteCategory,
} from "../../../redux/actions/categories.js"
import { CircularProgress, IconButton } from "@mui/material"
import AddEditCategoryDialog from "./AddEditCategoryDialog.js"
import { DeleteCategoryDataGridAction } from "../../../GlobalComponent/dataGridActions/index.js"

const useStyles = makeStyles({
  table: {
    minWidth: "100%",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
  },
  paper: {
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
    borderRadius: "20px",
    minHeight: "75vh",
  },
})

export default function Categories() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { categories, CategoriesIsLoading } = useSelector(
    (state) => state.category
  )

  const [showConfirmation, setShowConfirmation] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [showAddCategory, setShowAddCategory] = useState(false)

  const selectEditCategory = (category) => {
    setSelectedCategory(category)
    setShowAddCategory(true)
  }

  const handleCloseAddCategory = () => {
    reloadData()
    setShowAddCategory(false)
  }

  const handleOpenAddCategory = () => {
    setSelectedCategory(null)
    setShowAddCategory(true)
  }

  const showDeleteConfirmation = (category) => {
    setSelectedCategory(category)
    setShowConfirmation(true)
  }

  const handleDeleteCategory = () => {
    dispatch(deleteCategory(selectedCategory._id)).then(() => {
      setShowConfirmation(false)
    })
  }

  const reloadData = () => {
    dispatch(findCategories())
  }

  useEffect(() => {
    dispatch(findCategories())
  }, [dispatch])

  return (
    <Grid container className="categoryPage">
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div>
            <TableContainer component={Paper}>
              <div className="paper-header">
                <h3 className="mytitle">
                  Liste des catégories
                  {CategoriesIsLoading ? (
                    <span className="loading">
                      <img src="/images/admin-loading.gif" alt="Loading" />
                    </span>
                  ) : null}
                </h3>
                <div className="button-container">
                  <AddEditCategoryDialog>
                    <AddCircleIcon sx={{ color: "white" }} />
                  </AddEditCategoryDialog>
                  {/* <IconButton onClick={() => handleOpenAddCategory()}>
                    
                    <AddCircleIcon sx={{ color: "white" }} />
                  </IconButton> */}
                </div>
              </div>
              <Table className={classes.table} aria-label="simple table">
                <TableHead className="custom-thead">
                  <TableRow>
                    <TableCell>Ordre</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Parent</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categories
                    ?.filter((c) => c.parent === "0")
                    .map((category) => (
                      <React.Fragment key={category._id}>
                        <TableRow className="categoryRow">
                          <TableCell>
                            <span className="cat-order">{category.order}</span>
                          </TableCell>
                          <TableCell>
                            <img
                              className="table-catImg"
                              src={category.image}
                              alt={category.title}
                            />
                          </TableCell>
                          <TableCell>
                            <strong>{category?.title}</strong>
                          </TableCell>
                          <TableCell>
                            {categories?.find((c) => c._id === category.parent)
                              ?.title || ""}
                          </TableCell>
                          <TableCell className="crud-actions" align="center">
                            <div style={{ display: "flex" }}>
                              <AddEditCategoryDialog>
                                <EditIcon
                                  sx={{ color: "#080" }}
                                  onClick={() =>
                                    dispatch({
                                      type: "SET_SELECTED_ROW",
                                      payload: category,
                                    })
                                  }
                                />
                              </AddEditCategoryDialog>
                              <DeleteCategoryDataGridAction id={category._id} />
                            </div>
                          </TableCell>
                        </TableRow>
                        {categories
                          ?.filter(
                            (c) => c.parent !== "0" && c.parent === category._id
                          )
                          .map((subCategory) => (
                            <TableRow
                              key={subCategory?._id}
                              className="subCategory"
                            >
                              <TableCell>
                                <span className="subcat-order">
                                  {subCategory.order}
                                </span>
                              </TableCell>
                              <TableCell>
                                <img
                                  className="table-catImg"
                                  src={subCategory.image}
                                  alt={subCategory.title}
                                />
                              </TableCell>
                              <TableCell>{subCategory?.title}</TableCell>
                              <TableCell>
                                {categories?.find(
                                  (c) => c._id === subCategory.parent
                                )?.title || "None"}
                              </TableCell>
                              <TableCell
                                className="crud-actions"
                                align="center"
                              >
                                <div style={{ display: "flex" }}>
                                  <AddEditCategoryDialog>
                                    <EditIcon
                                      sx={{ color: "#080" }}
                                      onClick={() =>
                                        dispatch({
                                          type: "SET_SELECTED_ROW",
                                          payload: subCategory,
                                        })
                                      }
                                    />
                                  </AddEditCategoryDialog>
                                  <DeleteCategoryDataGridAction
                                    id={subCategory._id}
                                  />
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </React.Fragment>
                    ))}
                </TableBody>
              </Table>
              <ConfirmModal
                show={showConfirmation}
                qst="Are you sure to delete category ?"
                title="Delete Category"
                onConfirm={(e) => handleDeleteCategory(e)}
                onClose={() => {
                  setShowConfirmation(false)
                }}
              ></ConfirmModal>
            </TableContainer>
          </div>
          <AddCategoryModal
            onClose={(e) => handleCloseAddCategory(e)}
            showAddCategory={showAddCategory}
            category={selectedCategory}
            categories={categories}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}
