import Paper from "@mui/material/Paper"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { updatePost } from "../../../redux/actions/posts.js"

import { SET_SELECTED_ROW } from "../../../redux/actionTypes"
import DataGridComponent from "../DataGridComponent/DataGridComponent.js"
import WaitingPostColumns from "./WaitingPostColumns.js"

export default function WaitingPosts() {
  const dispatch = useDispatch()
  const { posts , PostsIsLoading} = useSelector((state) => state.posts)
  const [columns, setColumns] = useState([])


  useEffect(() => {
    setColumns(WaitingPostColumns(dispatch))

    return () => dispatch({ type: SET_SELECTED_ROW, payload: null })
  }, [])


  return (
    <div className="waitingPostPage">
      <Paper sx={{ p: 3 }} elevation={4}>
        <h3 style={{ marginBottom: "1rem" }}>Publications en attente</h3>

        <DataGridComponent
          rows={posts.filter((post) => post.status?.toLowerCase() === "waiting")}
          columns={columns || []}
          onRowEdit={(newRow) => dispatch(updatePost(newRow._id, newRow))}
          loading={PostsIsLoading}
        />
      </Paper>

      
      {/* <JudgePostDetail /> */}
      
    </div>
  )

}
