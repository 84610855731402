import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
} from "@mui/material"
import { useState } from "react"
import { useSelector } from "react-redux"

export function Inputs({ formData, handleChange }) {
  const { authData } = useSelector((state) => state.auth)

  const [showPassword, setShowPassword] = useState("password")

  if (!authData ) return <></>

  if (authData?.role === "prestataire")
    return (
      <Stack spacing={5}>
        {input({ label: "Nom", name: "name" })}
        {input({ label: "Adresse", name: "address" })}
        {input({ label: "Cité", name: "city" })}
        {input({ label: "Téléphone", name: "phone" })}
        {input({
          label: "Adresse email",
          name: "email",
        })}
        {input({ label: "Mot de passe", name: "password" })}
        {input({
          label: "Confirmer mot de passe",
          name: "confirmPassword",
        })}
      </Stack>
    )

  return (
    <Stack spacing={5}>
      {input({ label: "Nom", name: "name" })}
      {input({ label: "Téléphone", name: "phone" })}
      {input({ label: "Date de naissance", name: "birthday", props: { type : "date"} })}
      {input({ label: "Adresse", name: "address" })}
      {input({
        label: "Adresse email",
        name: "email",
        // props: { disabled: true },
      })}
      {input({ label: "Mot de passe", name: "password" })}
      {input({
        label: "Confirmer mot de passe",
        name: "confirmPassword",
      })}
    </Stack>
  )

  function input({ label, name, props }) {
    return (
      <FormControl>
        <InputLabel variant="standard">{label}</InputLabel>

        <TextField
          style={{ backgroundColor: "#EFE5E5" }}
          variant="filled"
          placeholder={label}
          InputProps={
            name === "password" || name === "confirmPassword"
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowPassword(
                            showPassword === "password" ? "text" : "password"
                          )
                        }
                        size="large"
                      >
                        {showPassword === "password" ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              : null
          }
          name={name}
          value={formData[name]}
          onChange={handleChange}
          {...props}
        />
      </FormControl>
    )
  }
}
