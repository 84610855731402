import React from "react"
import { Button, Stack } from "@mui/material"
import { signin } from "../../redux/actions/auth"

export function fastLogin(dispatch, navigate) {
  return (
    <Stack sx={{ position: "absolute", bottom: 0, left: 0 }} spacing={2}>
      <Button
        onClick={() =>
          dispatch(
            signin({ email: "admin@gmail.com", password: "1111" }, navigate)
          )
        }
      >
        Test:Connection Prestataire
      </Button>
      <Button
        onClick={() =>
          dispatch(
            signin(
              { email: "manarheni9@gmail.com", password: "manar 1234" },
              navigate
            )
          )
        }
      >
        Test:Connection Admin
      </Button>
      <Button
        onClick={() =>
          dispatch(
            signin(
              {
                email: "denguezli.iskander@gmail.com2",
                password: "1111",
              },
              navigate
            )
          )
        }
      >
        Test:Connection Client
      </Button>
    </Stack>
  )
}
