import { Avatar, Stack } from "@mui/material"
import React, { useEffect, useState } from "react"
import WhatsAppIcon from "@mui/icons-material/WhatsApp"
export default function Footer() {
  return (
    <footer
      className="footer"
      style={{ marginTop: window.location.pathname == "/auth" ? 0 : "" }}
    >
      <h1 className="footerGridMiddle">
        Tunisia <span style={{ color: "pink" }}>Events</span>
      </h1>
      <img
        style={{ margin: "auto", width: "25%" }}
        src="/images/divider_title.webp"
      />
      <h2>Site événementiel</h2>
      {socialLinks()}
      <p className="create-by">Crée par Creo</p>
    </footer>
  )

  function socialLinks() {
    return (
      <div className={`footerLinks`}>
        <a
          href="https://www.facebook.com/anywhere4"
          target="_blanc"
          rel="noreferrer noopener"
        >
          <img
            className={"socialImg"}
            src="/images/socials/facebook.png"
            alt="Social"
          />
        </a>
        <a
          href="https://www.linkedin.com/company/anytime-anywhere/"
          target="_blanc"
          rel="noreferrer noopener"
        >
          <img
            className={"socialImg"}
            src="/images/socials/linkedin.png"
            alt="Social"
          />
        </a>
        <a
          href="https://www.instagram.com/anytime4anywhere/"
          target="_blanc"
          rel="noreferrer noopener"
        >
          <img
            className={"socialImg"}
            src="/images/socials/instagram.png"
            alt="Social"
          />
        </a>
        <a href="https://www.instagram.com/anytime4anywhere/">
          <Avatar className="socialImg" sx={{ bgcolor: "green", ml: 0.5 }}>
            <WhatsAppIcon
              sx={{
                fontSize: 40,
              }}
            />
          </Avatar>
        </a>
      </div>
    )
  }
}
