import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import React from "react"

export function SelectController({
  label,
  name,
  options,
  handleChange,
  formData,
}) {
  return (
    <FormControl sx={{ width: "150px" }}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={formData[name]}
        name={name}
        label={label}
        onChange={handleChange}
      >
        {options.map((option, key) => (
          <MenuItem key={key} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
