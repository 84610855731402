import { createTheme, adaptV4Theme } from "@mui/material/styles"

// assets
import colors from "./_themes-vars.module.scss"

// project imports
import componentStyleOverrides from "./compStyleOverride"
import themePalette from "./palette"
import themeTypography from "./typography"

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization) => {
  const color = colors

  const themeOption = {
    colors: color,
    heading: color.grey900,
    paper: color.paper,
    backgroundDefault: color.paper,
    background: color.primaryLight,
    darkTextPrimary: color.grey700,
    darkTextSecondary: color.grey500,
    textDark: color.grey900,
    menuSelected: color.secondaryDark,
    menuSelectedBack: color.secondaryLight,
    divider: color.grey200,
    customization,
  }

  const themeOptions = {
    direction: "ltr",
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: "48px",
        padding: "16px",
        "@media (min-width: 600px)": {
          minHeight: "48px",
        },
      },
    },
    typography: themeTypography(themeOption),
    headerIcon: {
      fontSize: "1.5rem",
      color: "#F76C6F",
      cursor: "pointer",
      //  margin: "4px 5px"
    },

    components: {
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: "2rem",
            fontFamily: "Cambria",
          },
        },
      },

      // MuiTextField: {
      //   styleOverrides: {
      //     variants: [
      //       // variants will help you define the props given by Mui and the styles you want to apply for it
      //       {
      //         props: { variant: "outlined", disabled: true },
      //         style: {
      //           // backgroundColor: "green",
      //           color: "red",
      //           fontSize: "5rem",
      //         },
      //       },
      //     ],
      //   },
      // },

      MuiAlert: {
        styleOverrides: {
          filledSuccess: {
            backgroundColor: "rgba(70,134,157,1)",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          textPrimary: {
            color: "white",
            backgroundColor: "rgba(70,134,157,1)",
            // background:
            //   "linear-gradient(66deg, rgba(240,112,128,1) 0%, rgba(70,134,157,1) 100%)",

            transition: "all 0.5s ease-in-out",
            "&:hover": {
              filter: "brightness(1.2)",
              backgroundColor: "rgba(70,134,157,1)",
            },
          },
        },
      },
    },
  }

  const themes = createTheme(themeOptions)
  //   themes.components = componentStyleOverrides(themeOption)

  return themes
}

export default theme
