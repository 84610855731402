import React, { useEffect, useMemo, useState } from "react"
import Paper from "@mui/material/Paper"
import { useDispatch, useSelector } from "react-redux"
import { CircularProgress, IconButton, Stack } from "@mui/material"
import AddPostModal from "../Modals/AddPostModal.js"
import DataGridComponent from "../DataGridComponent/DataGridComponent.js"
import PublicPostColumns from "./PublicPostColumns.js"
import { SET_SELECTED_ROW } from "../../../redux/actionTypes"


export default function Posts() {
  const dispatch = useDispatch()
  const { posts, PostsIsLoading } = useSelector((state) => state.posts)

  const [publicPostColumns, setPublicPostColumns] = useState([])

  useEffect(() => {
    setPublicPostColumns(PublicPostColumns(dispatch))

    return () => dispatch({ type: SET_SELECTED_ROW, payload: null })
  }, [])

  return (
    <div className="publicPostPage">
      <Paper sx={{ p: 3 }} elevation={4}>
        {/* Title */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 3 }}
        >
          <h3>Publication Publiques</h3>
        </Stack>

        {/* Data Grid */}
        <DataGridComponent
          rows={posts.filter((post) => post.status.toLowerCase() === "public")}
          columns={publicPostColumns}
          loading={PostsIsLoading}
        />
      </Paper>

    </div>
  )

}
