import {
  FETCH_ALL,
  FETCH_POST,
  ADD_POST,
  UPDATE_POST,
  DELETE_POST,
  LIKE,
  COMMENT,
  START_LOADING,
  END_LOADING,
  PUBLIC_WAITING,
  LIST_POSTS,
  TOP_POST,
  POSTS_ERROR,
  SHOW_NEW_POST_WINDOW,
} from "../actionTypes"

export default (
  state = {
    PostsIsLoading: true,
    posts: [],
    post: null,
    postsError: "",
    showNewPostWindow: false,
  },
  action
) => {
  const payload = action.payload
  switch (action.type) {
    case START_LOADING:
      return { ...state, PostsIsLoading: true }
    case END_LOADING:
      return { ...state, PostsIsLoading: false }
    case FETCH_ALL:
      return { ...state, posts: payload.data }
    case FETCH_POST:
      return { ...state, post: payload.post }
    case LIKE:

      return {
        ...state,
        posts: state.posts.map((post) =>
          post._id === payload._id ? payload : post
        ),
      }
    case COMMENT:
      return {
        ...state,
        posts: state.posts.map((post) =>
          post._id === payload.data._id ? payload.data : post
        ),
      }
    case ADD_POST:
      return { ...state, posts: [...state.posts, payload] }
    case UPDATE_POST:
      return {
        ...state,
        posts: state.posts.map((post) =>
          post._id === payload._id ? payload : post
        ),
      }
    case DELETE_POST:
      return {
        ...state,
        posts: state.posts.filter((post) => post._id !== payload),
      }
    case PUBLIC_WAITING:
      return {
        ...state,
        posts: state.posts.map((post) =>
          post._id === payload._id ? payload : post
        ),
      }
    case TOP_POST:
      return {
        ...state,
        posts: state.posts.map((post) =>
          post._id === payload._id ? payload : post
        ),
      }
    case LIST_POSTS:
      return { ...state, posts: payload.data }
    case POSTS_ERROR:
      return { ...state, postsError: payload }
    case SHOW_NEW_POST_WINDOW:
      return { ...state, showNewPostWindow: payload }
    default:
      return state
  }
}
