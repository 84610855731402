import React from "react"
import Skeleton from "@mui/material/Skeleton"

export default function FillerSkeleton() {
  return (
    <div className="flex-between"  style={{margin :"1rem" }} >
      {[...Array(3)].map((e, key) => (
        <div
          key={key}
          style={{
            width: "30%",
          }}
        >
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height={118}
          />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" width="60%" />
        </div>
      ))}
    </div>
  )
}
