import React, { useState } from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import CategoryItem from "./CategoryItem"
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 7,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

export default function Categories({ categories }) {

  return (
    <div style={{ marginBottom: "1.5rem" }}>
      <Carousel
        responsive={responsive}
        infinite={true}
        // autoPlay={true}
        autoPlaySpeed={2000}
        keyBoardControl={true}
        transitionDuration={1000}
        arrows={true}
      >
        {categories
          ?.filter((cat) => cat.parent === "0")
          ?.map((category, key) => (
            <CategoryItem key={key} category={category} />
          ))}
      </Carousel>
    </div>
  )
}
