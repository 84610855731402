import makeStyles from '@mui/styles/makeStyles';

export default makeStyles({
  card: {
    borderRadius: '10px',
    width: '97%',
    height: '100%',
    margin: '0 auto',
    position: 'relative',
    boxShadow: '0 0 20px 0 rgb(62 28 131 / 10%)',
  },
  media: {
    height: '180px',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    backgroundBlendMode: 'darken',
  },
  content: {
    padding: "15px",
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: 0,
    maxWidth: "80%",
    height: '40px',
    color: '#333'
  },
  location: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    color: "#F76C6F",
    marginBottom: "10px",
  },
  adress: {
    paddingTop: "2px",
    paddingLeft: "4px",
    color: "#333",
    whiteSpace: "nowrap",
    fontSize: "14px",
    '& a': {
      color: '#F76C6F'
    }
  },
  category: {
    marginRight: '5px',
    cursor: "pointer",
    fontSize: "14px"
  },
  svgIcon: {
    fontSize: '1.2rem',
    color: "#F76C6F"
  },
  fullHeightCard: {
    height: '100%',
  },
  
  overlay: {
    position: 'absolute',
    top: '20px',
    left: '20px',
    color: 'white',
  },
  overlay2: {
    position: 'absolute',
    top: '52%',
    left: '20px',
    color: 'white',
    background: "none"
  },
  grid: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px',
  },
  
  cardActions: {
    padding: '0 16px 8px 16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardAction: {
    display: 'block',
    textAlign: 'initial',
  },
});
