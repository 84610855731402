import { Avatar, IconButton } from "@mui/material"
import React from "react"
import moment from "moment"
import JudgeReservation from "./JudgeReservationDialog"
import FlakyIcon from "@mui/icons-material/Flaky"

export default function ReservationsColumnsPrestataire() {
  let columns = [
    {
      field: "name",
      headerName: "Nom",
      valueGetter: (params) => params.row.userReference?.name,
    },
    {
      field: "email",
      valueGetter: (params) => params.row.userReference?.email,
    },
    {
      field: "phone",
      headerName: "Téléphone",

      valueGetter: (params) => params.row.userReference?.phone,
    },
    {
      field: "text",
      headerName: "Message",
    },
    {
      field: "createdAt",
      headerName: "Date",

      valueFormatter: (params) =>
        moment(params.value).format("DD/MM/YYYY HH:mm"),
    },
    { field: "numberOfPlace", headerName: "Nb de place" },
    {
      field: "status",
      valueFormatter: (params) => {
        switch (params.value) {
          case "PENDING":
            return "En attente"
          case "ACCEPTED":
            return "Approuvée"
          case "REJECTED":
            return "Rejetée"
        }
      },
    },

    {
      field: "action",
      renderCell: (cellValues) => {
        return (
          <JudgeReservation cellValues={cellValues}>
            <IconButton variant="contained" color="error" size="large">
              <FlakyIcon sx={{ fontSize: "2rem" }} />
            </IconButton>
          </JudgeReservation>
        )
      },
    },
  ]

  // Center columns, filed and headerName
  columns = columns.map(
    (column, index) =>
      (column = {
        ...{
          headerName: capitalizeFirstLetter(column.field),
          flex: 1,
          headerAlign: "center",
          align: "center",
        },
        ...column,
      })
  )

  return columns
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
