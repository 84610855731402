import { Button, Stack } from "@mui/material"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { signin } from "../../redux/actions/auth"
import { feedbackError } from "../../redux/actions/feedback"
import useStyles from "../Header/styles"
import { GoogleFacebookLogin } from "./GoogleFacebookLogin"
import Input from "./Input"

const initialState = {
  email: "",
  password: "",
}

function LoginForm() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const classes = useStyles()
  const location = useLocation()

  const from = location.state?.from.pathname || "/"

  const [formData, setFormData] = useState(initialState)
  const [showPassword, setShowPassword] = useState(false)

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value })

  const handleShowPassword = () => setShowPassword(!showPassword)

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      await dispatch(signin(formData))
      navigate(from)
    } catch (error) {
      dispatch(feedbackError(error?.response?.data))
    }
  }

  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit}
      style={{ maxWidth: "30vw" }}
    >
      <Stack alignItems="center" spacing={2}>
        {input({ label: "Email", name: "email", type: "email" })}
        {input({
          label: "Mot de passe",
          name: "password",
          type: showPassword ? "text" : "password",
          handleShowPassword: handleShowPassword,
        })}

        <Link to="/forget_password" >Mot de passe oublié ?</Link>

        <Button
          type="submit"
          fullWidth
        >
          Se connecter
        </Button>

        {GoogleFacebookLogin()}
      </Stack>
    </form>
  )
  function input(props) {
    return (
      <Input
        value={formData[props.name]}
        handleChange={handleChange}
        {...props}
      />
    )
  }
}

export default LoginForm
