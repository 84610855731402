import React from "react"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import ListSubheader from "@mui/material/ListSubheader"
import DashboardIcon from "@mui/icons-material/Dashboard"
import PeopleIcon from "@mui/icons-material/People"
import AssignmentIcon from "@mui/icons-material/Assignment"
import { Link } from "react-router-dom"
import CategoryIcon from "@mui/icons-material/Category"
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd"
import PublicIcon from "@mui/icons-material/Public"
import EventSeatIcon from "@mui/icons-material/EventSeat"
import RequestPageIcon from "@mui/icons-material/RequestPage"
import ThumbDownIcon from '@mui/icons-material/ThumbDown';


export const mainListItems = () => {
  const currentUser = JSON.parse(localStorage.getItem("profile"))

  if (currentUser?.role == "admin")
    return (
      <div className="admin-menu">
        {/* //! -------------- Admin ----------------- */}
        {link("/admin/waiting_posts", DashboardIcon, "Pubs en Attente")}
        {link("/admin/public_posts", PublicIcon, "Pubs Publiques")}
        {link("/admin/archived_posts", AssignmentIcon, "Pubs Archivées")}
        {link("/admin/rejected_posts", ThumbDownIcon, "Pubs Rejetées")}
        {link("/admin/categories", CategoryIcon, "Categories")}
        {link("/admin/prestataires", AssignmentIndIcon, "Prestataires")}
        {link(
          "/admin/membership_prestataires",
          RequestPageIcon,
          "Demandes d'adhésion"
        )}
        {link("/admin/users", PeopleIcon, "Utilisateurs")}
      </div>
    )

  {
    /* //! ----------- Prestataire -------------- */
  }
  if (currentUser?.role == "prestataire")
    return (
      <div className="admin-menu">
        {link("/admin/my_posts", PublicIcon, "Mes Publication")}
        {link(
          "/admin/reservations",
          EventSeatIcon,
          <span>
            Demandes de <br /> réservation
          </span>
        )}
      </div>
    )

  return <></>

  function link(path, Icon, text) {
    return (
      <Link to={path}>
        <ListItem button>
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText
            primary ={text}
            primaryTypographyProps={{
              style: { color: "white", fontSize: "20px" },
            }}
          />
        </ListItem>
      </Link>
    )
  }
}

// Statistique
export const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
)
