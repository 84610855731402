import React, { useEffect, useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import Paper from "@mui/material/Paper"
import { useDispatch, useSelector } from "react-redux"
import DataGridComponent from "../DataGridComponent/DataGridComponent.js"
import { updatePrestataire } from "redux/actions/prestataires.js"
import { SET_SELECTED_ROW } from "../../../redux/actionTypes"
import MembershipColumns from "./MembershipColumns.js"

export default function MembershipPrestatairesPage() {
  const dispatch = useDispatch()
  const { prestataires } = useSelector((state) => state.prestataires)
  console.log(prestataires)
  const [prestataireColumns, setPrestataireColumns] = useState()

  useEffect(() => {
    setPrestataireColumns(MembershipColumns())

    return () => dispatch({ type: SET_SELECTED_ROW, payload: null })
  }, [])

  return (
    <div className="prestatairePage">
      <Paper sx={{ p: 3, mb: 3 }} elevation={4}>
        <div className="paper-content">
          <h3 className="mytitle">Demandes d’adhésion </h3>

          <div>
            <DataGridComponent
              rows={prestataires.filter(
                (prestataire) => prestataire?.accepted === false
              )}
              columns={prestataireColumns}
              getRowClassName={(params) =>
                `client-row ${
                  params.row.userId?.freeze === true ? "freezed" : ""
                }`
              }
              onRowEdit={(newRow) =>
                dispatch(updatePrestataire(newRow._id, newRow))
              }
            />
          </div>
        </div>
      </Paper>
    </div>
  )
}
