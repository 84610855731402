import * as React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { IconButton, Tooltip } from "@mui/material"
import { useDispatch } from "react-redux"
import { feedbackError, feedbackSuccess } from "redux/actions/feedback"

export default function ConfirmDialog({
  actionFn,
  title,
  text,
  Form,
  children,
  feedback,
}) {
  const dispatch = useDispatch()

  const action = title.slice(0, title.indexOf(" "))

  /* #region  Open Close */
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
    console.log("ta");
  }

  const handleClose = () => {
    setOpen(false)
  }
  /* #endregion */

  const onSubmit = async () => {
    try {
      actionFn()
      dispatch(feedbackSuccess(feedback ? feedback: "Opération effectuée avec succès."))
      setTimeout(() => {
        handleClose()
      }, 1000)
    } catch (error) {
      dispatch(feedbackError(error?.response?.data))
    }
  }

  return (
    <>
      {children.type.render?.name === "Button" ? (
        React.cloneElement(children, { onClick: handleClickOpen })
      ) : (
        <Tooltip
          title={capitalizeFirstLetter(action)}
          onClick={handleClickOpen}
        >
          <IconButton variant="contained" size="large">
            {children}
          </IconButton>
        </Tooltip>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
          {Form}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fermer</Button>
          <Button onClick={onSubmit}>{capitalizeFirstLetter(action)}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
