import { AddImages } from "./AddImages"
import React, { createContext, useEffect,  useState } from "react"
import Dialog from "@mui/material/Dialog"
import { useDispatch, useSelector } from "react-redux"
import { SHOW_NEW_POST_WINDOW } from "../../redux/actionTypes"
import { Button, DialogActions } from "@mui/material"
import { FormInputs } from "./FormInputs"
import { createPost } from "../../redux/actions/posts"
import { feedbackError, feedbackSuccess } from "../../redux/actions/feedback"
import SelectCategories from "./SelectCategories"
import { LoadingButton } from "@mui/lab"
import SaveIcon from "@mui/icons-material/Save"


export const AddPostContext = createContext(null)
var initForm = {}

export default function AddPostDialog() {
  const dispatch = useDispatch()
  const { showNewPostWindow, PostsIsLoading } = useSelector((state) => state.posts)
  const { authData } = useSelector((state) => state.auth)
  const { selectedRow } = useSelector((state) => state.dataGrid)

  const [step, setStep] = useState(0)
  const [formData, setFormData] = useState({})

  useEffect(() => {
    initForm = {
      photos: [],
      title: "",
      description: "",
      price: "",
      categoriesIds: [],
      date: "",
      prestataireId: authData?.prestataireBody,
    }

    setFormData(initForm)
  }, [authData])

  useEffect(() => {
    if (selectedRow) setFormData(selectedRow)
  }, [selectedRow])

  const handleChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleClose = async () => {
    dispatch({ type: SHOW_NEW_POST_WINDOW, payload: false })
    setTimeout(() => {
      setFormData(initForm)
      setStep(0)
    }, 500)
  }

  const stepSwitch = () => {
    switch (step) {
      case 0:
        return <AddImages formData={formData} setFormData={setFormData} />
      case 1:
        return <SelectCategories />
      case 2:
        return <FormInputs />
    }
  }

  const onSubmit = async (e) => {
    try {
      await dispatch(createPost(formData))
      await dispatch(
        feedbackSuccess("Votre post est passé en phase de vérification.")
      )

      setTimeout(() => {
        handleClose()
      }, 1000)
    } catch (error) {
      dispatch(feedbackError(error.response?.data))
    }
  }

  const nextStep = async () => {
    if (step === 0 && formData.photos.length === 0)
      return dispatch(feedbackError("Vous devez ajouter au moins une photo."))

    if (step === 1 && formData.categoriesIds.length === 0)
      return dispatch(
        feedbackError("Vous devez ajouter au moins une catégorie.")
      )

    setStep(step + 1)
  }

  return (
    <Dialog
      open={showNewPostWindow}
      onClose={handleClose}
      maxWidth="xl"
      PaperProps={{
        sx: { borderRadius: 5 },
      }}
    >
      <AddPostContext.Provider
        value={{
          handleChange: handleChange,
          formData: formData,
          setFormData: setFormData,
        }}
      >
        <div className="addNewPost">
          <h3>Créer une nouvelle publication</h3>
          <hr />
          {stepSwitch()}

          <DialogActions>
            {step !== 0 && (
              <Button onClick={() => setStep(step - 1)}>Retour</Button>
            )}

            {step !== 2 ? (
              <Button onClick={nextStep}>Suivant</Button>
            ) : (
              <LoadingButton
                loading={PostsIsLoading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                onClick={onSubmit}
              >
                Confirmer
              </LoadingButton>
            )}
          </DialogActions>
        </div>
      </AddPostContext.Provider>
    </Dialog>
  )
}
// style={{ padding: "1rem", width: "50vw",border: "1px solid black", margin: "1rem", overflowY: "auto"  }}
