import { LoadingButton } from "@mui/lab"
import { Button, Grid, Skeleton } from "@mui/material"
import { AddImages } from "components/AddPost/AddImages"
import ChangeAvatar from "GlobalComponent/ChangeAvatar"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { feedbackError, submitAction } from "redux/actions/feedback"
import { updatePrestataire } from "redux/actions/prestataires"
import CategorySelection from "../../GlobalComponent/CategorySelection"
import { editUser } from "../../redux/actions/users"
import { Inputs } from "./Inputs"
import SaveIcon from "@mui/icons-material/Save"


function ProfilePage() {
  const { authData } = useSelector((state) => state.auth)
  const { isLoading } = useSelector((state) => state.misc)

  const dispatch = useDispatch()
  const [isLoadingImage, setIsLoadingImage] = useState(true)

  const [formData, setFormData] = useState({})
  const handleChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    if (!authData) return

    setFormData(authData)
  }, [authData])

  const onSubmit = async () => {
    const formDataCopy = formData
    if (formData.password !== formData.passwordConfirm)
      return dispatch(feedbackError("Mot de passe non identique."))

    if (formData.email === authData.email) formData.email = undefined

    await dispatch(
      submitAction({
        feedback: "Vos données sont sauvegardées.",
        actionFn:
          authData.role === "prestataire"
            ? updatePrestataire(authData.prestataireBody._id, formData)
            : editUser(authData._id, formData),
      })
    )

    dispatch({ type: "AUTH", payload: { ...authData, ...formDataCopy } })
  }

  if (!authData) return <></>

  return (
    <div className="profilePage">
      {isLoadingImage && (
        <Skeleton style={{ transform: "scale(1)", height: "35vh" }} />
      )}
      <img
        style={{
          width: "100%",
          height: "35vh",
          display: isLoadingImage ? "none" : "block",
        }}
        src="https://picsum.photos/1500/1000"
        alt="Event image"
        onLoad={() => setIsLoadingImage(false)}
      />

      <Grid className="form-container " container spacing={10}>
        <Grid item xs={6}>
          <Inputs formData={formData} handleChange={handleChange} />
        </Grid>
        <Grid item xs={6}>
          <ChangeAvatar formData={formData} setFormData={setFormData} />
        </Grid>
        {authData?.role === "prestataire" && (
          <Grid item xs={12}>
            <AddImages
              height="200px"
              formData={formData}
              setFormData={setFormData}
            />
          </Grid>
        )}

        {/* Category Select */}
        <Grid item xs={12}>
          <CategorySelection setFormData={setFormData} formData={formData} />
        </Grid>
        <LoadingButton
          loading={isLoading}
          startIcon={<SaveIcon />}

          color="primary"
          variant="contained"
          sx={{ m: 4, ml: "auto", fontSize: "1.5rem" }}
          onClick={onSubmit}
        >
          Confirmer
        </LoadingButton>
      </Grid>
    </div>
  )
}

export default ProfilePage
