import React, { useEffect, useState } from "react"
import {
  Grid,
  TextField,
  CardMedia,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { useDispatch, useSelector } from "react-redux"
import SaveIcon from "@mui/icons-material/Save"
import Button from "@mui/material/Button"
import MessageBox from "../MessageBox.js"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import Avatar from "@mui/material/Avatar"
import Chip from "@mui/material/Chip"
import Rating from "@mui/material/Rating"
import InputLabel from "@mui/material/InputLabel"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import moment from "moment"
import { createPost, updatePost } from "../../../redux/actions/posts.js"
import { resizePhotos } from "../../../functions/imageFunctions"
import { API } from "../../../api"
import RestartAltIcon from "@mui/icons-material/RestartAlt"
import CloseIcon from "@mui/icons-material/Close"

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "80%",
      margin: "auto",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    inputUpload: {
      display: "none",
    },
  },
}))

const initForm = {
  categoriesIds: [],
  title: "",
  date: moment(),
  description: "",
  price: "",
  photos: [],
  rating: 0,
  status: "Waiting",
}
export default function AddPostModal(props) {
  const currentUser = JSON.parse(localStorage.getItem("profile"))?.result
  const today = moment(new Date()).format("yyyy-MM-DD")
  const { PostsIsLoading, postsError } = useSelector((state) => state.posts)
  const { post, prestataires, users, categories } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const [newPost, setNewPost] = useState()
  const [feedback, setFeedback] = useState(null)
  const [errors, setErrors] = useState(null)
  const [mediaCardImages, setMediaCardImages] = useState([])
  const [formData, setFormData] = useState(initForm)
  const clear = () => {
    setMediaCardImages([])
    setNewPost({
      categoriesIds: [],
      prestataireId: "0",
      title: "",
      date: today,
      description: "",
      quost: "",
      photos: [],
      rating: 0,
      status: "Waiting",
    })
  }

  // Handles
  const handleClearMediaCardImages = async () => {
    setNewPost({
      ...newPost,
      photos: [],
    })
    setMediaCardImages([])
  }

  const handleSelectFiles = (e, type) => {
    e.preventDefault()
    switch (type) {
      case "avatar":
        document.querySelector("#input-avatar-native input[type=file]").click()
        break
      case "photos":
        document.querySelector("#input-photos-native input[type=file]").click()
        break
    }
  }

  const handleOnAddPhotos = async (e) => {
    if (mediaCardImages.length + e.target.files.length > 5)
      return setFeedback("Images max count reached.")

    const photos = [...e.target.files]
    setNewPost({
      ...newPost,
      photos: [...newPost.photos, ...e.target.files],
    })

    let promises = photos.map((photo) => getBase64(photo))

    const base64Images = await Promise.all(promises)

    setMediaCardImages([...mediaCardImages, ...base64Images])
    return

    var bodyFormData = new FormData()
    if (photos.length > 0) {
      for (const photo of photos) {
        bodyFormData.append("images", photo)
      }
    }

    await API.post(`/posts/addImages/${"df/-1"}`, bodyFormData)
  }

  // Dispatch
  const submitEdit = (e) => {
    e.preventDefault()

    // Modify
    if (post) {
      dispatch(updatePost(post._id, newPost))
        .then(() => {
          setFeedback("Post Updated successfully !")
        })
        .catch((err) => {
          setErrors(err.response.data.message)
        })
      // Add
    } else {
      dispatch(createPost(newPost))
        .then(() => {
          setFeedback("Post Added successfully !")
          clear()
        })
        .catch((err) => {
          setErrors(err)
        })
    }
  }

  const formProps = (value) => {
    const handleFormDataChange = async (e) => {
      setFormData((prev) => {
        return { ...prev, [e.target.name]: e.target.value }
      })
    }
    return {
      name: value,
      value: formData[value],
      onChange: handleFormDataChange,
    }
  }

  if (false)
    return (
      <div className="modal-container show">
        {/* Close button */}
        <div className="modal-box">
          <IconButton
            sx={{ positon: "absolute", top: "3rem", left: "3rem" }}
            onClick={props.onClose}
            size="large">
            <CloseIcon />
          </IconButton>
          <h1>{currentUser?.name} </h1>
          <div className="modal-content">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mb: 2 }}
            >
              <h3 style={{ fontFamily: "Cambria" }}>Ajouter un prestataire</h3>
              <Tooltip title="Réinitialiser le formulaire">
                <IconButton onClick={() => setFormData(initForm)} size="large">
                  <RestartAltIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className="form-center">
                  <form className={classes.root} noValidate autoComplete="off">
                    <Stack spacing={2}>
                      {input("title", "Titre")}
                      {input("date", "", { type: "date" })}
                      {input("price", "Prix")}
                      {input("description", "", { type: "textarea" })}

                      {renderAddPhotos()}

                      {renderCategories()}

                      <Button
                        variant="contained"
                        color="primary"
                        className="mybtn"
                        startIcon={<SaveIcon />}
                        onClick={submitEdit}
                      >
                        {post ? "Edit  " : "Add  "}
                        {PostsIsLoading ? (
                          <img
                            src="/images/loading-buffering.gif"
                            alt="loading"
                            className="loading-img"
                          />
                        ) : null}
                      </Button>
                      {feedback && feedback !== "" ? (
                        <MessageBox>{feedback}</MessageBox>
                      ) : errors ? (
                        <MessageBox variant="danger">{errors}</MessageBox>
                      ) : (
                        ""
                      )}
                    </Stack>
                  </form>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );

  function renderTextInputs() {
    return <></>
  }

  function input(value, label, props) {
    return (
      <TextField
        type="text"
        label={label ? label : capitalizeFirstLetter(value)}
        variant="outlined"
        {...formProps(value)}
        {...props}
        InputLabelProps={{ shrink: true }}
      />
    )
  }

  function renderAddPhotos() {
    return (
      <>
        <div className="flex-imageBox flex-column">
          <Button
            variant="contained"
            className={classes.button}
            startIcon={<CloudUploadIcon />}
            onClick={(e) => handleSelectFiles(e, "photos")}
          >
            Télécharger 5 photos max
          </Button>
          <div className="photos-collections">
            {mediaCardImages.map((photo, index) => (
              <div key={index} className="collection">
                <CardMedia
                  className="collection-file"
                  image={photo.toString()}
                  title={newPost.title}
                />
              </div>
            ))}
          </div>
          <Button
            variant="contained"
            style={{ margin: "1rem 0" }}
            className={classes.button}
            onClick={handleClearMediaCardImages}
          >
            Effacer photos
          </Button>
        </div>
        <div id="input-photos-native">
          <input
            type="file"
            multiple
            onChange={handleOnAddPhotos}
            accept="image/*"
          />
        </div>
      </>
    )
  }

  function renderCategories() {
    return (
      <div>
        <h4>Select categories</h4>
        <div className="categories-container">
          {categories?.map((Category) => (
            <Chip
              key={Category._id}
              size="small"
              label={Category.title}
              avatar={
                <Avatar
                  alt={Category.title}
                  src={Category.image || "/images/default.png"}
                />
              }
              color={
                newPost?.categoriesIds?.find((c) => c === Category._id)
                  ? "secondary"
                  : "primary"
              }
              onClick={() => {
                newPost?.categoriesIds?.find((c) => c === Category._id)
                  ? setNewPost({
                      ...newPost,
                      categoriesIds: newPost.categoriesIds.filter(
                        (c) => c !== Category
                      ),
                    })
                  : setNewPost({
                      ...newPost,
                      categoriesIds: [...newPost.categoriesIds, Category],
                    })
              }}
            />
          ))}
        </div>
      </div>
    )
  }

  function renderPrestataire() {
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="prestataires-select">Prestataire</InputLabel>
        <Select
          native
          value={newPost.prestataireId}
          onChange={(e) =>
            setNewPost({
              ...newPost,
              prestataireId: e.target.value,
            })
          }
          inputProps={{
            name: "prestataires",
            id: "prestataires-select",
          }}
        >
          <option value="0">Select a prestataire</option>
          {prestataires?.map((prestataire) => (
            <option key={prestataire._id} value={prestataire._id}>
              {users?.find((u) => u._id === prestataire.userId).name}
            </option>
          ))}
        </Select>
      </FormControl>
    )
  }

  function renderStatus() {
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="status-select">Status</InputLabel>
        <Select
          native
          value={newPost.status}
          onChange={(e) => setNewPost({ ...newPost, status: e.target.value })}
          inputProps={{
            name: "status",
            id: "status-select",
          }}
        >
          <option value="Waiting">Waiting</option>
          <option value="Public">Public</option>
          <option value="Archived">Archived</option>
        </Select>
      </FormControl>
    )
  }

  function renderTemplates() {
    return !post ? (
      <div className="templates-btn">
        <button
          onClick={() => {
            setMediaCardImages([])
            setNewPost({
              categoriesIds: [
                categories?.find((cat) => cat.title === "Lounges")?._id,
              ],
              prestataireId: prestataires?.find(
                (pst) =>
                  pst.userId ===
                  users?.find((user) => user.name === "Saloon")?._id
              )?._id,
              title: "Soirée inoubliable",
              date: today,
              description:
                "Top du top, soirée inoubliable en plein air du Saloon best of the best; soyez nombreux !",
              quost: "50-70",
              photos: newPost.photos,

              rating: 5,
              status: "Public",
            })
          }}
        >
          Template 1
        </button>
        <button
          onClick={() => {
            setMediaCardImages([])

            setNewPost({
              categoriesIds: [
                categories?.find((cat) => cat.title === "Restaurants")?._id,
              ],
              prestataireId: prestataires?.find(
                (pst) =>
                  pst.userId === users?.find((user) => user.name === "QG")?._id
              )?._id,
              title: "Bon plat d'aujourd'hui au QG",
              date: today,
              description:
                "Bon plat délicieux en plein air du QG best of the best; soyez nombreux !",
              quost: "60-80",
              rating: 5,
              photos: newPost.photos,

              status: "Public",
            })
          }}
        >
          Template 2
        </button>
        <button
          onClick={() => {
            setMediaCardImages([])

            setNewPost({
              categoriesIds: [
                categories?.find((cat) => cat.title === "Lounges")?._id,
              ],
              prestataireId: prestataires?.find(
                (pst) =>
                  pst.userId ===
                  users?.find((user) => user.name === "Iron bar")?._id
              )?._id,
              title: "Remise de l'été",
              date: today,
              description:
                "Remise exceptionnel au Iron Bar aujourd’hui. 10 Bières coûte 30DT !!! soyez nombreux !",
              quost: "30",
              photos: newPost.photos,
              rating: 4,
              status: "Public",
            })
          }}
        >
          Template 3
        </button>
      </div>
    ) : null
  }
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
