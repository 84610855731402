import { IconButton, Stack, useTheme } from "@mui/material"
import decode from "jwt-decode"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useNavigate } from "react-router-dom"
import * as actionType from "../../redux/actionTypes"
import { AutocompleteSearch } from "./AutocompleteSearch"
import HeaderIcons from "./HeaderIcons"
import useStyles from "./styles"
import MenuIcon from "@mui/icons-material/Menu"
import MobileDrawer from "./MobileDrawer"

export default function Header() {
  const { authData } = useSelector((state) => state.auth)
  const { width } = useSelector((state) => state.misc)

  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const classes = useStyles()

  const logout = async () => {
    await dispatch({ type: actionType.LOGOUT })
    navigate("/auth")
  }

  useEffect(() => {
    const token = authData?.token

    if (token) {
      const decodedToken = decode(token)

      if (decodedToken.exp * 1000 < new Date().getTime()) logout()
    }
  }, [location])

  return (
    <header id="header" className={classes.header}>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "95%", margin: "auto" }}
      >
        <Link className={classes.logoContainer} to="/">
          <Stack direction="row" alignItems="center" spacing={2}>
            <img className={classes.logo} src="/images/logo.png" alt="Logo" />
            <h1 className={classes.heading}>Tunisia Events</h1>
          </Stack>
        </Link>

        {/* Search */}
        {width > 500 && <AutocompleteSearch classes={classes} />}
        <HeaderIcons logout={logout} />
        {/* <Stack direction="row" alignItems="center">
          <MobileDrawer>
            <IconButton>
              <MenuIcon />
            </IconButton>
          </MobileDrawer>
        </Stack> */}
      </Stack>
    </header>
  )
}
