//! ------------- CATEGORY -----------------
export const LOADING_CATEGORY = 'LOADING_CATEGORY'
export const END_LOADING_CATEGORY = 'END_LOADING_CATEGORY'
export const SET_CATEGORY = 'SET_CATEGORY'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const ADD_CATEGORY = 'ADD_CATEGORY'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'


// User management
export const CREATE_PRESTATAIRE = "CREATE_PRESTATAIRE"
export const UPDATE_PRESTATAIRE = "UPDATE_PRESTATAIRE"
export const DELETE_PRESTATAIRE = "DELETE_PRESTATAIRE"
export const FETCH_ALL_PRESTATAIRE = "FETCH_ALL_PRESTATAIRE"
export const FETCH_PRESTATAIRE = "FETCH_PRESTATAIRE"
export const LIKE_PRESTATAIRE = "LIKE_PRESTATAIRE"
export const COMMENT_PRESTATAIRE = "COMMENT_PRESTATAIRE"
export const START_LOADING_PRESTATAIRE = "START_LOADING_PRESTATAIRE"
export const ACCEPT_REJECT_PRESTATAIRE = "ACCEPT_REJECT_PRESTATAIRE"
export const END_LOADING_PRESTATAIRE = "END_LOADING_PRESTATAIRE"




{/* //! -------------- Post ------------------ */ }
export const LIST_POSTS = "LIST_POSTS"
export const ADD_POST = "ADD_POST"
export const UPDATE_POST = "UPDATE_POST"
export const DELETE_POST = "DELETE_POST"
export const FETCH_ALL = "FETCH_ALL"
export const FETCH_POST = "FETCH_POST"
export const LIKE = "LIKE"
export const COMMENT = "COMMENT"
export const PUBLIC_WAITING = "PUBLIC_WAITING"
export const TOP_POST = "TOP_POST"
export const START_LOADING = "START_LOADING"
export const POSTS_ERROR = "POSTS_ERROR"
export const SHOW_NEW_POST_WINDOW = "SHOW_NEW_POST_WINDOW"

// Authentification
export const AUTH = "AUTH"
export const LOGOUT = "LOGOUT"
export const LIST_USERS = "LIST_USERS"
export const GET_USER = "GET_USER"
export const CREATE_USER = "CREATE_USER"
export const UPDATE_USER = "UPDATE_USER"
export const DELETE_USER = "DELETE_USER"
export const BLOCK_USER = "BLOCK_USER"
export const END_LOADING_USER = "END_LOADING"
export const START_LOADING_USER = "START_LOADING"
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD"

// Chat
export const FETCH_CHAT_POST = "FETCH_CHAT_POST"
export const CREATE_CHAT_MESSAGE = "CREATE_CHAT_MESSAGE"
export const END_LOADING_CHAT = "END_LOADING_CHAT"
export const START_LOADING_CHAT = "START_LOADING_CHAT"

{
  /* //! ------------- ERRORS ----------------- */
}
export const AUTH_ERROR = "AUTH_ERROR"

// New
export const SET_FETCHED_DATA = "SET_FETCHED_DATA"
export const PUSH_FETCHED_DATA = "PUSH_FETCHED_DATA"
export const PULL_FETCHED_DATA = "PULL_FETCHED_DATA"
export const UPDATE_FETCHED_DATA = "UPDATE_FETCHED_DATA"
export const SET_FEEDBACK = "SET_FEEDBACK"

// DataGrid
export const SET_SELECTED_ROW = "SET_SELECTED_ROW"
export const SET_FEEDBACK_OBJECT = "SET_FEEDBACK_OBJECT"

{
  /* //! ------------- Misc ---------------- */
}
export const LOADING = "LOADING"
export const END_LOADING = "END_LOADING"

//! ------------- RESERVATION -----------------
export const LOADING_RESERVATION = "LOADING_RESERVATION"
export const END_LOADING_RESERVATION = "END_LOADING_RESERVATION"
export const SET_RESERVATION = "SET_RESERVATION"
export const SET_RESERVATIONS = "SET_RESERVATIONS"
export const ADD_RESERVATION = "ADD_RESERVATION"
export const UPDATE_RESERVATION = "UPDATE_RESERVATION"
export const DELETE_RESERVATION = "DELETE_RESERVATION"

//! ------------- NOTIFICATION -----------------
export const LOADING_NOTIFICATION = "LOADING_NOTIFICATION"
export const END_LOADING_NOTIFICATION = "END_LOADING_NOTIFICATION"
export const SET_NOTIFICATION = "SET_NOTIFICATION"
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS"
export const ADD_NOTIFICATION = "ADD_NOTIFICATION"
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION"
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION"

{/* //! -------------- Misc ------------------ */}
export const SET_WIDTH = "SET_WIDTH"


