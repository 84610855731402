import FlakyIcon from "@mui/icons-material/Flaky"
import { Stack } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { updateReservation } from "redux/actions/reservation"
import {
  feedbackError,
  feedbackSuccess,
} from "../../../../redux/actions/feedback"

export default function FormDialog(props) {
  const [open, setOpen] = useState(false)
  const [motif, setMotif] = useState("")
  const dispatch = useDispatch()

  const onSubmit = async (status) => {
    try {
      await dispatch(
        updateReservation(props.cellValues.row._id, {
          status: status,
          motif: motif,
        })
      )
      dispatch(
        feedbackSuccess(
          `La réservation a été ${
            status === "ACCEPTED" ? "acceptée" : "rejetée"
          }.`
        )
      )

      setTimeout(() => {
        handleClose()
      }, 3000)
    } catch (error) {
      dispatch(feedbackError(error?.response?.data))
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {React.cloneElement(props.children, { onClick: handleClickOpen })}
      <Dialog open={open} onClose={handleClose}>
        <Stack alignItems="center" spacing={2} sx={{ p: 2 }}>
          <DialogTitle>
            Accepter ou refuser la réservation de :
            {props.cellValues.row.userId?.name}
          </DialogTitle>
          {/* <div className="judge-dialog"> */}
          <FlakyIcon
            color="error"
            style={{
              fontSize: "10rem",
              // position: "relative",
              // top: "-0.9rem",
            }}
          />
          {/* </div> */}
          <DialogContent>
            <DialogContentText sx={{ color: "black" }}>
              Veuillez entrer un motif pour refuser la réservation.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Motif"
              fullWidth
              variant="standard"
              onChange={(e) => setMotif(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="error"
              onClick={() => onSubmit("REJECTED")}
            >
              Refuser
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onSubmit("ACCEPTED")}
            >
              Accepter
            </Button>
          </DialogActions>
        </Stack>
      </Dialog>
    </>
  )
}
