import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  paper: {
    margin: "auto",
    // marginTop: theme.spacing(16),
    // marginBottom: theme.spacing(12),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
    borderRadius: '40px 0 40px 0',
    boxShadow: 'rgb(255 0 64 / 40%) 0px 7px 29px 0px',
    // boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    background: 'rgba(255,255,255)',
    maxWidth: "50vw",
    width: "fit-content",

  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  avatar: {
    display: 'block',
    margin: theme.spacing(1),
    width: '80px',
    height: '80px',
    objectFit: 'contain',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: '#18829b',
    '&:hover': {
      background: '#07677d',
    }
  },
  googleButton: {
    marginBottom: theme.spacing(2),
  },
}));
