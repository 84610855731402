import { CircularProgress, Paper } from '@mui/material';
import Rating from '@mui/material/Rating';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getPrestataire } from '../../redux/actions/prestataires';
import useStyles from './styles';

export default function PrestataireDetails() {
    const { prestataire, prestataires, PrestatairesIsLoading } = useSelector((state) => state.prestataires);
    const dispatch = useDispatch();
    const { id } = useParams();
    const classes = useStyles();

    useEffect(() => {
        dispatch(getPrestataire(id));
    }, [dispatch, id]);

    if (PrestatairesIsLoading) {
        return (
          <Paper elevation={6} className={classes.loadingPaper}>
            <CircularProgress size="7em" />
          </Paper>
    )}

    return (
        <div className="prestataire-page">
            <div className="page-header">
                <img className="couverture-photo" src={prestataire?.photos[0]} alt="Prestataire" />
                <div className="header-overlay">
                    <div className="header-content mycontainer">
                        <h2>{prestataire?.name}</h2>
                        <Rating readOnly={true} name="prestataire-rating" value={prestataire?.rating} precision={0.5} size="medium" />
                    </div>
                </div>
                <img className="avatar-photo" src={prestataire?.avatar} alt="Avatar" />
            </div>
            <div className="prestataire-content"></div>
        </div>
    )
}
