import { resizePhotos } from "functions/imageFunctions"
import { Avatar, Badge, Button } from "@mui/material"
import React from "react"

function ChangeAvatar({ formData, setFormData, height, readOnly }) {
  const handleAddPostPhotos = async (e) => {
    const file = e.target.files[0]

    setFormData({
      ...formData,
      avatar: await resizePhotos(file, 25),
    })
  }

  const handleRemovePhoto = async (event) => {
    if (event.target.nodeName !== "SPAN") return

    setFormData({
      ...formData,
      avatar: "",
    })
  }
  return (
    <div className="center">
      <Badge
        badgeContent="X"
        color="primary"
        overlap="circular"
        onClick={handleRemovePhoto}
        sx={{
          "& .MuiBadge-badge": {
            fontSize: "2rem",
            height: 40,
            width: 40,
            borderRadius: "50%",
            cursor: "pointer",
          },
        }}
      >
        <Avatar src={formData?.avatar} sx={{ width: 200, height: 200 }} />
      </Badge>
      <Button component="label" variant="contained">
        Changer la photo de profil
        <input hidden type="file" onChange={handleAddPostPhotos} />
      </Button>
    </div>
  )
}

export default ChangeAvatar
