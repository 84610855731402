import FavoriteIcon from "@mui/icons-material/Favorite"
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import VisibilityIcon from "@mui/icons-material/Visibility"
import {
  Button, Card,
  CardContent,
  CardMedia, IconButton, Tooltip, Typography
} from "@mui/material/"
import Rating from "@mui/material/Rating"
import moment from "moment"
import "moment/locale/fr"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { feedbackError } from "../../../redux/actions/feedback"
import { likePost } from "../../../redux/actions/posts"
import useStyles from "./styles"
moment.locale("fr")

const PostComponent = ({ post, postImageHeight }) => {
  const { authData } = useSelector((state) => state.auth)

  const dispatch = useDispatch()
  const classes = useStyles()
  const navigate = useNavigate()

  const handleLike = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (!authData) return navigate("/auth")

    if (authData?.role !== "client")
      return dispatch(
        feedbackError("Veuillez vous connecter en tant que client.")
      )

    dispatch(likePost(post?._id, authData._id))
  }

  const LikeIcon = () => {
    return post?.likes?.find((like) => like === authData?._id) ? (
      <FavoriteIcon className="like-svg" fontSize="small" />
    ) : (
      <FavoriteBorderIcon className="like-svg" />
    )
  }

  return (
    <div className="postComponent">
      <IconButton className="like-button" onClick={handleLike}>
        <LikeIcon />
      </IconButton>
      <Link to={`/post/${post?._id}`}>
        <img
          //  style={{ height: postImageHeight,  width: postImageHeight }}
          src={
            post?.photos
              ? post.photos[0]
              : "https://source.unsplash.com/user/c_v_r"
          }
        />
        <div>
          <h3 style={{ fontSize: "1.5rem" }}>{post?.title} </h3>
          <p>{post?.address ? post?.address : "Tunisie"} </p>
          <p>{moment(post?.date).format("DD/MM/YYYY")}</p>
          <strong>{post?.price ? post?.price + " DT" : ""}</strong>
        </div>
      </Link>
    </div>
  )

  return (
    <Card className={classes.card + " post-hover"} raised elevation={6}>
      <CardMedia
        className={classes.media}
        title={post?.title}
        style={{ height: postImageHeight ? postImageHeight : "180px" }}
        image={
          post?.photos
            ? post?.photos[0]
            : "https://source.unsplash.com/user/c_v_r"
          // "http://localhost:5002/postImages/62778ffa8c4ab63ca4d35061%2081993.jpg"
          // "https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
        }
      />
      <CardContent className={classes.content}>
        <div className={classes.location}>
          <Tooltip
            title={
              post?.prestataireId?.city + " " + post?.prestataireId?.address
            }
          >
            <span className="post-svg-container">
              <LocationOnIcon className={classes.svgIcon} />
            </span>
          </Tooltip>
          <div className="post-adress">
            <p className={classes.adress}>
              <Link to={`/locations/${post?.location}`}>
                <strong>{post?.location}</strong>
              </Link>
              &nbsp;:&nbsp;&nbsp;
              <Link to={`/prestataires/${post?.prestataireId}`}>
                <strong>{post?.prestataire}</strong>
              </Link>
            </p>
            <Rating
              readOnly={true}
              name="prestataire-rating"
              value={post?.prestataireRating}
              precision={0.5}
              size="small"
            />
          </div>
        </div>
        <div className="post-line">
          <Typography
            className={classes.title}
            gutterBottom
            variant="h5"
            component="h2"
          >
            {post?.title}
          </Typography>
          {/* <span className="post-price">{ post?.quost && post?.quost !== '' ? post?.quost + ' DT' : ' ' }</span> */}
        </div>
        <div className="post-description">
          <p>{post?.description?.split(" ").splice(0, 15).join(" ")}...</p>
        </div>
        <div className="post-categories">
          {post?.categoriesIds?.slice(1, 3).map((cat, index) => (
            <p
              style={{ color: "#F76C6F", whiteSpace: "nowrap" }}
              key={index}
              to={`/categories/${cat._id}`}
              className={classes.category}
            >
              #{cat.title}
            </p>
          ))}
        </div>
        <Link
          to={`/post/${post?._id}`}
          state={{ ...post }}
          className="view-container"
        >
          <VisibilityIcon className="view" />
        </Link>
      </CardContent>

      <span className="post-date">
        {moment(post?.date).endOf("day").fromNow()}
      </span>
      <Button
        className="like-button"
        disabled={!authData}
        onClick={(e) => handleLike(e)}
      >
        <LikeIcon />
      </Button>
    </Card>
  )
}

export default PostComponent
