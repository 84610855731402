import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useSearchParams } from "react-router-dom"
import PostsComponent from "../Posts/PostsComponent"
import { getPostsFromParentCategory } from "../../redux/actions/posts"
import { BannerCarousel } from "../../GlobalComponent/BannerCarousel"
import { findCategory } from "../../redux/actions/categories"
import LoadingPage from "../../GlobalComponent/LoadingPage"
import FlowerTitle from "../../GlobalComponent/FlowerTitle"
import { Stack, TextField } from "@mui/material"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import FillerSkeleton from "../../GlobalComponent/FillerSkeleton"
import PostsAndSort from "./PostsAndSort"

function CategoryDetails() {
  let [searchParams, setSearchParams] = useSearchParams()
  var parentId = searchParams.get("parentId")

  const dispatch = useDispatch()
  const { category, loadingCategory } = useSelector((state) => state.category)

  useEffect(() => {
    dispatch(findCategory(parentId))
  }, [])

  if (loadingCategory) return <LoadingPage />

  return (
    <div>
      <BannerCarousel
        autoPlay={false}
        imagesPath={[category.image]}
        // text={category.title}
      />
      <div className="viewAllPage">
        <FlowerTitle title={"Les offres : " + category.title} />

        <PostsAndSort />
      </div>
    </div>
  )
}

export default CategoryDetails
