import { applyMiddleware, configureStore } from "@reduxjs/toolkit"
import thunk from "redux-thunk"

import category from "./reducers/category"
import prestataires from "./reducers/prestataires"
import posts from "./reducers/posts"
import auth from "./reducers/auth"
import users from "./reducers/users"
import chats from "./reducers/chats"
import dataGrid from "./reducers/dataGrid"
import  reservation  from "./reducers/reservation"
import misc from "./reducers/misc"

export default configureStore(
  {
    reducer: {
      category: category,
      prestataires: prestataires,
      posts: posts,
      auth: auth,
      users: users,
      chats: chats,
      reservation: reservation,
      dataGrid: dataGrid,
      misc: misc,
    },
  },
  applyMiddleware(thunk)
)
