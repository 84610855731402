import { Stack } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import FillerSkeleton from "../../GlobalComponent/FillerSkeleton"
import { getPostsFromParentCategory } from "../../redux/actions/posts"
import PostsComponent from "../Posts/PostsComponent"
import { SelectController } from "../../GlobalComponent/controllersInputs/SelectController"

const initForm = { date: "asc", price: "asc" }
const options = [
  { label: "Croissant", value: "asc" },
  { label: "Décroissant", value: "desc" },
]

function PostsAndSort() {
  const dispatch = useDispatch()

  let [searchParams, setSearchParams] = useSearchParams()
  var parentId = searchParams.get("parentId")

  const { PostsIsLoading } = useSelector((state) => state.posts)
  const [categoryPosts, setCategoryPosts] = useState([])

  const [formData, setFormData] = useState(initForm)
  const handleChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    ;(async () => {
      let filteredData = await dispatch(
        getPostsFromParentCategory(parentId, formData)
      )
      setCategoryPosts(filteredData)
    })()
  }, [formData])

  return (
    <>
      <Stack direction="row" spacing={2}>
        {SelectController({
          label: "Date",
          name: "date",
          options: options,
          handleChange: handleChange,
          formData: formData,
        })}
        {SelectController({
          label: "Prix",
          name: "price",
          options: options,
          handleChange: handleChange,
          formData: formData,
        })}
      </Stack>

      {PostsIsLoading ? (
        <FillerSkeleton />
      ) : (
        <PostsComponent renderMode="Grid" posts={categoryPosts} />
      )}
    </>
  )
}

export default PostsAndSort
