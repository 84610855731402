import React from "react"
import Carousel from "react-multi-carousel"
import { responsive } from "../components/Pages/Home/HomePage"

export function BannerCarousel({ title, imagesPath, autoPlay = true }) {
  return (
    <div style={{ position: "relative", height: "75vh" }}>
      {title &&<div className="homePageBannerTitle">{title}</div>}
      <Carousel
        className={"homePageBannerCarousel"}
        responsive={responsive}
        infinite={true}
        autoPlay={autoPlay}
        autoPlaySpeed={3000}
        // keyBoardControl={true}
        showDots={true}
        // customTransition="all .5"
        transitionDuration={3000}
        arrows={false}
      >
        {imagesPath.map((imagePath, key) => (
          <img key={key} src={imagePath} />
        ))}
      </Carousel>
    </div>
  )
}
