import React, { useEffect, useState } from "react"
import { Autocomplete, Avatar, Box, InputBase, Stack, useTheme } from "@mui/material"
import { Link } from "react-router-dom"
import SearchIcon from "@mui/icons-material/Search"
import { useSelector } from "react-redux"

export function AutocompleteSearch({ classes }) {
  const { posts } = useSelector((state) => state.posts)
  const theme = useTheme()



  const [postAutocomplete, setPostAutocomplete] = useState([])
  const [userInput, setUserInput] = useState("")

  useEffect(() => {
    if (posts.length) {
      var result = []
      posts.map((post, key) => {
        result[key] = {
          id: post._id,
          label: post.title,
          photo: post?.photos?.length
            ? post?.photos?.[0]
            : "https://source.unsplash.com/user/c_v_r",
        }
      })
      setPostAutocomplete(result)
    }
  }, [posts])

  if (posts.length)
    return (
      <Stack direction="row" alignItems="center">
        <SearchIcon sx={theme.headerIcon}/>
        <Autocomplete
          noOptionsText="Aucune publication trouvée."
          open={userInput?.length > 2}
          inputValue={userInput || ""}
          onInputChange={(event) => setUserInput(event?.target?.value)}
          options={postAutocomplete}
          // sx={{ width: 300 }}
          renderOption={(props, option) => (
            <Link to={`/post/${option.id}`}>
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Avatar loading="lazy" width="20" src={option.photo} alt="" />
                  <p>{option.label} </p>
                </Stack>
              </Box>
            </Link>
          )}
          renderInput={(params) => {
            let { InputProps, InputLabelProps, ...others } = params

            return (
              <InputBase
                placeholder="Rechercher"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                ref={params.InputProps.ref}
                {...others}
              />
            )
          }}
        />
      </Stack>
    )
}
