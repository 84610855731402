import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Avatar,
  Paper,
  Grid,
  Typography,
  Container,
  Button,
} from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import { signin, signup } from "../../../redux/actions/auth"
import { AUTH } from "../../../redux/actionTypes"
import useStyles from "../../Auth/styles"
import Input from "../../Auth/Input"
import RegisterForm from "../../Auth/RegisterForm/RegisterForm"
import LoginForm from "../../Auth/LoginForm"
import { GoogleFacebookLogin } from "../../Auth/GoogleFacebookLogin"
import { fastLogin } from "../../Auth/FastLogin"
import ForgetPasswordForm from "./ForgetPasswordForm"

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  password: "",
  confirmPassword: "",
  googleRequest: false,
}

const ForgetPasswordPage = () => {
  const currentUser = JSON.parse(localStorage.getItem("profile"))
  const dispatch = useDispatch()
  const [form, setForm] = useState(initialState)
  const [isSignup, setIsSignup] = useState(false)
  const classes = useStyles()
  const [showPassword, setShowPassword] = useState(false)

  const navigate = useNavigate()
  if (currentUser) return navigate("/home")

  const switchMode = () => {
    setForm(initialState)
    setIsSignup((prevIsSignup) => !prevIsSignup)
    setShowPassword(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    try {
      if (isSignup) {
        dispatch(signup(form, navigate))
      } else {
        dispatch(signin(form, navigate))
      }
    } catch (error) {
      console.log(error.response.data)
    }
  }

  const googleSuccess = async (res) => {
    const result = res?.profileObj
    const token = res?.tokenId

    console.log(result)
    /* dispatch(signup({firstName: result.givenName, lastName: result.familyName, email: result.email, password: '00000000', confirmPassword: '00000000', googleRequest: true}, history)); */

    try {
      dispatch({ type: AUTH, data: { result, token } })
      navigate("/")
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="authPage" >
      <Paper className={classes.paper} style={{ width: "40vw" }} elevation={6}>
        <img className={classes.avatar} src="/images/logo.png" alt="Logo" />
        <h1 className="forget-password-title" >Récupérer votre mot de passe</h1>

        <ForgetPasswordForm />

        <p style={{ marginTop: "1rem" }}>Vous avez déjà un compte ?</p>
        <Link to="/auth">
          <Button variant="outlined" sx={{ mt: 2, width: "100%" }}>
            connectez-vous !
          </Button>
        </Link>
        {/* // TODO TESTING */}
        {fastLogin(dispatch, navigate)}
      </Paper>
    </div>
  )
}

export default ForgetPasswordPage
